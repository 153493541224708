import React, { useState,  useEffect } from 'react';
import "../../styles/styles.css"
import axios from 'axios';
import uuid from 'react-uuid';
import {
  updateCompanyRoute,
  deleteCompanyRoute,
  insertCompanyRoute,
  deleteGeoDataRoute,
  updateGeoDataRoute,
  insertGeoDataRoute,
  getGeoTableRoute,
  companyDataRoute,
  getGeotechnicsNodetreeMenuRoute
} from '../../utils/routes';
import { geoPage, LogMessageType, GeoTechnicsInterface,  PageNumber } from '../../functions_interfaces/interfaces';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../functions_interfaces/interfaces';
import { getModuleNodetreeMenu } from '../monitoring/_monitoring';
import { IoCloseOutline } from "react-icons/io5";

interface geoTableItem {
  report_number: number | number[],
  uuid: string,
  object: string | number[],
  client: string | number[],
  investor: string | number[],
  order_date: string | number[],
  deadline: string | number[],
  submited: boolean | number[],
  section: string,
  phase: string | number[],
  project_number: string | number[],
  members: [],
  value: string | number[],
  lab_GGR: boolean | number[],
  hg_GGR: boolean | number[],
  wells_GGR: string | number[],
  spt_GGR: string | number[],
  dp_GGR: string | number[],
  cpt_GGR: string | number[],
  dmt_GGR: string | number[],
  presio_GGR: string | number[],
  excavations_GGR: string | number[],
  submision_date: string | number[],
  internal: boolean | number[],
  comment: string | number[],
  type: string | number[]
};

const sectionAppearInPageTemplate: geoTableItem = {
  uuid: '',
  report_number: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  object: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  client: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  investor: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  order_date: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  deadline: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  submited: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  section: '',
  phase: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  project_number: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  members: [],
  value: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  lab_GGR: [geoPage.geo_geotechnical_reports],
  hg_GGR: [geoPage.geo_geotechnical_reports],
  wells_GGR: [geoPage.geo_geotechnical_reports],
  spt_GGR: [geoPage.geo_geotechnical_reports],
  dp_GGR: [geoPage.geo_geotechnical_reports],
  cpt_GGR: [geoPage.geo_geotechnical_reports],
  dmt_GGR: [geoPage.geo_geotechnical_reports],
  presio_GGR: [geoPage.geo_geotechnical_reports],
  excavations_GGR: [geoPage.geo_geotechnical_reports],
  submision_date: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls],
  internal: [geoPage.field_investigations],
  comment:[geoPage.caves, geoPage.foundation, geoPage.supervision],
  type: [geoPage.caves, geoPage.companies, geoPage.field_investigations, geoPage.foundation, geoPage.geo_geotechnical_reports, geoPage.supervision, geoPage.supporting_walls]
};

interface companyItem {
  uuid: string,
  name: string,
  adress: string,
  type: string,
  post: string,
};

const geoPageCavesTypeOptions = [
  {name: 'VGJ'},
  {name: 'TZ'},
  {name: 'N'},
  {name: 'OK'},
  {name: 'PK'}
];

const geoPageGeotechnicalReportsTypeOptions = [
  {name: 'S-GGP'},
  {name: 'GGP'},
  {name: 'PGGR'}
];

const geoPageGeoPhaseOptions = [
  {name: 'IZP'},
  {name: 'DGD'},
  {name: 'PZI'},
  {name: 'PID'}
];

const companyTypeOptions = [
  {name: 'JS'},
  {name: 'PS'},
  {name: 'FS'}
];
export interface geoPageNodetreeMenuInterface {
  uuid: string,
  name: string,
  parent_node_id: string,
  active: boolean,
  section_number: number
}

function GeoTable ({ActionLogReferences}: GeoTechnicsInterface) {
  
  const general_data: geoTableItem = {
    uuid: uuid(),
    report_number: 0,
    object: '',
    client:'',
    investor: '',
    order_date: '',
    deadline:'',
    submited: false,
    section: '',
    phase: '',
    project_number: '',
    members: [],
    value: '',
    lab_GGR: false,
    hg_GGR: false,
    wells_GGR: '',
    spt_GGR: '',
    dp_GGR: '',
    cpt_GGR: '',
    dmt_GGR: '',
    presio_GGR: '',
    excavations_GGR: '',
    submision_date: '',
    internal: false,
    comment:'',
    type: ''
  };

  const companyData_init: companyItem = {
    uuid:'',
    name:'',
    adress:'',
    type: '',
    post:'',
  };



  // switches
  const [edit, setEdit] = useState<boolean>(false);
  const [togglePage, setTogglePage] = useState<number>(0);
  const [showInsertForm, setShowInsertForm] = useState<boolean>(false);
  const [showInfoBox, setShowInfoBox] = useState<boolean>(false);
  const [showCompany, setShowCompany] = useState<boolean>(false);
  const [showCompanyForm, setShowCompanyForm] = useState<boolean>(false);

  //data states
  const [generalData, setGeneralData] = useState<geoTableItem>(general_data);

  const [company, setCompany] = useState<companyItem>(companyData_init);  
  const [companiesData, setCompaniesData] = useState<companyItem[]>();

  const [tableItems, setTableItems] = useState<geoTableItem[]>([]);
  const [filteredTableItems, setFilteredTableItems] = useState<geoTableItem[]>([]);

  const [geotechnicsNodetreeMenu, setGeotechnicsNodetreeMenu] = useState<geoPageNodetreeMenuInterface[]>()

  //translation function
  const { t } = useTranslation();

  //search bar keyword
  const [searchKeyWord, setSearchKeyword] = useState<string>('')

  //page name => for display, sectionName => for database to know where to insert
  const [pageName, setPageName] = useState<string>(t('geotechnics.geo-geotechnical_reports'));
  const [sectionName, setSectionName] = useState<string>('geo_geotechnical_reports');

  useEffect(() => {
    fetchCompaniesData();
  }, []);
    
  useEffect(() => {
    fetchData(togglePage);
  }, [togglePage]);

  useEffect(() => {
    
    getMonitoringNodeTreeInsideComponent();
  }, []);

  async function getMonitoringNodeTreeInsideComponent() {
    const monitoringNodeTreeData: geoPageNodetreeMenuInterface[] = await getModuleNodetreeMenu(PageNumber.Geo, getGeotechnicsNodetreeMenuRoute) as geoPageNodetreeMenuInterface[]
    console.log(monitoringNodeTreeData)
    setGeotechnicsNodetreeMenu(monitoringNodeTreeData)
  }
  useEffect(() => {
      setFilteredTableItems(filterItemsBasedOnKeyword(tableItems, searchKeyWord));
  }, [searchKeyWord]);
  
//--------------------------------------------------------------------------------------------------------

  async function fetchCompaniesData() {

    try{
      let response = await axios.get(companyDataRoute);
      ActionLogReferences.updateLogString('Fetching companies', LogMessageType.Pending);

      if (response && response.data.resolved) {
        setCompaniesData(response.data.data);

        ActionLogReferences.updateLogString(
          'Companies Transfer Success',
          LogMessageType.Success,
          JSON.stringify(response.data.data, null, "\t")
        );
      }

      else {
        ActionLogReferences.updateLogString(
          'Error fetching companies',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch(e) {
      ActionLogReferences.updateLogString(
        'Frontend Error',
        LogMessageType.Error,
        e
      );
    };
  };

  async function fetchData(section) {
    try {
      let response = await axios.put(getGeoTableRoute, {section})
      ActionLogReferences.updateLogString('Fetching Data', LogMessageType.Pending);

      if (response && response.data.resolved) {
        setTableItems(response.data.data);
        setFilteredTableItems(response.data.data);

        ActionLogReferences.updateLogString(
          'Data Transfer Success',
          LogMessageType.Success,
          JSON.stringify(response.data.data, null, "\t")
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Backend Data Transfer Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch(e) {
      ActionLogReferences.updateLogString(
        'Frontend Data Transfer Error',
        LogMessageType.Error,
        e
      );
    };
  };

  async function insertNewItem () {

    let newItem: geoTableItem = {
      ...generalData,
      section: sectionName
    }

    try {
      let response = await axios.post(insertGeoDataRoute, newItem);
      ActionLogReferences.updateLogString('Inserting New Item', LogMessageType.Pending);

      if (response.data.resolved) {
        await fetchData(togglePage);
        setShowInsertForm(false);

        ActionLogReferences.updateLogString(
          'Item inserted',
          LogMessageType.Success
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Backend Insert Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Frontend Error',
        LogMessageType.Error,
        e
      );
    };
  };

  async function updateExistingItem() {

    let itemToUpdate = {
      ...generalData
    };
    
    try {
      let response = await axios.put(updateGeoDataRoute, itemToUpdate);
      ActionLogReferences.updateLogString('Updating Item', LogMessageType.Pending);

      if (response && response.data.resolved) {
        await fetchData(togglePage);
        setEdit(false);
        setShowInsertForm(false);

        ActionLogReferences.updateLogString(
          'Item Updated',
          LogMessageType.Success
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Backend Item Update Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Frontend Item Update Error',
        LogMessageType.Error,
        e
      );
    };
  };

  async function deleteItem(uuid) {

    if (window.confirm('Ali res želiš izbrisati item?')) {
      try {
        let response = await axios.delete(deleteGeoDataRoute, {data:{uuid}});
        ActionLogReferences.updateLogString('Deleting Item', LogMessageType.Pending);

        if (response && response.data.resolved) {
          await fetchData(togglePage);
          setShowInsertForm(false);
          setShowInfoBox(false);

          ActionLogReferences.updateLogString(
            'Item Deleted',
            LogMessageType.Success
          );
        }
        else {
          ActionLogReferences.updateLogString(
            'Backend Item Delete Error',
            LogMessageType.Error,
            response.data.message
          );
        }
      }
      catch (e) {
        ActionLogReferences.updateLogString(
          'Frontend Item Delete Error',
          LogMessageType.Error,
          e
        );
      };
    };
  };

  async function insertNewCompany(e) {
    e.preventDefault();

    let newCompany: companyItem = {
      uuid: uuid(),
      name: company.name,
      type: company.type,
      adress: company.adress,
      post: company.post
    };

    setCompany(prev => ({...prev, uuid: newCompany.uuid}));

    try {
      let response = await axios.post(insertCompanyRoute, newCompany);
      ActionLogReferences.updateLogString('Inserting New Company', LogMessageType.Pending);

      if (response && response.data.resolved) {
        await fetchCompaniesData();
        setShowCompany(true);
        setShowCompanyForm(false);
        ActionLogReferences.updateLogString(
          'Company Inserted',
          LogMessageType.Success
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Backend Company Insert Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Frontend Company Insert Error',
        LogMessageType.Error,
        e
      );
    };
  };

  async function deleteCompany() {

    if (window.confirm(`Ali si zares želiš izbrisati podjetje ${company.name}?`)) {
      try {
        let response = await axios.delete(deleteCompanyRoute, {data: {uuid: company.uuid, name: company.name}});
        ActionLogReferences.updateLogString('Deleting Company', LogMessageType.Pending);
  
        if (response && response.data.resolved) {
          await fetchCompaniesData();
          setCompany(companyData_init)
          setShowCompany(false);

          ActionLogReferences.updateLogString(
            'Company Deleted',
            LogMessageType.Success
          );
        }
        else {
          ActionLogReferences.updateLogString(
            'Backend Company Delete Error',
            LogMessageType.Error,
            response.data.message
          );
        }
      }
      catch (e) {
        ActionLogReferences.updateLogString(
          'Frontend Company Delete Error',
          LogMessageType.Error,
          e
        );
      };
    };
  };

  async function updateCompany(e) {
    e.preventDefault()
    try {
      let response = await axios.put(updateCompanyRoute, company);
      ActionLogReferences.updateLogString('Updating Company', LogMessageType.Pending);

      if (response && response.data.resolved) {
        await fetchCompaniesData();
        setEdit(false);
        setShowCompany(false);
        setShowCompanyForm(false);

        ActionLogReferences.updateLogString(
          'Company Updated',
          LogMessageType.Success
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Backend Company Update Error',
          LogMessageType.Error,
          response.data.message
        );
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Frontend Company Update Error',
        LogMessageType.Error,
        e
      );
    };
  };
//--------------------------------------------------------------------------------------------------------
  function initData () {
    setShowInfoBox(false);
    setShowCompanyForm(false);
    setShowInsertForm(false);
    setShowCompany(false);
    setGeneralData(general_data);
  };

  function setData(data: geoTableItem) {
    setGeneralData(data);
    setShowInfoBox(true);
  };

  async function submitForm (e) {
    e.preventDefault();

    if (edit === false) {
      await insertNewItem();
    };
    if (edit === true) {
      await updateExistingItem();
    };
  };

  function setFormToEdit() {
    setShowInsertForm(true);
    setEdit(true);
  };

  function handlePageChange(pageNumber) {
    setTogglePage(pageNumber);
    initData();
    setDataAccordingToPage(pageNumber, setPageName, setSectionName, t);
  };

  function setForEdit(e) {
    e.preventDefault();
    setEdit(true);
    setShowCompany(false);
    setShowCompanyForm(true);
  };

  return (
    <div className = 'geo-reports-component-wrapper'>
      <header className = 'globals--module-navbar'>

        <div className = 'globals--module-navbar-title'>
          {t(`index.head.dropdown.geotechnics`)}

           <div className = 'globals--module-navbar-title-arrow'> {Icons.arrowRight} </div>
           <div className= 'globals--module-navbar-dropdown-tree-menu'>
            <RenderFirstLevel 
              firstLevelItems={geotechnicsNodetreeMenu as geoPageNodetreeMenuInterface[]}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
       
        <div className = 'globals--module-navbar-subTitle'>
          {pageName}
        </div>

        <div className = 'helpers--section-container-orientation-flex-row'>
          <input  type = 'text'
                  placeholder='search...'
                  value = {searchKeyWord}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  className = 'globals--search-bar'/>
        </div>
        
        {togglePage === geoPage.companies?
          <button onClick={() => {setShowCompanyForm(true); setCompany(companyData_init); setShowCompany(false);}}
                  className = 'globals--regular-button'>
            {t('geotechnics.new_company')}
          </button>
        :
          <button onClick = {() => {initData (); setShowInsertForm(true); setEdit(false)}}
                  className = 'globals--regular-button'>
            {t('geotechnics.new_report')}
          </button>
        }
      </header>

      <main className = 'geo-reports-component-wrapper-main-content'>
        {togglePage === geoPage.companies?
          <>
            <CompaniesDataTable companiesData={companiesData}
                                setCompany={setCompany}
                                setShowCompany={setShowCompany}
                                setShowCompanyForm={setShowCompanyForm}/>
              {showCompanyForm &&
                <CompanyDataForm  setCompany={setCompany}
                                  company={company}
                                  insertNewCompany={insertNewCompany}
                                  updateCompany={updateCompany}
                                  setShowCompanyForm={setShowCompanyForm}
                                  edit = {edit}
                                  t = { t }/>
              }

              {showCompany &&
                <CompanyDataDescriptionbox  t = {t}
                                            company={company}
                                            setShowCompany={setShowCompany}
                                            deleteCompany={deleteCompany}
                                            setForEdit={setForEdit}/>
              }
          </>
        :
          <>
            <MainDataTable  t = {t}
                            tableItems={filteredTableItems}
                            setData={setData}
                            initData={initData}
                            setShowInsertForm={setShowInsertForm}
                            setEdit={setEdit}/>

              {showInsertForm?
                <div className = 'form'>
                  <div className = 'header'>
                    <div>
                      {edit &&
                        <button onClick={() => deleteItem(generalData.uuid)}
                                className = 'globals--red-button'>
                          {Icons.trashCan}
                        </button>}
                    </div>

                    {edit?
                      <div>{t('button.edit')}</div>
                    :
                      <div>{t('button.insert')}</div>
                    }
                    
                    <div onClick={() => setShowInsertForm(false)} className = 'globals--close-button'></div>
                  </div>

                  <TemplateStructuredDataForm submitForm={submitForm}
                                              generalData={generalData}
                                              setGeneralData={setGeneralData}
                                              t = {t}
                                              currentPage = {togglePage}
                                              companiesData={companiesData}/>
                  
                </div>
              :
                <div className = 'formContainer'>
                  {showInfoBox &&
                    <div className = 'form'>

                      <div className = 'header'>
                        <div></div>
                        <div> {generalData.object as string} </div>
                        <button onClick={() => {setShowInfoBox(false); setEdit(false)}}
                              className = 'globals--close-button'></button>
                      </div>

                      <TemplateStructuredDataDescriptionbox setFormToEdit={setFormToEdit}
                                                            generalData={generalData}
                                                            t = {t}
                                                            currentPage={togglePage}/>
                    </div>
                  }
                </div>
          }
          </>
          }
      </main>
    </div>
  );
};

function filterItemsBasedOnKeyword(itemsToFilter: geoTableItem[], keyWord: string): geoTableItem[] {
  const keyWordInLowerCase = keyWord.toLowerCase();

  return itemsToFilter.filter(item => 
    Object.entries(item).some(([key, value]) => 
      String(value).toLowerCase().includes(keyWordInLowerCase)
    )
  );
};

interface geoTreeItemProps {
  data: geoPageNodetreeMenuInterface,
  handlePageChange: (pageNumber: number) => void;
  [key: string]: any;
}
const TreeItem = React.memo(({ data, handlePageChange }: geoTreeItemProps) => {


  return (
      <div className="TreeItem">
          <div className="TreeItem-label" onClick={() => handlePageChange(data.section_number)}>
              {data.name}
          </div>
      </div>
  );
});

interface renderGeomenuFirstLevelProps {
  firstLevelItems: geoPageNodetreeMenuInterface[],
  handlePageChange: (pageNumber: number) => void;
}
function RenderFirstLevel({firstLevelItems, handlePageChange}: renderGeomenuFirstLevelProps) {

  return (
    <>
      {firstLevelItems?.map(item => (
        <TreeItem key={item.uuid} data={item} handlePageChange={handlePageChange}>
        </TreeItem>
      ))}
    </>
  );
};
function setDataAccordingToPage(pageNumber, setPageName, setSectionName, t) {
    
  if (pageNumber === geoPage.caves) {
    setPageName(t('geotechnics.caves'));
    setSectionName('caves');
  }
  if (pageNumber === geoPage.foundation){
    setPageName(t('geotechnics.foundation'));
    setSectionName('foundation');
  }
  if (pageNumber === geoPage.geo_geotechnical_reports) {
    setPageName(t('geotechnics.geo-geotechnical_reports'));
    setSectionName('geo_geotechnical_reports');
  }
  if (pageNumber === geoPage.supervision) {
    setPageName(t('geotechnics.supervision'));
    setSectionName('supervision');
  }
  if (pageNumber === geoPage.supporting_walls) {
    setPageName(t('geotechnics.supporting_walls'));
    setSectionName('supporting_walls');
  }
  if (pageNumber === geoPage.companies) {
    setPageName(t('geotechnics.companies'));
    setSectionName('companies');
  }
  if (pageNumber === geoPage.field_investigations) {
    setPageName(t('geotechnics.field_investigations'));
    setSectionName('field_investigations');
  }
};

function MainDataTable ({t, tableItems, setData, initData, setShowInsertForm, setEdit}) { 

  return(
    <div style = {{overflow:'auto', width: '100%'}}>
      <table>
          <thead>
              <tr>
                  <th>{t('geotechnics.phase').toUpperCase()}</th> 
                  <th>{t('geotechnics.reportNu').toUpperCase()}</th>
                  <th>{t('geotechnics.object').toUpperCase()}</th>
                  <th>{t('geotechnics.client').toUpperCase()}</th>
                  <th>{t('geotechnics.investor').toUpperCase()}</th>
                  <th>{t('geotechnics.comissionDate').toUpperCase()}</th>
                  <th>{t('geotechnics.deadline').toUpperCase()}</th>
                  <th>{t('geotechnics.submited').toUpperCase()}</th> 
              </tr>
          </thead>
          <tbody>
          {tableItems.map((item) => {
              return (
              <tr onClick={() => setData(item)} key = {item.uuid}>
                <td>{item.phase as string}</td>
                <td>{item.report_number as number}</td>
                <td>{item.object as string}</td>
                <td>{item.client as string}</td>
                <td>{item.investor as string}</td>
                <td>{item.order_date as string}</td>
                <td>{item.deadline as string}</td>
                <td style = {{color: item.submited?'green':'red'}}>{(item.submited? Icons.check : <IoCloseOutline />)}</td>
              </tr>
              )
          })}
          </tbody>
          
      </table>
      
    </div>
  )
};

function CompaniesDataTable({companiesData, setCompany, setShowCompany, setShowCompanyForm}) {
  return (
    <div  style = {{overflow:'auto', width: '100%'}}>
      <table>
        <thead className='heading'>
          <tr>
            <th>Vrsta</th>
            <th>Ime</th>
            <th>Naslov</th>
            <th>Pošta</th>
          </tr>
        </thead>
        <tbody>
          {companiesData
            ?.slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
                <tr
                    key={item.uuid}
                    onClick={() => {
                        setCompany({
                            uuid: item.uuid,
                            name: item.name,
                            type: item.type,
                            adress: item.adress,
                            post: item.post
                        });
                        setShowCompany(true);
                        setShowCompanyForm(false);
                    }}
                >
                    <td>{item.type}</td>
                    <td>{item.name}</td>
                    <td>{item.adress}</td>
                    <td>{item.post}</td>
                </tr>
            ))}
        </tbody>
      </table>
    </div>
    )
};

function CheckBoxInput({name, objectField, object, onChange}) {

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    onChange(prev => ({...prev, [objectField]: isChecked}));
  }
  return (
    <div className = 'section'>
      <div className = 'sectionName'> {name} </div>
      <input
          type='checkbox'
          checked={object[objectField]}
          onChange={handleCheckboxChange}
        />
    </div>
  )
};

function DateInput({name, objectField, object, onChange}) {

  const handleDateChange = (event) => {
    const date = event.target.value.split('T')[0];
    onChange(prev => ({...prev, [objectField]: date}));
  }

  return (
    <div className = 'section'>
      <div className = 'sectionName'> {name} </div>
      <input type = 'date'
            value = {object[objectField]}
            onChange={handleDateChange}>
      </input>
    </div>
  )
};

function TextInput({name, objectField, object, onChange}) {

  const handleTextChange = (event) => {
    let text = event.target.value;
    onChange(prev => ({...prev, [objectField]: text}))
  }
  return (
    <div className = 'section'>
      <div className = 'sectionName'> {name} </div>
      <input type = 'text'
            value = {object[objectField]}
            onChange={handleTextChange}>
      </input>
    </div>
  )
};

function SelectInput({name, objectField, object, onChange, arrayOfObjectsInSelect}) {
  const handleSelectChange = (event) => {
    let selectedItem = event.target.value;
    onChange(prev => ({...prev, [objectField]: selectedItem}))
  }
  return (
    <div className = 'section'>
      <div className = 'sectionName'> {name} </div>
      <div>
        <select
            value={object[objectField]}
            onChange={handleSelectChange}
        >
            <option value=""> select...</option>
            {arrayOfObjectsInSelect
                ?.slice() // Create a copy of the array to avoid modifying the original array
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
                .map((item) => (
                    <option key={item.uuid} value={item.name}>
                        {item.name}
                    </option>
                ))}
        </select>
      </div>
    </div>
  )
};

function NumberInput({name, objectField, object, onChange}) {

  const handleNumberChange = (event) => {
    let number = event.target.value;
    onChange(prev => ({...prev, [objectField]: number}))
  };

  return (
    <div className = 'section'>
      <div className = 'sectionName'> {name} </div>
      <div>
        <input  type = 'number'
                value = {object[objectField]}
                onChange={handleNumberChange}>
        </input> 
      </div>
    </div>
  )
};

function RegularSection ({name, data, isCommonDataToAllForms}) {
  return (
    <div className = 'section'>
      <div className = { `sectionName ${isCommonDataToAllForms? '' : 'additionalSectionColor'}`}> {name} </div>
      <div className = 'dataSection'>
        {data}
      </div>
    </div>
  )
};

function TrueFalseSection ({name, data, isCommonDataToAllForms}) {
  return (
    <div className = 'section'>
      <div className = { `sectionName ${isCommonDataToAllForms? '' : 'additionalSectionColor'}`}> {name} </div>
      <div  className = 'dataSection' style = {{color: data?'green': 'red'}}>
        {data? Icons.check : <IoCloseOutline />}
      </div>
    </div>
  )
};

function CompanyDataForm ({setCompany, company, insertNewCompany, updateCompany, setShowCompanyForm, edit, t}) {
  return (
    <div className = 'formContainer'>
      <div className = 'form'>
        <div className = 'header'>
          <div>
            
          </div>
          <div>
            {t('geotechnics.new_company')}
          </div>
          <button onClick={() => setShowCompanyForm(false)}
                  className = 'globals--close-button'>
          </button>
        </div>
        <form onSubmit={edit? (e)=> updateCompany(e) : (e) => insertNewCompany(e)}>

          <SelectInput  name = {t('geotechnics.type')}
                        objectField = 'type'
                        object = {company}
                        onChange={setCompany}
                        arrayOfObjectsInSelect = {companyTypeOptions} />

          <TextInput  name = {t('geotechnics.name')}
                      objectField = 'name'
                      object = {company}
                      onChange={setCompany} />

          <TextInput  name = {t('geotechnics.adress')}
                      objectField = 'adress'
                      object = {company}
                      onChange={setCompany} />
          
          <TextInput  name = {t('geotechnics.post')}
                      objectField = 'post'
                      object = {company}
                      onChange={setCompany} />

          <div className = 'header'>
            <button type = 'submit' className = 'globals--regular-button'>
              {t('button.submit')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
};

function CompanyDataDescriptionbox ({t, company, setShowCompany, deleteCompany, setForEdit}) {
  return (
  <div className = 'formContainer'>
    <div className = 'form'>
      <div className = 'header'>{/* 
        <div onClick = {() => deleteCompany()}>{Icons.trashCan}</div> */}
        <button onClick = {() => deleteCompany()}
                className = 'globals--red-button'>
          {Icons.trashCan}
        </button>
        <div>{company.name}</div>
        <button onClick={() => setShowCompany(false)}
                className = 'globals--close-button'>
        </button>
      </div>

      <RegularSection name = {t('geotechnics.type')} data = {company.type} isCommonDataToAllForms={true}/>
      <RegularSection name = {t('geotechnics.adress')} data = {company.adress} isCommonDataToAllForms={true}/>
      <RegularSection name = {t('geotechnics.post')} data = {company.post} isCommonDataToAllForms={true}/>

      <div className = 'header'>
        <button onClick = {(e) => setForEdit(e)}
                className = 'globals--regular-button'>
          {t('button.edit')}
        </button>
      </div>
    </div>
  </div>
  )
};

function TemplateStructuredDataDescriptionbox ({setFormToEdit, generalData, t, currentPage}) {

  function shouldRenderInput(objectKey: string, page: number): boolean {
    return Array.isArray(sectionAppearInPageTemplate[objectKey]) && sectionAppearInPageTemplate[objectKey].includes(page);
  }

  return (
    <div>
      {shouldRenderInput('object', currentPage) && (
        <RegularSection
          name={t('geotechnics.object')}
          data={generalData.object}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('report_number', currentPage) && (
        <RegularSection
          name={t('geotechnics.reportNu')}
          data={generalData.report_number}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('project_number', currentPage) && (
        <RegularSection
          name={t('geotechnics.project_number')}
          data={generalData.project_number}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('phase', currentPage) && (
        <RegularSection
          name={t('geotechnics.phase')}
          data={generalData.phase}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('type', currentPage) && (
        <RegularSection
          name={t('geotechnics.type')}
          data={generalData.type}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('client', currentPage) && (
        <RegularSection
          name={t('geotechnics.client')}
          data={generalData.client}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('investor', currentPage) && (
        <RegularSection
          name={t('geotechnics.investor')}
          data={generalData.investor}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('value', currentPage) && (
        <RegularSection
          name={t('geotechnics.value')}
          data={generalData.value}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('order_date', currentPage) && (
        <RegularSection
          name={t('geotechnics.order_date')}
          data={generalData.order_date}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('deadline', currentPage) && (
        <RegularSection
          name={t('geotechnics.deadline')}
          data={generalData.deadline}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('object', currentPage) && (
        <TrueFalseSection
          name={t('geotechnics.submited')}
          data={generalData.submited}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('submision_date', currentPage) && (
        <RegularSection
          name={t('geotechnics.submision_date')}
          data={generalData.submision_date}
          isCommonDataToAllForms={true}
        />
      )}

      {shouldRenderInput('internal', currentPage) && (
        <TrueFalseSection
          name={t('geotechnics.internal')}
          data={generalData.internal}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('comment', currentPage) && (
        <RegularSection
          name={t('geotechnics.comment')}
          data={generalData.comment}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('wells_GGR', currentPage) && (
        <RegularSection
          name="Vrtine"
          data={generalData.wells_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('spt_GGR', currentPage) && (
        <RegularSection
          name="Spt"
          data={generalData.spt_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('dp_GGR', currentPage) && (
        <RegularSection
          name="Dp"
          data={generalData.dp_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('cpt_GGR', currentPage) && (
        <RegularSection
          name="Cpt"
          data={generalData.cpt_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('dmt_GGR', currentPage) && (
        <RegularSection
          name="Dmt"
          data={generalData.dmt_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('presio_GGR', currentPage) && (
        <RegularSection
          name="Presio"
          data={generalData.presio_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('excavations_GGR', currentPage) && (
        <RegularSection
          name="Razkopi"
          data={generalData.excavations_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('lab_GGR', currentPage) && (
        <TrueFalseSection
          name="Lab"
          data={generalData.lab_GGR}
          isCommonDataToAllForms={false}
        />
      )}

      {shouldRenderInput('hg_GGR', currentPage) && (
        <TrueFalseSection
          name="Hg"
          data={generalData.hg_GGR}
          isCommonDataToAllForms={false}
        />
      )}
      <div className = 'header'>
        <button onClick = {() => setFormToEdit()}
                className = 'globals--regular-button'>
          {t('button.edit')}
        </button>
      </div>
    </div>
  )
};

function TemplateStructuredDataForm ({submitForm, generalData, setGeneralData, t, currentPage, companiesData}) {

  let selectedTypeOptions;

  if (currentPage === geoPage.caves) {
    selectedTypeOptions = geoPageCavesTypeOptions;
  }

  if (currentPage === geoPage.geo_geotechnical_reports) {
    selectedTypeOptions = geoPageGeotechnicalReportsTypeOptions;
  }

  function shouldRenderInput(objectKey: string, page: number): boolean {
    return Array.isArray(sectionAppearInPageTemplate[objectKey]) && sectionAppearInPageTemplate[objectKey].includes(page);
  }

  return (
    <form onSubmit={(e) => submitForm(e)} className='form'>

      {shouldRenderInput('object', currentPage) ?
        <TextInput  name = {t('geotechnics.object')}
                    objectField = 'object'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }
      
      {shouldRenderInput('report_number', currentPage) ?
        <NumberInput  name = {t('geotechnics.reportNu')}
                      objectField = 'report_number'
                      object = {generalData}
                      onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('project_number', currentPage) ?
          <TextInput  name = {t('geotechnics.project_number')}
                      objectField = 'project_number'
                      object = {generalData}
                      onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('phase', currentPage) ?
        <SelectInput  name = {t('geotechnics.phase')}
                      objectField='phase'
                      object = {generalData}
                      onChange={setGeneralData}
                      arrayOfObjectsInSelect={geoPageGeoPhaseOptions} />
      :
        null
      }

      {shouldRenderInput('type', currentPage) ?
        <SelectInput  name = {t('geotechnics.type')}
                      objectField='type'
                      object = {generalData}
                      onChange={setGeneralData}
                      arrayOfObjectsInSelect={selectedTypeOptions} />
      :
        null
      }
      {shouldRenderInput('client', currentPage) ?
        <SelectInput  name = {t('geotechnics.client')}
                      objectField = 'client'
                      object = {generalData}
                      onChange={setGeneralData}
                      arrayOfObjectsInSelect={companiesData} />
      :
        null
      }

      {shouldRenderInput('investor', currentPage) ?
        <SelectInput  name = {t('geotechnics.investor')}
                      objectField = 'investor'
                      object = {generalData}
                      onChange={setGeneralData}
                      arrayOfObjectsInSelect={companiesData} />
      :
        null
      }

      {shouldRenderInput('value', currentPage) ?
        <TextInput  name = {t('geotechnics.value')}
                    objectField = 'value'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }
      {shouldRenderInput('order_date', currentPage) ?
        <DateInput  name = {t('geotechnics.comissionDate')}
                    objectField = 'order_date'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('deadline', currentPage) ?
        <DateInput  name = {t('geotechnics.deadline')}
                    objectField = 'deadline'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('submited', currentPage) ?
        <CheckBoxInput  name = {t('geotechnics.submited')} 
                        objectField='submited'
                        object={generalData}
                        onChange={setGeneralData}/>
      :
        null
      }

      {shouldRenderInput('submision_date', currentPage) ?
        <DateInput  name = {t('geotechnics.submision_date')}
                    objectField = 'submision_date'
                    object = {generalData}
                    onChange={setGeneralData} />
      : 
        null
      }

      {shouldRenderInput('internal', currentPage) ?      
        <CheckBoxInput  name = {t('geotechnics.internal')}
                        objectField='internal'
                        object={generalData}
                        onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('comment', currentPage) ?      
        <TextInput  name = {t('geotechnics.comment')}
                    objectField = 'comment'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('wells_GGR', currentPage) ?
        <TextInput  name = 'Vrtine'
                    objectField = 'wells_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('spt_GGR', currentPage) ?
        <TextInput  name = 'Spt'
                    objectField = 'spt_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('dp_GGR', currentPage) ?
        <TextInput  name = 'Dp'
                    objectField = 'dp_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('cpt_GGR', currentPage) ?
        <TextInput  name = 'Cpt'
                    objectField = 'cpt_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('dmt_GGR', currentPage) ?
        <TextInput  name = 'Dmt'
                    objectField = 'dmt_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('presio_GGR', currentPage) ?
        <TextInput  name = 'Presio'
                    objectField = 'presio_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('excavations_GGR', currentPage) ?
        <TextInput  name = 'Razkopi'
                    objectField = 'excavations_GGR'
                    object = {generalData}
                    onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('lab_GGR', currentPage) ?
        <CheckBoxInput  name = 'Lab'
                        objectField='lab_GGR'
                        object={generalData}
                        onChange={setGeneralData} />
      :
        null
      }

      {shouldRenderInput('hg_GGR', currentPage) ?
        <CheckBoxInput  name = 'Hg'
                        objectField='hg_GGR'
                        object={generalData}
                        onChange={setGeneralData} />
      :
        null
      }

      <div className = 'header'>
        <button type = 'submit' className = 'globals--regular-button'>
          {t('button.submit')}
        </button>
      </div>
    </form>
  )
};

export default React.memo(GeoTable);
