import React, { useEffect, useRef, useState } from "react";
import { PageNumber, Sensor, SetNewVirtualTourNodeProps } from "../../functions_interfaces/interfaces";
import IfcViewer from "./IFC.viewer"
import axios from "axios";
import { addIfcZipRoute, getIfcNodetreeRoute } from "../../utils/routes";
import { getModuleNodetreeMenu } from "../monitoring/_monitoring";


interface IfcRepositoryProps {
  VirtualTourReferences: any;
}
const IfcRepository: React.FC<IfcRepositoryProps> = ({VirtualTourReferences}) => {

    const [IfcNodetreeData, setIfcNodetreeData] = useState<Sensor[]>()
    const [currentModelRoute, setCurrentModelRoute] = useState<string | null>(null);

    
    useEffect(() => {
        getMonitoringNodeTreeInsideComponent();
    }, []);
    
    async function getMonitoringNodeTreeInsideComponent() {
      const monitoringNodeTreeData = await getModuleNodetreeMenu(PageNumber.ifc_repository, getIfcNodetreeRoute) as Sensor[]
      setIfcNodetreeData(monitoringNodeTreeData)
    }
    VirtualTourReferences.getVirtualTourNodeTree = getMonitoringNodeTreeInsideComponent;

    return (
        <div style = {{backgroundColor:'rgba(0,0,0,0.7)'}}>
          <header className = 'globals--module-navbar'>
              <div className='globals--module-navbar-title'>
              {IfcNodetreeData &&
                <div  className='globals--module-navbar-dropdown-tree-menu'>
                  <RenderThirdLevel firstLevelItems={IfcNodetreeData} 
                                    secondLevelItems={IfcNodetreeData} 
                                    VirtualTourReferences={VirtualTourReferences}
                                    setCurrentModelRoute = {setCurrentModelRoute}/>
                </div>}
                  IFC Viewer
              </div>
          </header>
          <div>
            <InsertVirtualTourNode VirtualTourReferences = {VirtualTourReferences}/>
          </div>
          <div style = {{height:'100vh'}}>
            {currentModelRoute && <IfcViewer modelRoute={currentModelRoute as string}/>}
          </div>
        </div>
    )
}

export default IfcRepository;

function RenderThirdLevel({firstLevelItems, secondLevelItems, VirtualTourReferences, setCurrentModelRoute}) {

    const handleNewSensorClick = () => {
      setNewTreeItemBasedOnType({
        isProject: false,
        isSensor: true,
        parentUUID: secondLevelItems.uuid,
        name: '',
        projectData: firstLevelItems,
        workplaceData: secondLevelItems,
        VirtualTourReferences: VirtualTourReferences
      });
    };
  
    return (
      <>
        <div 
          onClick={handleNewSensorClick} 
          className='add-new-item sensor'
        >
          New Ifc Model
        </div>
  
        {secondLevelItems.map(thirdLevelItem => (
          <ThirdLevelItem 
            key={thirdLevelItem.uuid}
            setCurrentModelRoute = {setCurrentModelRoute}
            firstLevelItem={firstLevelItems}
            secondLevelItem={secondLevelItems}
            thirdLevelItem={thirdLevelItem}
            VirtualTourReferences = {VirtualTourReferences}
          >
          </ThirdLevelItem>
        ))}
      </>
    );
  }

  //------------------------------------------------------------------------------------------------------
  const ThirdLevelItem = React.memo(({ thirdLevelItem, openMenu, setCurrentModelRoute }: any) => {
    console.log(thirdLevelItem.name);
    const handleVirtualTourClick = () => {/* 
      openMenu(thirdLevelItem.file_name); */
      setCurrentModelRoute(thirdLevelItem.file_name);
    }
    return (
        <div className="TreeItem" onClick={handleVirtualTourClick}>
          
            <div  className="TreeItem-label" >
                  {thirdLevelItem.name}
            </div>
            
        </div>
    );
  });
  
  export function InsertVirtualTourNode ({VirtualTourReferences}) {
  
    const [newItemState, setNewItemState]= useState<any>()
    const [showComponent, setShowComponent] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [selected, setSelected] = useState(false);
    const fileRef = useRef<any>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showUploadProgressBar, setShowUploadProgressBar] = useState(false);
  
  
    if (VirtualTourReferences) {
      VirtualTourReferences.setShowInsertForm = setShowComponent;
    }
  
    const handleNewNodeItemClose = () => {
        setShowComponent(false);
        setSelectedFile(null);
        setNewItemState(VirtualTourReferences.data);
        setSelected(false);     // This line resets the selected state to false
        if (fileRef.current) {
          fileRef.current.value = null;  // This line resets the value of the file input field
        }
    };
  
    async function submitFile() {
    
      const formData = new FormData();
      formData.append('zipFile', selectedFile);
      formData.append('nodeTreeItemData', JSON.stringify(newItemState));
    
      try {
        setShowUploadProgressBar(true);
        const response = await axios.post(addIfcZipRoute, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        });
  
        if (response && response.data.resolved) {
          setShowComponent(false);
          VirtualTourReferences.getVirtualTourNodeTree()
          handleNewNodeItemClose();
          setShowUploadProgressBar(false);
        } else {
          window.alert(response.data.message);
        }
  
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };
  
    const handleFileSubmit = () => {
      submitFile();
    }
  
  
    const isButtonDisabled = () => {
      if (newItemState?.isSensor) {
        if (newItemState?.name.length > 0) {
  
          return !selected;
        } else {
          return true;
        }
      } else {
        console.log(newItemState?.name.length <= 0)
        return newItemState?.name.length <= 0;
      }
    };
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setSelectedFile(file);
        setSelected(true);
      } else {
        setSelected(false);
      }
    };
  
  
    useEffect(() => {
        setNewItemState(VirtualTourReferences?.data);
    },[VirtualTourReferences?.data]);
  
  
    return(
        <div className = 'insertNodeFormContainer' style = {{ display: showComponent? '':'none'}}>
            <div style = {{opacity: '1', backgroundColor: newItemState?.isProject? '#007BFF': newItemState?.isSensor?'#FFA500': '#008248'}} className='insertNodeForm'>
              <div className="gridContainer">
                            
                {newItemState?.isSensor && 
                  <div>
                    <div>
                        <span>Model Name:</span>
                        <span>{newItemState?.name}</span>
                    </div>
                    <div>
                    <input type="file" ref={fileRef} accept=".zip" onChange={handleFileChange} />
                    </div>
                  </div> 
                }
              </div>
              <textarea placeholder='Insert Name' 
                        onChange = {(e) => setNewItemState(prev => ({...prev, name: e.target.value}))}
                        value = {newItemState?.name}
                        style = {{gridColumn: 'span 2'}}/>
              <div style = {{gridColumn: 'span 2', textAlign:'center', display: showUploadProgressBar?'': 'none', backgroundColor:'darkred' }}>
                Uploaded {uploadProgress}%
              </div>
              
              <button onClick = {handleFileSubmit}
                      disabled = {isButtonDisabled()}>
                Confirm ok
              </button>
              <button onClick = {handleNewNodeItemClose}>
                Cancel
              </button>
            </div>
        </div>
    )
  }
  
  
  function setNewTreeItemBasedOnType({
    isProject,
    isSensor,
    parentUUID,
    name = '',
    projectData,
    workplaceData,
    virtualTourData,
    VirtualTourReferences
  }: SetNewVirtualTourNodeProps) {
  
    const monitoringData = {
      name,
      isProject,
      isSensor,
      parentUUID,
      projectData,
      workplaceData,
      virtualTourData,
    };
  
    VirtualTourReferences.data = monitoringData;
    VirtualTourReferences.setShowInsertForm(true);
  }
  
  
  
  
  
  
  
  