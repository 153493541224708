import {DEV} from './cfg'

export const backendServer: string = DEV? 'https://data.irgo.si/server/': 'http://localhost:9090/server/';//10.16.38.103

// Globaly used routes
export const getBasicUserDataRoute: string = backendServer + 'getBasicUserDataRoute'; //Problem => check backend

// index.tsx Component
export const decrypt: string = backendServer + 'decrypt';

// Login.tsx Component
export const loginRoute: string = backendServer + 'login';
export const resetpasswordRoute: string = backendServer + 'reset-password';

//------------------------------------------------------------------------------------------------------------
// Shared routes in this section
export const getPartialDataRoute: string = backendServer + 'getPartialDataRoute';
export const getSingleNodeDataRoute: string = backendServer + 'getSingleNodeDataRoute';

// Main.tsx Component 
export const deletePreviewDataRoute: string = backendServer + 'deletePreviewDataRoute';
export const confirmAndInsertPreviewDataRoute: string = backendServer + 'confirmAndInsertPreviewDataRoute';
export const uploadExcelRoute: string = backendServer + 'uploadExcelRoute';
export const searchDatabaseRoute: string = backendServer + 'searchDatabaseRoute';
export const getTotalNodesCountRoute: string = backendServer + 'getTotalNodesCountRoute';
// {
    // Overview.tsx Component
    export const getOverviewNodeListRoute: string = backendServer + 'getOverviewNodeListRoute';
    export const getHistoryLogsOfNodeRoute: string = backendServer + 'getHistoryLogsOfNodeRoute';

    // Recursive.tsx Component
    export const updateSectionRoute: string =  backendServer + 'updateSectionRoute';

    // DescriptionBox.tsx Component
    export const deleteRoute: string = backendServer + 'deleteRoute';
    export const addPathRoute: string =  backendServer + 'addPathRoute';
    export const updateRoute: string = backendServer + 'updateRoute';
//}
//----------------------------------------------------------------------------------------------------------

// Profile.tsc Component
export const updateUserDataRoute: string = backendServer + 'updateUserDataRoute';
export const convertCsvExcelTimetableRoute: string = backendServer + 'convertCsvExcelTimetableRoute';

// UsersTable.tsx Component
export const updateUserDataAsAdminRoute: string = backendServer + 'updateUserDataAsAdminRoute';
export const deleteUserRoute: string = backendServer + 'deleteUserRoute';
export const registerRoute: string = backendServer + 'register';
export const getPagesDataRoute: string = backendServer + 'getPagesDataRoute';
export const getUsersTableRoute: string = backendServer + 'getUsersTableRoute';

// geotechnics.tsx Component
export const companyDataRoute: string = backendServer + 'companyDataRoute';
export const updateCompanyRoute: string = backendServer + 'updateCompanyRoute';
export const insertCompanyRoute: string = backendServer + 'insertCompanyRoute';
export const deleteCompanyRoute: string = backendServer + 'deleteCompanyRoute';
export const insertGeoDataRoute: string = backendServer + 'insertGeoDataRoute';
export const getGeoTableRoute: string = backendServer + 'getGeoTableRoute';
export const updateGeoDataRoute: string = backendServer + 'updateGeoDataRoute';
export const deleteGeoDataRoute: string = backendServer + 'deleteGeoDataRoute';

// GroupsTable.tsx Component
export const insertNewGroupRoute: string = backendServer + 'insertNewGroupRoute';
export const groupsDataRoute: string = backendServer + 'groupsDataRoute';
export const deleteGroupRoute: string = backendServer + 'deleteGroupRoute';
export const updateGroupRoute: string = backendServer + 'updateGroupRoute';

// virtualTour.tsx Component
export const addFolderRoute: string = backendServer + 'addFolderRoute';
export const getVirtualTourNodeTreeRoute: string = backendServer + 'getVirtualTourNodeTreeRoute';
export const virtualTourRoute: string = backendServer + 'virtualTourRoute/';

export const kmlRoute: string = backendServer + 'kmlRoute/';
// monitoring.tsx Component
export const addFileRoute: string = backendServer + 'addFileRoute';
export const appendFileRoute: string = backendServer + 'appendFileRoute';
export const deleteFileRoute: string = backendServer + 'deleteFileRoute';
export const downloadFileRoute: string = backendServer + 'downloadFileRoute';
export const updatePdfFileRoute: string = backendServer + 'updatePdfFileRoute';
export const getMeasurmentsRoute: string = backendServer + 'getMeasurmentsRoute';
export const getMonitoringNodeTreeRoute: string = backendServer + 'getMonitoringNodeTreeRoute';
export const newMonitoringTreeItemRoute: string = backendServer + 'newMonitoringTreeItemRoute';
export const reprocessFilesRoute: string = backendServer + 'reprocessFilesRoute';
export const updateConfigJsonRoute: string = backendServer + 'updateConfigJsonRoute';
export const servePdfFileRoute: string = backendServer + 'servePdfFileRoute';
export const deleteMonitoringNodeRoute: string = backendServer + 'deleteMonitoringNodeRoute'

// Routes that need implementing
export const register: string = backendServer + 'register'; // register functionality must be implemented


// AccessTable.tsx Component

export const mod_getUsersDataRoute: string = backendServer + 'mod_getUsersDataRoute';
export const mod_getPagesDataRoute: string = backendServer + 'mod_getPagesDataRoute';
export const mod_updateUserAccessRoute: string = backendServer + 'mod_updateUserAccessRoute';
export const jantarLoginRoute: string = backendServer + 'jantarLoginRoute';
export const getMeasurementAdditionalInfoRoute: string = backendServer + 'getMeasurementAdditionalInfoRoute'
export const fetchTagsRoute: string = backendServer + 'fetchTagsRoute';
export const unifyDuplicatedTagsRoute: string = backendServer + 'unifyDuplicatedTagsRoute';
export const deleteTagRoute: string = backendServer + 'deleteTagRoute';
export const getTagFilteredNodesRoute: string = backendServer + 'getTagFilteredNodesRoute';

export const mod_getUserNodeTreeUUIDsFlatList: string =  backendServer + 'mod_getUserNodeTreeUUIDsFlatList'
export const mod_getUserAttributeRestrictrionsRoute: string =  backendServer + 'mod_getUserAttributeRestrictrionsRoute';
export const mod_getModuleNodetreeRoute: string =  backendServer + 'mod_getModuleNodetreeRoute';

export const getGeotechnicsNodetreeMenuRoute: string =  backendServer + 'getGeotechnicsNodetreeMenuRoute'

export const getQgisNodeTreeRoute: string =  backendServer + 'getQgisNodeTreeRoute'
export const QgisRoute: string =  backendServer + 'QgisRoute/'
export const addQgisFolderRoute: string =  backendServer + 'addQgisFolderRoute'

export const notesRoute: string = backendServer + 'notes/';

export const getIfcNodetreeRoute: string =  backendServer + 'getIfcNodetreeRoute'
export const getOnlyUsersNodesRoute: string =  backendServer + 'getOnlyUsersNodesRoute'

export const notificationRoute: string = backendServer + 'notificationRoute';

export const insertDepartureRoute: string = backendServer + 'insertDeparture';
export const getDeparturesRoute: string = backendServer + 'getDepartures';
export const updateDeparturesRoute: string = backendServer + 'updateDeparturesRoute';
export const getBannerTextRoute: string = backendServer + 'getBannerTextRoute';
export const editBannerTextRoute: string = backendServer + 'editBannerTextRoute';

export const modelsRoute: string = backendServer + 'models/'
export const addIfcZipRoute: string = backendServer + 'addIfcZipRoute';
export const refreshFtpDataRoute: string = backendServer + 'refreshFtpDataRoute';
export const fetchFtpFileListRoute: string = backendServer + 'fetchFtpFileListRoute';
export const updateDatabaseFTPdata: string = backendServer + 'updateDatabaseFTPdata';

// Routes that got updated but functionality might still be useful

//----------------------------------------------------------------------------------------------------------
// => export const getTableRoute: string =  backendServer + 'getTableRoute';

/* This route served the purpose of gettig and nesting whole data structure of "Main.tsx" nodeTree
    functionality of this route still stands in backend */
//----------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------
// => export const cleanTableRoute: string =  backendServer + 'cleanTableRoute';

/* This route served the purpose of deleting everything from "Main.tsx" data table. Its useful for local dev purposes
    functionality of this route still stands in backend (needs to be uncommented in backend) */
//----------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------
// => export const checkIfNodeExistsRoute: string = backendServer + 'checkIfNodeExistsRoute';

/* This route served the purpose of checking "Main.tsx" data table for existance of single item. It will be useful
    in later developement. functionality of this route still stands in backend (needs to be uncommented in backend) */
//----------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------
// => export const insertNewUserRoute: string  = backendServer + 'insertNewUserRoute';

/* This route served is outdated and will likely be removed when checked */
//----------------------------------------------------------------------------------------------------------