export const stringHourMinuteLength = 2;
export const stringShortLength = 10;
export const stringDefaultLength = 30;
export const stringLongLength = 50;
export const stringMaxLength = 100;
export const stringDescriptionLength = 1000;
export const passwordMinLength = 8;
export const passwordDefaultLength = 12;
export const passwordMaxLength = 36;
export const numberMaxLength = 5;
export const defaultPageSize = 10;
export const extendedPageSize = 20;
export const extendedPlusPageSize = 25;
export const specialCharacters = '!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~\\';
export const PASSWORD_REQUIREMENTS = [1, 1, 1, 1];
export const linesPerPage = 25;

export function numberWithCommas(x) {
  if (x === undefined) {
    x = 0;
  }
  const separator = ',';
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return parts.join('.');
}
