import React from "react"
import { IoFilter } from "react-icons/io5";

const MainManual:React.FC = () => {
    return (
        <div className='resizable-popup' style = {{scrollBehavior:'smooth'}}>
            <div style = {{backgroundColor:'white', textAlign:'justify', padding: '2em'}}>
                <div>
                    <h1 style = {{textAlign:'center'}}>Navodila</h1>
                    <h2 id = 'nav'>Kazalo</h2>
                    <ul >
                        <li><a href = '#about'>Predstavitev</a></li>
                        <li><a href = '#elements'>Glavni Elementi</a>
                            <ul>
                                <li><a href = '#inserting'>Kreiranje Elementov</a></li>
                            </ul>
                        </li>
                        <li><a href = '#descriptionBox'>Opisno Okno</a>
                            <ul>
                                <li><a href = '#deleting' style = {{color:'red', fontWeight:'bold'}}>Brisanje Elementov</a></li>
                            </ul>
                        </li>
                        <li><a href = '#search'>Search Bar</a></li>
                        <li><a href = '#tags'>Tags Filter</a></li>
                        <li><a href = '#buttons'>Orodna Vrstica Z Gumbi</a>
                            <ul>
                                <li><a href = '#tagseditor'>Tags Urejevalno okno</a></li>
                                <li><a href = '#myblueprints'>Moji Načrti</a></li>
                                <li><a href = '#overview'>Pregled</a></li>
                                <li><a href = '#moveitem'>Premakni Elemente</a></li>
                                <li><a href = '#refresh'>Osveži</a></li>
                            </ul>
                        </li>
                        
                    </ul>
                    <img src = 'navodila_main.png' style = {{objectFit:'cover', width: '100%'}}/>
                    <h2 id='about'><a href = '#nav'>Predstavitev</a></h2>
<p>Repozitorij gradbenih načrtov predstavlja online alternativo excelovim dokumentom. Portal omogoča sočasno urejanje, filtriranje in iskanje načrtov, določanje pravic različnim uporabnikom ter ponuja orodja za sledljivost in analizo. Naš sistem je zasnovan za fleksibilnost, kar omogoča nadaljnje nadgradnje glede na potrebe uporabnikov, in je prototipna rešitev za učinkovito iskanje in shranjevanje načrtov in dokumentov.</p>
                    <h2 id= 'elements'><a href = '#nav'>Glavni Elementi</a></h2>
                    <p>Glavni elementi na naši platformi so organizirani v drevesno strukturo, ki omogoča hiter dostop do relevantnih informacij. S klikom na puščico na levi strani elementa se odpre naslednji nivo strukture s podelementi. Klik na element aktivira opisno okno v desnem oknu, kjer so na voljo podrobne informacije in možnosti za urejanje.</p>
                    <h3 id = 'inserting'><a href = '#nav'>Kreiranje Elementov</a></h3>
                    <img src = 'elementcontext.png' style = {{objectFit:'cover', width: '100%'}}/>
                    <p>Elemente lahko ustvarite z desnim klikom na ime nadrejenega elementa (parenta) in izbiro možnosti "add new item" iz kontekstnega menija, ki se pojavi. To dejanje ustvari nov element z začasnim imenom "🚨🚨🚨🚨🚨NEW ITEM🚨🚨🚨🚨🚨". Za nadaljnje postopke, kliknite na ta nov element, kar ga lokalno kreira. Nato morate urediti podatke v opisnem oknu in potrditi kreacijo z izbiro gumba "Submit". V primeru, da ste element ustvarili po pomoti in ga ne želite shraniti, preprosto osvežite podatke z gumbom "Refresh", da odstranite neželeni element. Ta postopek zagotavlja, da so vsi novi elementi pravilno in premišljeno dodani v sistem.</p>

                    
                    <h2 id = 'descriptionBox'><a href = '#nav'>Opisno Okno</a></h2>
                    <p>Opisno okno omogoča podrobne informacije in urejanje elementov za uporabnike, ki imajo dostop. Okno vsebuje naslednje dele:</p>
                    <ul>
                        <li><strong>Tags:</strong> Ključne besede, ločene z ";", za iskanje v tagfilter komponenti.</li>
                        <li><strong>Type of Item:</strong> Izbira med "Divider" (element vsebuje podelemente) ali "Item" (samostojen element).</li>
                        <li><strong>Name:</strong> Ime elementa.</li>
                        <li><strong>Priority Number:</strong> Prioritetno število, ki določa zaporedje pojavljanja elementov; višje število pomeni višjo pozicijo na seznamu.</li>
                        <li><strong>Type of Target:</strong> Vrsta dokumenta (npr. 3D, dokument, 2D).</li>
                        <li><strong>Section</strong> in <strong>ClassID:</strong> Dodatni identifikatorji za organizacijo.</li>
                        <li><strong>Scale:</strong> Merilo, navedeno kot niz (npr. 1:50).</li>
                        <li><strong>Status checkbox:</strong> Možnost za določanje statusa elementa.</li>
                        <li><strong>Status:</strong> Če je StatusBox obkljukan, se omogoči vnos statusa, ki ga lahko spreminjamo; ob spremembi statusa je priporočljivo dodati kratki opis spremembe.</li>
                        <li><strong>FileName:</strong> Samodejno generirano ime datoteke.</li>
                        <li><strong>Path:</strong> Polna pot elementa, vključno z datoteko.</li>
                        <li><strong>Working on Item:</strong> Osebe, ki delajo na elementu, kar omogoča filtriranje in hitrejši dostop do elementov.</li>
                        <li><strong>Date Created, Date Finished, Date Submitted, Deadline:</strong> Datumi nastanka, zaključka, oddaje in rok oddaje dokumenta.</li>
                        <li><strong>Submitted File Name:</strong> Ime, pod katerim se mora element oddati.</li>
                        <li>Za potrditev vseh sprememb je potrebno klikniti na gumb <strong>"SUBMIT"</strong>.</li>
                    </ul>

                    <p><strong>Za potrditev vseh sprememb je potrebno klikniti na gumb "SUBMIT".</strong></p>

                    <h3 id = 'deleting'><a href = '#nav'  style = {{color:'red', fontWeight:'bold'}}>Brisanje Elementov</a></h3>
                    <p><strong>Elementi v našem sistemu se brišejo rekurzivno, kar pomeni, da ko izbrišete en element, se samodejno izbrišejo tudi vsi njegovi podelementi. To je izredno pomembno upoštevati, saj lahko nenameren izbris nekega glavnega elementa povzroči izgubo obsežnih delov podatkov. Pred izbrisom skrbno preverite, kateri elementi bodo vključeni v postopek, da se izognete nenamerni izgubi pomembnih informacij.</strong></p>
                

        	        <h2 id = 'search'><a href = '#nav'>Search Bar</a></h2>
                    <p>Search bar na naši platformi omogoča iskanje po ključnih besedah v celotni bazi podatkov. Za izvedbo iskanja je potrebno vnesti ključne besede in klikniti na gumb "Search". Pri vnosu ključnih besed bodite specifični, saj preveč splošne besede lahko vrnejo obsežne rezultate, tudi do 10.000 elementov. Če želite zapustiti rezultate iskanja in se vrniti na izhodiščno stanje, kliknite na search bar in pritisnite tipko "ESC".</p>
                
                    <h2 id = 'tags'><a href = '#nav'>Tags Filter <IoFilter /></a></h2>
                    <p>Sekcija za tags omogoča dinamično upravljanje ključnih besed. Ko vnesete nov tag v opisno okno, se ta avtomatsko shrani in prikaže v sekciji za tags. Klik na katerikoli tag filtrira in prikaže vse elemente, ki so označeni s tem tagom. Stopnja natančnosti filtriranja je 40%, kar pomeni, da mora biti vsaj 40% tagov pravilno izbranih za element, da se ta še naprej prikazuje med rezultati; z drugimi besedami, do 60% tagov lahko izberete napačno, preden se element neha prikazovati. Čeprav je izbira tagov prosta, priporočamo, da se za boljšo organiziranost in iskanje tagi vnašajo z <strong>velikimi črkami</strong> in da vsak tag vsebuje samo <strong>eno ključno besedo (npr., namesto "predor oblaz", uporabite "predor", "oblaz").</strong></p>

                    <h2 id = 'buttons'><a href = '#nav'>Orodna Vrstica Z Gumbi</a></h2>
                    <img src = 'buttonbar.png' style = {{objectFit:'cover', width: '100%'}}/>

                    <h3 id = 'tagseditor'><a href = '#nav'>Tags Urejevalno Okno (Gumb)</a></h3>
                    <img src = 'tags.png' style = {{objectFit:'cover', width: '100%'}}/>
                    <p>V oknu za upravljanje tagov je prikazan seznam vseh kreiranih tagov, ki omogoča tudi njihovo urejanje. Za doslednost in jasnost je priporočljivo, da se tagi kreirajo z velikimi črkami. Če pride do inkonsistence ali duplikatov, kot so na primer (KARAVANKE, Karavanke, KaRaVaNkE), ta komponenta omogoča združitev vseh variacij v enotno obliko, ki jo uporabnik sam izbere. Poleg tega komponenta prikazuje tudi seznam neuporabljenih tagov, ki jih je za boljšo urejenost in preglednost mogoče izbrisati.</p>
                    
                    <h3 id = 'myblueprints'><a href = '#nav'>Moji Načrti (Gumb)</a></h3>
                    <p>Ta gumb omogoča filtriranje celotne drevesne strukture, da se prikažejo samo elementi, na katerih dela prijavljeni uporabnik. Vsi itemi, kjer je v sekciji "Working on Item" navedeno ime uporabnika, bodo postali vidni, medtem ko se vsi ostali elementi skrijejo. Ta funkcija omogoča uporabnikom, da hitro najdejo in dostopajo do svojih nalog, kar izboljšuje učinkovitost in osredotočenost na delo.</p>
                    
                    <h3 id = 'moveitem'><a href = '#nav'>Premakni Elemente (Gumb)</a></h3>
                    <img src = 'element.png' style = {{objectFit:'cover', width: '100%'}}/>
                    <p>Ta sekcija je namenjena premikanju elementov iz enega nadrejenega elementa (parenta) na drugega. Vsak item ima svoj identifikacijski številko (ID). Ko uporabnik klikne na to sekcijo, se prikažejo skrite informacije o itemih, vključno z njihovim ID-jem. Če želite premakniti item pod drug nadrejeni element, kopirate ID želenega nadrejenega elementa v polje "Parent ID" pri itemu, ki ga želite premakniti, in potrdite spremembo. Po premiku itema je priporočljivo uporabiti gumb "Osveži", da se podatki v sistemu posodobijo in odražajo novo strukturo. Ta postopek omogoča učinkovito reorganizacijo elementov znotraj drevesne strukture, zagotavljajoč, da so vsi podatki ažurni in pravilno razporejeni.</p>
                    
                    <h3 id = 'overview'><a href = '#nav'>Pregled (Gumb)</a></h3>
                    <img src = 'elementcontext.png' style = {{objectFit:'cover', width: '100%'}}/>
                    <p>Gumb "Pregled odpre statistični pregled itemov. Statistika se dela od izbranega parent itema navzdol kar pomeni, da je potrebno vstopno točko najprej določiti z desnim klikom na ime itema in izbiro opcije "set status graph here" v izbirnem oknu. S tem se gum "pregled" aktivira kar indicira, da imamo vstopno točko izbrano. Zdaj lahko kliknemo na gumb.</p>
                    
                    <h3 id = 'refresh'><a href = '#nav'>Osveži (Gumb)</a></h3>
                    <p>
Gumb "Refresh" služi za direktno osveževanje podatkov v sistemu. S pritiskom na ta gumb se informacije v aplikaciji takoj posodobijo. Priporočljivo je, da uporabniki uporabljajo ta gumb vsakič, ko želijo izvesti posodobitev, saj to zagotavlja, da so informacije, ki jih urejajo ali pregledujejo, v trenutku osvežitve popolnoma aktualne.</p>
                    <div style={{position: 'relative', padding:'56.25% 0 0 0'}}>
                        <iframe
                            src="https://player.vimeo.com/video/934737811#t=2m45s?badge=0&autopause=0&player_id=0&app_id=58479"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            style={{position:'absolute',top:0, left:0, width: '100%', height: '100%' }}
                            title="Repozitorij za Gradbene Načrte"
                        ></iframe>
                    </div>

                </div>
                
            </div>
        </div>
    )
}
export default MainManual;