import { useEffect, useState } from "react"
import { notesRoute } from "../../utils/routes"
import Cookies from "js-cookie"


const Notes: React.FC = () => {

    const [token, setToken] = useState<string | null>(null);
    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            setToken(token);
        } else {
            console.error('Token not found in cookies');
        }
    }, []);


    return (
        <div style={{ width: '100%',height:'100vh'}}>
            {token &&
                <iframe
                    src={`${notesRoute}matm_users_guide?token=${encodeURIComponent(token)}`}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                />
            }
        </div>
    )
}

export default Notes;