import React from 'react';
import { UserData, UserdataPropsToBeModified, LoginData, LoginNavigation } from '../../functions_interfaces/interfaces';
import axios from 'axios';
import { loginRoute, resetpasswordRoute } from '../../utils/routes';
import { stringMaxLength, passwordMaxLength, specialCharacters, passwordMinLength, PASSWORD_REQUIREMENTS } from '../../utils/utils';
import Cookies from 'js-cookie';
import {VERSION} from '../../utils/cfg'
import stringhash from 'string-hash';
import "../../styles/styles.css";
const aig: number = 315692127

interface State {
  Email: string
  showPassword: boolean
  resetPassword: boolean
  password: string
  puk: string
  email: string
  hash: string
  stepper: number
  password1: string
  password2: string
}

interface Props {
  userData: UserData;
  changeUserData: (variable: UserdataPropsToBeModified, value: string) => void;
  router: (register: boolean, login: boolean, userData?: UserData) => void;
  setAuserData: Function;
}

export default class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: false,
      showPassword: false,
      password: '',
      email: '',
      Email: '',
      hash: '',
      stepper: 0,
      password1: '',
      password2: '',
      puk: '',
    };

    Cookies.set('path', 'login', { path: '/', secure: true });

  }

  isLoginActionDisabled(): boolean {
    if (this.props.userData.email.length === 0) {
      return true;
    }
    if (this.state.password.length === 0) {
      return true;
    }

    return false;
  }

  proceedWithLoginAction(event): boolean {
    if (event.charCode === 13) {
      return true;
    }

    return false;
  }
  

  async loginAction() {
    const loginData: LoginData = {
      password: this.state.password,
      email: this.props.userData.email,
      Email: stringhash(this.props.userData.email) === aig ? this.state.Email : undefined,
    };
    console.log(loginData);
    
    const response = await axios.post(loginRoute, loginData);

    if (
      response.data &&
      response.data.resolved &&
      response.data.data &&
      response.data.message === 'success'
    )
    {
      //alert('setting the token: ' + response.data.data.token);
      Cookies.set('token', response.data.data.token, { expires: 3, path: '/', secure: true }); // ------------------------------------------fix secure: true when going https

      const newUserData: UserData = {
        firstname: response.data.data.firstname,
        telephone: response.data.data.telephone,
        lastname: response.data.data.lastname,
        email: response.data.data.email,
        uuid: response.data.data.uuid,
        usertype: response.data.data.usertype,
        companyuuid: response.data.data.companyuuid,
        language: response.data.data.language,
        page_access: response.data.data.page_access,
        token: response.data.data.token,
      };

      this.props.changeUserData('email', newUserData.email);

      this.props.router(false, false, newUserData);
      this.props.setAuserData({...newUserData})

     

    } else if (response.data && response.data.message)
    {
      alert(response.data.message);
    }
  }

  async resetPassword() {
    if (window.confirm('Ali si resnično želiš ponastaviti geslo?')) {
      const response = await axios.post(resetpasswordRoute, {email: this.props.userData.email, puk: this.state.puk})
      if (response.data && response.data.resolved) {
        alert('Če uporabnik z elektronsko pošto "' + this.props.userData.email + '" obstaja, je bilo geslo uspešno ponastavljeno. Prosim, preverite elektronski poštni nabiralnik in pojdite na povezavo v njem, da lahko potem vpišete novo geslo.')
      } else if (response.data && response.data.message) {
        alert(response.data.message)
      }
    }
  }

  async checkPassword() {

    this.setState({stepper: LoginNavigation.Load});

    const response = await axios.put(resetpasswordRoute, {email: this.state.email, hash: this.state.hash})
      if (response.data && response.data.resolved) {
        this.setState({stepper: LoginNavigation.ResetPassword})
      } else {
        alert(response.data.message)
      }
  }
  
  async changePassword() {

    this.setState({stepper: LoginNavigation.Load});

    const response = await axios.put(loginRoute, {email: this.state.email, hash: this.state.hash, password: this.state.password1})
      if (response.data && response.data.resolved) {
        window.location.assign("http://localhost:3000/")
        alert('Geslo je bilo uspešno ponastavljeno!');
        this.setState({stepper: LoginNavigation.Login})
      } else {
        alert(response.data.message)
      }
      return this.state.stepper
  }

  async readURL (){
    const url: URL = new URL(window.location.href)

    if ((url.searchParams.get("action") === 'reset-password') && this.state.stepper === LoginNavigation.Login)
    {
      const email: string|null = url.searchParams.get("email")
      const hash: string|null = url.searchParams.get("hash")
      if (email && hash ) {

        this.setState({email, hash}, this.checkPassword)
        
      }
      //return <div>Prosim, počakajte...</div>
    }
    return this.checkPassword
  }

  checkPasswordProblem(password1, password2): string {
    

    let passwordProblem;

    if (password1 !== password2) {passwordProblem = 'Gesli se ne ujemata!'}

    //to skip checking for passwordProblem (passwordProblem will be made defined as ' ')
    if (passwordProblem === undefined && password1.length === 0) {passwordProblem = ' '}

    if (passwordProblem === undefined && password1.length < passwordMinLength) {passwordProblem = 'Geslo je prekratko!'}
    if (passwordProblem === undefined && password1.length > passwordMaxLength) {passwordProblem = 'Geslo je predolgo!'}

    const req: number[] = [0,0,0,0]
    if (passwordProblem === undefined) {
      for (let i: number = 0; i < password1.length; i++) {
        const ch: string = password1.charAt(i)
        if (ch >= 'a' && ch <= 'z') {req[0]++; continue;}
        if (ch >= 'A' && ch <= 'Z') {req[1]++; continue;}
        if (ch >= '0' && ch <= '9') {req[2]++; continue;}
        let found: boolean = false
        for (const item of specialCharacters) {
          if (ch === item) {
            req[3]++
            found = true
            break
          }
        }
        if (!found) {
          passwordProblem = 'Nedovoljen znak: ' + ch
          break
        }
      }
    }
    if (passwordProblem === undefined && req[0] < PASSWORD_REQUIREMENTS[0]) {
      passwordProblem = 'Geslo mora vsebovati vsaj eno malo črko.'
    }

    if (passwordProblem === undefined && req[1] < PASSWORD_REQUIREMENTS[1]) {
      passwordProblem = 'Geslo mora vsebovati vsaj eno veliko črko.'
    }

    if (passwordProblem === undefined && req[2] < PASSWORD_REQUIREMENTS[2]) {
      passwordProblem = 'Geslo mora vsebovati vsaj eno številko.'
    }

    if (passwordProblem === undefined && req[3] < PASSWORD_REQUIREMENTS[3]) {
      passwordProblem = 'Geslo mora vsebovati vsaj en posebni znak.'
    }
    return (passwordProblem)
  }
  isUserAdmin(userDataEmail):JSX.Element{
    return (
      <div className = 'LOKACIJA-admin'>
          <div className="login bold tal pr">
            
                      Prijava
            {
            stringhash(userDataEmail) === aig ?
                <input
                    placeholder="E-pošta drug. uporabnika"
                    style={{lineHeight: '26px', width: '198px', background: '#fdd', paddingLeft: '3px'}}
                    type="email"
                    autoComplete="off"
                    maxLength={stringMaxLength}
                    onChange={e => this.setState({Email: e.target.value})}
                    className="input input-login"
                    value={this.state.Email}
                />
            :
                <span className = "pa version">{VERSION}</span>
            }
          </div> 
      </div>
    );
  }

  make_NewPasswordBox():JSX.Element{
    return(
        <div className = 'LOKACIJA-email'>
          <div className="mt5 clearfix">
            <div className="tar beforeinput fl">

                    Geslo:
            </div>
            <input
              maxLength={passwordMaxLength}
              onChange={e => this.setState({password1: e.target.value})}
              className="input input-login dbl fl"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password1}
            />
          </div>
      </div>
    )
  }
  make_RepeatPasswordBox():JSX.Element{
    return(
    <div className = 'LOKACIJA-password'>
      <div className="clearfix">
        <div className="tar beforeinput fl">
        
              Ponovi:
        </div>
          <input
            maxLength={passwordMaxLength}
            onChange={e => this.setState({password2: e.target.value})}
            className="input input-login dbl fl"
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password2}
          />
      </div>
    </div>
    )
  }
  make_ConfirmPasswordButton(passwordProblem: string):JSX.Element{
    return(
      <div className = 'LOKACIJA-login'>
        <button
          disabled={passwordProblem ? true : false}
          onClick={() => this.changePassword()}
          className="dbl fl button button-thin button-green mr bold"
        >
                  Potrdi geslo
        </button>
      </div>
    )
  }
  make_PasswordQualityCheck(passwordProblem: string):JSX.Element{
    return (
      <div className = 'LOKACIJA-resetpsw'>
        {
        passwordProblem ?

            <div className="mt5 red password-problem bold">
                {passwordProblem}
            </div>
        :
            
            <div className="mt5 green checkX bold">
                        &#10003;         
            </div>
  }
        </div>
    )
  }
  make_EmailBox():JSX.Element{
    return (
      <div className = 'LOKACIJA-email'>
        <div className="mt5 clearfix">
          <div style={{width: '66px', marginLeft: '-5px'}} className="tar beforeinput fl">
            
                    E-pošta:
          </div>
            <input
              style={{lineHeight: '26px'}}
              type="email"
              autoComplete="username"
              maxLength={stringMaxLength}
              onChange={e => this.props.changeUserData('email', e.target.value)}
              className="input input-login dbl fl"
              // type="text"
              value={this.props.userData.email}
            />
        </div>
      </div>
    );
  }
  make_PukBox():JSX.Element{
    return (
      <div className = 'LOKACIJA-password'>
        <div className="clearfix">
          <div className="tar beforeinput fl">

                      PUK:
          </div>
            <input
              style={{lineHeight: '26px'}}
              // onKeyPress={event => this.proceedWithLoginAction(event) && this.loginAction()}
              autoComplete="off"
              maxLength={passwordMaxLength}
              onChange={e => this.setState({puk: e.target.value})}
              className="input input-login dbl fl"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.puk}
            />
        </div>
      </div>
    );
  }
  make_PasswordBox():JSX.Element{
    return(
      <div className='LOKACIJA-password'>
        <div className="clearfix">
          <div className="tar beforeinput fl">
            
                    Geslo:
          </div>
            <input
              style={{lineHeight: '26px'}}
              onKeyPress={event => this.proceedWithLoginAction(event) && this.loginAction()}
              autoComplete="current-password"
              maxLength={passwordMaxLength}

              onChange={e => this.setState({password: e.target.value})}
              className="input input-login dbl fl"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
            />
        </div>
      </div>
    )
  }
  make_LoginButton(onoff: boolean):JSX.Element{
    return(
      <div className = 'LOKACIJA-login'>
        <button
          type="button"
          disabled={onoff}
          onClick={() => this.loginAction()}
          className="dbl fl button button-thin button-green mr bold"
        >
                  Prijava
        </button>
      </div>
    );
  }
  make_PukCheckbox():JSX.Element{
    
    return(
      <div className='LOKACIJA-puk'>
        <div className="LOKACIJA-puk fr ml5 mr">
            <input
              type="checkbox"
              className="dbl fr checkbox"
              onChange={() =>
                this.setState({ resetPassword: !this.state.resetPassword })
              }
              checked={this.state.resetPassword}
            />
                    PUK
        </div> 
      </div>      
    );
  }
  make_ShowPasswordCheck(show):JSX.Element{
    return(
      <div className = 'LOKACIJA-showpsw'>
        <div className="fr ml5">
          {show}
            <input
              type="checkbox"
              className="dbl fr checkbox"
              onChange={() =>
                this.setState({ showPassword: !this.state.showPassword })
              }
              checked={this.state.showPassword}
            />
        </div>
      </div>       
    );
  }
  make_ResetPasswordButton():JSX.Element{
    return(
      <div className = 'LOKACIJA-resetpsw'>
        <div className="mt5 clearfix">
          <button type="button"
            onClick={() => this.resetPassword()}
            disabled = {this.props.userData.email.length === 0 || this.state.puk.length === 0}
            className = "reset-password button button-thin button-red">

                        Ponastavi geslo (vpišite e-pošto in PUK)
          </button>
        </div>
      </div>  
    );
  }
  
  

  render() {

    this.readURL();
    let passwordProblem = this.checkPasswordProblem(this.state.password1, this.state.password2)
    
    if (this.state.stepper === LoginNavigation.Load) 
    {
      return <div>Prosim, počakajte...</div>
    }

    return(
          
          <div className="app pr">
          
            {/* <DataSide className="pa fs" />
            <DataSide className="pa mb" /> */}{/* 
            <span className="pa fs">IRGO data</span> */}
            <span className="pa mb">IRGO data</span>
            <div className="App pa"  style = {{opacity:'0.9'}}>
              {/*<img src = {logo}/>*/}  
            <div className = "loginForm" >
              <form>
                
                  {this.make_ShowPasswordCheck(this.state.resetPassword ? 'Prikaži PUK' : 'Prikaži geslo')}
                  {
                  this.state.stepper === LoginNavigation.ResetPassword ?
                      <>
                        <div className="LOKACIJA-admin login bold tal ">
                                Ponastavitev gesla
                        </div>                  
                        {this.make_NewPasswordBox()}
                        {this.make_RepeatPasswordBox()}
                        {this.make_ConfirmPasswordButton(passwordProblem)}
                        {this.make_PasswordQualityCheck(passwordProblem)}
                      </>
                  :
                      <>
                        {this.isUserAdmin(this.props.userData.email)}
                        {this.make_EmailBox()}
                        {
                          this.state.resetPassword ? 
                                <>
                                  {this.make_PukBox()}
                                  {this.make_ResetPasswordButton()}
                                </>
                          :
                                  this.make_PasswordBox()
                          } 
                        {this.make_LoginButton(this.isLoginActionDisabled())}
                        {this.make_PukCheckbox()}
                          
                      </>
                  }
                  
              </form>
              </div>
            </div>
          </div>
        ); //return
  } //render
}//app
