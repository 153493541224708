import {useState,} from 'react';
import {ActionLogInterface, Icons, Item, LogMessageType} from '../functions_interfaces/interfaces';
import {getCurrentTime} from '../functions_interfaces/functions';
import uuid from 'react-uuid';
import { VscDebugAlt } from "react-icons/vsc";

function ActionLog ({ActionLogReferences}: ActionLogInterface) {
/* ActionLog is component used to record selected actions. 
    Actions are logged in list form. 
    When Item in Component is clicked, alert of full message is called (if passed) */

    let [logStringArray, setLogStringArray] = useState<any[]>([]);

    ActionLogReferences.updateLogString = updateLogString;
//---------------------------------------------------------------------------------------------------
function updateLogString(newLog: string, success?: number, fullErrorMessage?: string) {
    /* Function takes in parameters:
        newLog => short string displayed in component
        success => state of logged action (error/success/pending)
        fullErrorMessage => Full description of error passed 
        
    and creates a Log object. The object is then added to logStringArray to be displayed in list form 
    */
    let newItem = new Item();
    newItem.message = newLog ? newLog : 'empty';
    newItem.fullErrorMessage = fullErrorMessage ? fullErrorMessage : 'no full message';
    newItem.time = getCurrentTime();
  
    if (success === 0) {
      newItem.color = 'red';
      newItem.icon = Icons.warningLight;
      alertMessage(newItem.fullErrorMessage);
    } else if (success === 1) {
      newItem.color = 'green';
      newItem.icon = Icons.check;
    } else if (success === 2) {
      newItem.color = 'blue';
      newItem.icon = Icons.loading;
    } else {
      newItem.color = 'grey';
      newItem.icon = Icons.message;
    }
    

    setLogStringArray(prev => [...prev, newItem]);
  };
//---------------------------------------------------------------------------------------------------
function alertMessage(itemErrorMessage) {
    console.log(itemErrorMessage);
    alert(itemErrorMessage);
};
//---------------------------------------------------------------------------------------------------
function copyLogToClip(){
    /* Function is called to copy all past logs to clipboard */
    
    const fullString = logStringArray
    .map(
      (item) =>
        `${item.icon.repeat(100)}\n\n[LOG]: ${item.message}\n\n[FULL MESSAGE]:\n\t${item.fullErrorMessage}\n\n[TIME]: ${item.time}\n\n`
    )
    .reverse()
    .join('');

  const copyToClipText = fullString;

  updateLogString('Log Copied to Clip', LogMessageType.Success);

  return navigator.clipboard.writeText(copyToClipText);
};
//---------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------

    return (
        <div className="ActionLogWrapper">
          <div className="head">
              <div className="title">
                <VscDebugAlt/>
              </div>
              <div className="title">

                 Action Logger
              </div>
              
              {logStringArray.length > 0 ? (
                  <div onClick={() => copyLogToClip()} className="CopyToClip">
                    Copy {Icons.copyToClip}
                  </div>
              ) : null}
          </div>
          
          {logStringArray.length > 0 ? (
              <ul>
              {logStringArray.map((item) => {
                  return (
                  <li key={uuid()} onClick={() => alertMessage(item.fullErrorMessage)} style={{ color: item.color }}>
                    <div>
                      <span className="icon">{item.icon}</span>
                      <span className="message">{item.message}</span>
                    </div>
                    <div className="time">{item.time}</div>
                  </li>
                  );
              }).reverse()}
              </ul>
          ) : null}
        </div>
    );
  
};

export default ActionLog;