import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/styles.css';
import { mod_getUsersDataRoute, mod_getPagesDataRoute, mod_updateUserAccessRoute, mod_getUserNodeTreeUUIDsFlatList, mod_getUserAttributeRestrictrionsRoute, mod_getModuleNodetreeRoute } from '../../utils/routes';
import { Icons, LogMessageType, Sensor, UserControlPanelInterface, mod_User, } from '../../functions_interfaces/interfaces';

import { useTranslation } from 'react-i18next';
import { getModuleNodetreeMenu } from '../monitoring/_monitoring';

interface mod_Page {
  page_uuid: string,
  page_name: string,
  page_number: number,
  has_a_menu: boolean
};
export interface userAttributeAccess {
  forbiddenNodes: string[],
  allowedNodes: string[]
}

const UserControlPanel = ({ActionLogReferences}: UserControlPanelInterface) => {
  const initViewedUser: mod_User = {
      useruuid:'',
      firstname: '',
      lastname: '',
      is_internal: false,
      usertype:2,
      allowed_access_uuid:'',
      allowed_access: { read: true, edit: false, delete: true, download: false, upload: true }
    };
  
  const mod_pageData_init: mod_Page = {
    page_uuid: '123',
    page_name: 'User Control Panel',
    page_number: 0,
    has_a_menu: false
  }

  const initAttributeAccess: userAttributeAccess = {
    allowedNodes: [],
    forbiddenNodes: []
  }
  const [usersData, setUsersData] = useState<mod_User[]>([]);
  const [mod_pageData, setModPageData] = useState<mod_Page[]>([]);
  const [currentlyViewedPage, setCurrentlyViewedPage] = useState<mod_Page>(mod_pageData_init);
  const [currentlyViewedUser, setCurrentlyViewedUser] = useState<mod_User>(initViewedUser);
  const [usersNodeTreeUUIDsFlatList,setUsersNodeTreeUUIDsFlatList] = useState<string[]>([]);
  const [usersAttributeAccessOfPage, setUsersAttributeAccessOfPage]= useState<userAttributeAccess>(initAttributeAccess);
  const [showNodetreeMenu, setShowNodetreeMenu] = useState<boolean>(false);

  const { t } = useTranslation();

  const [nodeTreeData, setNodeTreeData] = useState<Sensor[]>();

  useEffect(() => {
    setUsersData([]);
  }, []);

  useEffect(() => {
    setCurrentlyViewedUser(initViewedUser);
    setShowNodetreeMenu(false);
  }, [currentlyViewedPage]);

  useEffect(() => {
    fetchPageData();
  }, []);

  useEffect(() => {

    if (currentlyViewedPage.has_a_menu === true) {
      getMonitoringNodeTreeInsideComponent();
    }
    else {
      setNodeTreeData([]);
    }
  }, [currentlyViewedPage]);

  async function getMonitoringNodeTreeInsideComponent() {
    const monitoringNodeTreeData: Sensor[] = await getModuleNodetreeMenu(currentlyViewedPage.page_number, mod_getModuleNodetreeRoute) as Sensor[]
    setNodeTreeData(monitoringNodeTreeData)
  }

  const toggleUsersNodeTreeUUIDsFlatList = async(atributeID: string) => { // togggle today

    const isInCurrentUsersUUIDs = usersAttributeAccessOfPage.forbiddenNodes?.includes(atributeID);
    let usersNodeTreeUUIDsFlatListCopy: string[] = [];
    
    if (isInCurrentUsersUUIDs) {

      usersNodeTreeUUIDsFlatListCopy = usersAttributeAccessOfPage.forbiddenNodes.filter(uuid => uuid !== atributeID);
    } else {
      usersNodeTreeUUIDsFlatListCopy =  [...usersAttributeAccessOfPage.forbiddenNodes, atributeID];
    }

    setUsersAttributeAccessOfPage({allowedNodes: [], forbiddenNodes: usersNodeTreeUUIDsFlatListCopy});

    await updateUsersAttributeAccessOfPage(usersNodeTreeUUIDsFlatListCopy);
  };

  async function updateUsersAttributeAccessOfPage(usersNodeTreeUUIDsFlatListCopy): Promise<void> {
    try {
      let response = await axios.put(mod_getUserAttributeRestrictrionsRoute, {
        usersNodeTreeUUIDsFlatList: usersNodeTreeUUIDsFlatListCopy,
        pageNumber:currentlyViewedPage.page_number,
        userUUID: currentlyViewedUser.useruuid
      });

      if (response && response.data.resolved) {
        await fetchUsersNodeTreeUUIDsFlatList(currentlyViewedUser);
      }
    }
    catch(error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t Frontend Error")
      );
    }
  }

  async function fetchPageData () {
    try {
        let response = await axios.get(mod_getPagesDataRoute);

        if (response && response.data.resolved) {
          setModPageData(response.data.data);
          setCurrentlyViewedPage(response.data.data[0]);
          console.log(response.data.data[0]);
          await fetchPageUsers(response.data.data[0].page_uuid);
          
          ActionLogReferences.updateLogString(
            'Data Transfer Success',
            LogMessageType.Success,
            JSON.stringify(response.data.data, null, "\t")
          );
        }
        else {
          ActionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            JSON.stringify(response.data.message, null, "\t")
          );
        }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(e, null, "\t Frontend Error")
      );
    }
  }
  interface generatePermissionQueryUserData {
    userUUID: string,
    pageNumber: number
  }
  async function fetchUsersNodeTreeUUIDsFlatList (userData: mod_User): Promise<void> {

    try {
      const params: generatePermissionQueryUserData = {
        userUUID: userData.useruuid,
        pageNumber: currentlyViewedPage.page_number
      }

      let response = await axios.get(mod_getUserNodeTreeUUIDsFlatList, {
        params: params
      })

      if (response && response.data.resolved) {
        setUsersNodeTreeUUIDsFlatList(response.data.data);
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t Frontend Error")
      );
    }
  }

  async function fetchUsersAttributeAccessForSelectedPage (userData: mod_User): Promise<void> {
    try {
      const params: generatePermissionQueryUserData = {
        userUUID: userData.useruuid,
        pageNumber: currentlyViewedPage.page_number
      }
      let response = await axios.get(mod_getUserAttributeRestrictrionsRoute, {
        params: params
      });

      if (response && response.data.resolved) {
        setUsersAttributeAccessOfPage(response.data.data);
        console.log(response.data.data);
      }
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t Frontend Error")
      );
    }
  }

  async function setCurrentlyViewedUserData (userData: mod_User): Promise<void> {

    if (currentlyViewedPage.has_a_menu === false)
      return;
    try {
      setCurrentlyViewedUser(userData);
      await fetchUsersNodeTreeUUIDsFlatList(userData);
      setShowNodetreeMenu(true);

      await fetchUsersAttributeAccessForSelectedPage(userData);
    }
    catch (error) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(error, null, "\t Frontend Error")
      );
    }
  }

  async function fetchPageUsers (page_uuid) {
    try {
      let response = await axios.get(mod_getUsersDataRoute, {params: {page_uuid: page_uuid}});

      if (response && response.data.resolved) {

        setUsersData(response.data.data);

        ActionLogReferences.updateLogString(
          'Data Transfer Success',
          LogMessageType.Success,
          JSON.stringify(response.data.data, null, "\t")
          );
      }
      else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(e, null, "\t Frontend Error")
      );
    }
  }


  const handleAccessChange = async(user: mod_User, accessType: keyof mod_User['allowed_access']) => {
    
    const updatedAccess = !user.allowed_access[accessType];
    user.allowed_access[accessType] = !user.allowed_access[accessType];

    try {
      let response = await axios.put(mod_updateUserAccessRoute, {
        user_uuid: user.useruuid, 
        page_uuid: currentlyViewedPage.page_uuid, 
        allowed_access_uuid: user.allowed_access_uuid,
        accessType: [accessType],
        value: updatedAccess,
      })
    
      if (response && response.data.resolved) {

        updateUserData(response.data.data);

        ActionLogReferences.updateLogString(
          'Data Transfer Success',
          LogMessageType.Success,
          JSON.stringify(response.data.data, null, "\t")
        );
      }
      else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          JSON.stringify(response.data.message, null, "\t")
        );
      }
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        JSON.stringify(e, null, "\t Frontend Error")
      );
    }
  };

  function updateUserData(newUserData) {
    setUsersData(prevUsersData => {
      return prevUsersData.map(user => {
        if (user.useruuid === newUserData.useruuid) {
          return { ...user, ...newUserData };
        }
        return user;
      });
    });
  }
  
  const handleSelectChange = (event) => {
    let pageIndex = event.target.value
    setCurrentlyViewedPage(mod_pageData[pageIndex]);
    fetchPageUsers(mod_pageData[pageIndex].page_uuid);
  };


  return (
    <div className="userControlPanel">
      <header className="globals--module-navbar">
        
        <div className="globals--module-navbar-title">
          {t(`index.head.dropdown.access_table`)}
        </div>

        <div className = 'helpers--section-container-orientation-flex-row'>
          {t(`index.head.dropdown.${currentlyViewedPage.page_name}`)}
        </div>

        <div className = 'helpers--section-container-orientation-flex-row'>
          <select value={0} 
                  onChange={handleSelectChange}>

            <option value={0}>Select a page</option>

            {mod_pageData.map((page, index) => (
              <option key={index} value={index}>
                {t(`index.head.dropdown.${page.page_name}`)}
              </option>
            ))}

          </select>
        </div>

      </header>

      <main className = 'content'>
        <div className="tableContainer">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Is Internal</th>
                <th>Allowed Access</th>
              </tr>
            </thead>
            <tbody>
              {usersData
                    .sort((a, b) => a.lastname.localeCompare(b.lastname))
                    .map((user, index) => (
                <tr key={index} onClick={() => setCurrentlyViewedUserData(user)}>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.is_internal ? 'Yes' : 'No'}</td>
                  <td>
                    <div className = 'helpers--section-container-orientation-flex-row'>
                    {Object.entries(user.allowed_access).map(([accessType, allowed]) => (
                      <button
                        key={accessType}
                        className={`${allowed ? 'globals--green-button' : 'globals--red-button'}`}
                        onClick={() => handleAccessChange(user, accessType as keyof mod_User['allowed_access'])}
                        disabled={user.usertype === 0}
                      >
                        {accessType}
                      </button>
                    ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
            
        </div>
        <div className = 'node-tree-section'>
          <div className="pageTitle">
            {currentlyViewedUser?.firstname} {currentlyViewedUser?.lastname}
          </div>
        {nodeTreeData && showNodetreeMenu &&
              <RenderProjects levelOne={nodeTreeData} 
                              usersNodetreeMenuUUIDS={usersNodeTreeUUIDsFlatList}
                              toggleUsersNodeTreeUUIDsFlatList = {toggleUsersNodeTreeUUIDsFlatList}/>
            }{/* 
        <GroupsTable ActionLogReferences={ActionLogReferences}/> */}
        </div>
      </main>
      
    </div>
  );
};
function RenderSensors({
  levelTwo,
  usersNodetreeMenuUUIDS,
  toggleUsersNodeTreeUUIDsFlatList
}) {
  return (
    <>

      {levelTwo.children.map(levelTwoItem => (
        <SensorItem 
          key={levelTwoItem.uuid}
          data={levelTwoItem}
          workplace={levelTwo}
          usersNodetreeMenuUUIDS = {usersNodetreeMenuUUIDS}
          toggleUsersNodeTreeUUIDsFlatList = {toggleUsersNodeTreeUUIDsFlatList}
        >
          {/* Optionally render sensor details here */}
        </SensorItem>
      ))}
    </>
  );
}
//------------------------------------------------------------------------------------------------------
function RenderWorkplaces({
  levelOne,
  usersNodetreeMenuUUIDS,
  toggleUsersNodeTreeUUIDsFlatList,
}) {



  return (
    <>
      {levelOne.children.map(levelOneItem => (
        <TreeItem key={levelOneItem.uuid} 
                  data = {levelOneItem} 
                  usersNodetreeMenuUUIDS = {usersNodetreeMenuUUIDS}
                  toggleUsersNodeTreeUUIDsFlatList = {toggleUsersNodeTreeUUIDsFlatList}>
          <RenderSensors 
            levelTwo={levelOneItem}
            usersNodetreeMenuUUIDS={usersNodetreeMenuUUIDS}
            toggleUsersNodeTreeUUIDsFlatList = {toggleUsersNodeTreeUUIDsFlatList}
          />
        </TreeItem>
      ))}
    </>
  );
}
//------------------------------------------------------------------------------------------------------
function RenderProjects({
  levelOne,
  usersNodetreeMenuUUIDS,
  toggleUsersNodeTreeUUIDsFlatList
}) {


  return (
    <>
      {levelOne.map(levelOneItem => (
        <TreeItem key={levelOneItem.uuid} 
                  data = {levelOneItem} 
                  usersNodetreeMenuUUIDS = {usersNodetreeMenuUUIDS}
                  toggleUsersNodeTreeUUIDsFlatList = {toggleUsersNodeTreeUUIDsFlatList}>
          <RenderWorkplaces 
            levelOne={levelOneItem}
            usersNodetreeMenuUUIDS={usersNodetreeMenuUUIDS}
            toggleUsersNodeTreeUUIDsFlatList = {toggleUsersNodeTreeUUIDsFlatList}
          />
        </TreeItem>
      ))}
    </>
  );
};
//------------------------------------------------------------------------------------------------------
const TreeItem = React.memo(({ data, children, usersNodetreeMenuUUIDS, toggleUsersNodeTreeUUIDsFlatList }: any) => {


  return (
      <div className="TreeItem">
          <div className="TreeItem-label">
              {Icons.arrowDown}
              {data.name}
              <button className = {usersNodetreeMenuUUIDS.includes(data.uuid)?'globals--green-button':'globals--red-button'}
                      onClick={() => toggleUsersNodeTreeUUIDsFlatList(data.uuid)}>
                Allow
              </button>
          </div>
          <div className="TreeItem-children">{children}</div>
      </div>
  );
});
//------------------------------------------------------------------------------------------------------
const SensorItem = React.memo(({ data, usersNodetreeMenuUUIDS, toggleUsersNodeTreeUUIDsFlatList }: any) => {
  return (
      <div className="TreeItem">
        
          <div  className="TreeItem-label">
                {data.name}
                <button className = {usersNodetreeMenuUUIDS.includes(data.uuid)?'globals--green-button':'globals--red-button'}
                        onClick={() => toggleUsersNodeTreeUUIDsFlatList(data.uuid)}>
                  Allow
                </button>
          </div>
          
      </div>
  );
});
export default UserControlPanel;
