import { useState, useEffect } from 'react';
import axios from 'axios';
import { updateUserDataRoute, jantarLoginRoute } from '../../utils/routes';
import { LogMessageType, ProfileInterface } from '../../functions_interfaces/interfaces';
import Cookies from 'js-cookie';
import { BiSolidDownload } from 'react-icons/bi';
import { Notifications } from '../..';


export default function Profile({ userData, loadUserDataFromToken, ActionLogReferences }: ProfileInterface) {

  const [toggleEditForm, setToggleEditForm] = useState(false);
  const [userName, setUserName] = useState(userData.firstname);
  const [userSurname, setUserSurname] = useState(userData.lastname);
  const [userEmail, setUserEmail] = useState(userData.email);
  const [userTelephone, setUserTelephone] = useState(userData.telephone);
  const [userLanguage, setUserLanguage] = useState(userData.language);
  const [formattedLanguage, setFormattedLanguage] = useState('');
  const [JTConverterToggle, setJTConverterToggle] = useState(false);

  const handleInputChange = (e, setValue) => {
    setValue(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    formatLanguage()
  }, [])

  function formatLanguage() {
    if (userLanguage === 'sl') {
      setFormattedLanguage('Slovenščina')
    }
    if (userLanguage === 'en') {
      setFormattedLanguage('English')
    }
    if (userLanguage === 'ch') {
      setFormattedLanguage('中文')
    }
    if (userLanguage === 'bs') {
      setFormattedLanguage('Bosanski')
    }
  }

  async function handleSubmit (e) {
    e.preventDefault()
    const userData = {
        firstName: userName,
        lastName: userSurname,
        email: userEmail,
        telephone: userTelephone,
        language: userLanguage
    }
    let response = await axios.put(updateUserDataRoute, userData);
    if (response) {
        console.log(response)
        try {
          formatLanguage();
          await Cookies.remove('token', { path: '/', secure: true })
          await Cookies.set('token', response.data.data.token, { expires: 7, path: '/', secure: false });
        } catch (error) {
          ActionLogReferences.updateLogString(
            'Error Updating data!',
            LogMessageType.Error,
            JSON.stringify(error, null, "\t")
          );
        }
    }
    loadUserDataFromToken();
    setToggleEditForm(false);
  };

  return (
    <>
      <header className='globals--module-navbar'>
        <div className = 'globals--module-navbar-title'>
          Profil  
        </div>
        
        <div className = 'helpers--section-container-orientation-flex-row'>
          <button className = 'globals--regular-button' onClick={() => setJTConverterToggle(!JTConverterToggle)}>
            Jantar Timetable 

              <img src="logo_jantar.png" alt="Jantar Logo" className = 'logo' width={'16px'}/>

          </button>
        </div>

      </header>
      <main className = 'subPageContent'>
        <div className = 'resizable-popup' style = {{display: JTConverterToggle?'':'none', width:'400px', minWidth:'400px', height:'500px', minHeight:'500px'}}>
            <JantarLogin setJTConverterToggle = {setJTConverterToggle}
                          ActionLogReferences={ActionLogReferences}/>
            <button  className = 'close-button' onClick={() => setJTConverterToggle(!JTConverterToggle)}/>
        </div>
        <div className = 'profileContent'>
          <div className="ProfileWrapper">
          
          {toggleEditForm ? (
            <form onSubmit={(e) => handleSubmit(e)} className = 'form'>
              
                <label>FirstName:</label>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => handleInputChange(e, setUserName)}
                  />

                <label>LastName:</label>
                  <input
                    type="text"
                    value={userSurname}
                    onChange={(e) => handleInputChange(e, setUserSurname)}
                  />

                <label>E-mail:</label>
                <div>
                  {userEmail}
                </div>

                <label>Telephone:</label>
                  <input
                    type="text"
                    value={userTelephone}
                    onChange={(e) => handleInputChange(e, setUserTelephone)}
                  />

                <label>Language:</label>
                  <select
                    value={userLanguage}
                    onChange={(e) => handleInputChange(e, setUserLanguage)}>
                    <option value = 'sl'>Slovenscina</option>
                    <option value = 'en'>English</option>
                    <option value = 'bs'>Bosanski</option>
                    <option value = 'ch'>中文</option>
                  </select>
              <button type = "submit" className = 'globals--regular-button'>
                Submit
              </button>
            </form>
          ) : (
            <div>
              <div className="section">{userName}</div>
              <div className="section">{userSurname}</div>
              <div className="section">{userEmail}</div>
              <div className="section">{userTelephone}</div>
              <div className="section">{formattedLanguage}</div>
              <button onClick={() => setToggleEditForm(true)}>Edit</button>
            </div>
            
          )}</div>

{/*           <div className = 'resizable-popup' style = {{display: JTConverterToggle?'':'none'}}>
            <div className = 'tutorial-container'>
              <h2 className="tutorial-heading">Jantar Timetable Converter</h2>
                  <ul className="tutorial-description">
                      <li>Step 1: <a href='https://jantar.irgo.si/'>Jantar</a></li>
                      <li>Step 2: Izvoz {'=>'} Delovni Cas.</li>
                      <li>Step 3: Izberi ".csv" Format datoteke.</li>
                      <li>Step 4: Uporabi.</li>
                      <li>Step 5: Naloži CSV v Converter. </li>
                  </ul>
                  <div className = 'subPageHeader'>
                    <CsvToExcelTimetable/>
                  </div>
              <div className="image-container">
                  <img src='Csv_convert_tutorial.png' alt="CSV Convert Tutorial" className="tutorial-image"/>
              </div>
            </div>
            <div className="controls" onClick={() => setJTConverterToggle(!JTConverterToggle)}>
              <button>close</button>
            </div>
          </div> */}
          <div style = {{width:'100%', height:'100vh', overflow:'scroll'}}>
          <div style = {{position:'sticky',padding: '0 1rem', top: 0,color: 'white', backgroundColor:'rgba(0, 52, 0, 0.76)', margin:0, textAlign:'center', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <h1>Notifications</h1>
              <div style = {{alignContent:'center'}}>
                <span style = {{alignContent:'center'}}>Označi vse kot prebrano:</span>
                <input type = 'checkbox'/>
              </div>
            </div>
            <Notifications/>
          </div>
        </div>
        
    </main>
    </>
  );
}


function JantarLogin({setJTConverterToggle, ActionLogReferences}) {
  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    return `${year}-${month}`;
  };
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [date, setDate] = useState(getCurrentMonth());
  const [errorWarning, setErrorWarning] = useState<string | null>(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(jantarLoginRoute, {
        username: username,
        password: password,
        date: date
      }, {
        responseType: 'blob' // Set responseType to 'blob' for file downloads
      });
  
      if (response && response.data) {
        console.log(response.data);

        if (response.headers['content-type'] !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

          const errorResponseBlob = response.data;
          const errorResponseText = await errorResponseBlob.text(); // Convert blob to text
          try {
            const errorResponseJSON = JSON.parse(errorResponseText); 
            if (errorResponseJSON.resolved === false) {
              if (errorResponseJSON.errorCode === -2) {
                setErrorWarning('Invalid Username Or Password!');
              } else if (errorResponseJSON.errorCode === -1) {
                setErrorWarning('Jantar Server Error!');
              }
            }
          } catch (error) {
            ActionLogReferences.updateLogString(
              'Error fetching data!',
              LogMessageType.Error,
              JSON.stringify(response.data.message, null, "\t")
            );
          }
        }
        else {
          const contentDisposition = response.headers['content-disposition'];
          let filename = 'downloaded_file.xlsx'; // Default filename
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
          }
    
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
    
          // Clean up
          link.remove();
          window.URL.revokeObjectURL(url);
    
          setJTConverterToggle(false);
          setErrorWarning(null);
        }
      }
      else if (response.data.resolved === false) {
        if (response.data.errorCode === -2){
          setErrorWarning('Invalid Username Or Password!')
        }
        else if (response.data.errorCode === -1) {
          setErrorWarning('Jantar Server Error!');
        }
      }
    } catch (e) {
      console.error('Error during file download:', e);
    }
  };
  

  return (
    <div  className = 'insertUser'>
      <form onSubmit={handleSubmit} className = 'form'>
        <div className='logo-class'>
          <img src="logo_jantar.png" alt="Jantar Logo"/>
        </div>
        <div className = 'form-header'>
            Jantar Excel
        </div>
        <div className = 'subheader-class'>
          Select Timetable Month And Insert Jantar Credentials
        </div>
        <div className = 'subheader-class' style = {{color: 'darkred', fontWeight:'bold', display:errorWarning?'':'none'}}>
          {errorWarning}
        </div>
        <div className='form-row'>
          <label htmlFor='username'>Date:</label>
          <input 
            type="month"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className = 'searchBar'
          />
        </div>
        <div className='form-row'>
          <label htmlFor='username'>Username:</label>
          <input
            id='username'
            type='text'
            className = 'searchBar'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className='form-row'>
          <label htmlFor='password'>Password:</label>
          <input
            id='password'
            type='password'
            className = 'searchBar'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type='submit' className = 'globals--red-button'>
          Download .xslx 
          <span style = {{marginLeft:'10px'}}>
            <BiSolidDownload/>
          </span>
        </button>
      </form>
    </div>
  );
}
