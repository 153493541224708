import React, { useEffect, useRef, useState } from 'react';
import * as OBC from "@thatopen/components";
import * as OBCF from '@thatopen/components-front';
import { modelsRoute } from '../../utils/routes';
import * as BUI from "@thatopen/ui";
import * as BUIC from '@thatopen/ui-obc';
interface IfcViewerProps {
  modelRoute: string
}

const IfcViewer: React.FC<IfcViewerProps> = ({modelRoute}) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {

      let loader;
      let world;
      let stats;
      const setupScene = async () => {
        if (!containerRef.current) return;

        BUI.Manager.init();
        BUIC.Manager.init();

        const components = new OBC.Components();
        const worlds = components.get(OBC.Worlds);
  
        world = worlds.create<
          OBC.SimpleScene, 
          OBC.OrthoPerspectiveCamera, 
          OBCF.RendererWith2D
        >();
        
        world.scene = new OBC.SimpleScene(components);
        /* world.renderer = new OBC.SimpleRenderer(components, containerRef.current); */
        world.renderer = new OBCF.RendererWith2D(components, containerRef.current);
        world.camera = new OBC.OrthoPerspectiveCamera(components);
  

        components.init();


        world.scene.setup();
        world.camera.controls.setLookAt(12, 6, 8, 0, 0, -10);
        containerRef.current.appendChild(world.renderer.three2D.domElement);


        const grids = components.get(OBC.Grids);
        grids.create(world);
  
        loader = components.get(OBCF.IfcStreamer);
        loader.world = world;
        loader.url = modelsRoute + modelRoute + '/';

        const loadModel = async (geometryURL, propertiesURL?) => {
          const rawGeometryData = await fetch(geometryURL);
          const geometryData = await rawGeometryData.json();
          let propertiesData;
          if (propertiesURL) {
            const rawPropertiesData = await fetch(propertiesURL);
            propertiesData = await rawPropertiesData.json();
          }
          await loader.load(geometryData, true, propertiesData);
        };
  
        await loadModel(
          `${modelsRoute}${modelRoute}/${modelRoute}-processed.json`, 
          `${modelsRoute}${modelRoute}/${modelRoute}-processed-properties.json`
        );
  
        world.camera.controls.addEventListener("sleep", () => {
          loader.culler.needsUpdate = true;
        });
        
        loader.culler.threshold = 2;
        loader.culler.maxHiddenTime = Number.MAX_SAFE_INTEGER;
        loader.culler.maxLostTime = Number.MAX_SAFE_INTEGER;
        loader.useCache = true;
        
        setIsLoading(false);
        
      };
  
      setupScene();
      return () => {
        if (loader) {
          loader.clearCache();
          loader.dispose();
        }
        if (world) {
          world.renderer.dispose();
          world.scene.dispose();
          world.camera.controls.dispose();
        }
        if (stats) {
          document.body.removeChild(stats.dom);
        }
        setIsLoading(false);
      };
  }, [modelRoute, modelsRoute]);

  return (

    <div className='IFCviewer-container'>
      {isLoading && (
        <div>
          LOADING MODEL...
        </div>
      )}

      
      <div ref={containerRef} id = "container" className='IFCviewer-container-3D-viewer' />
      
    </div>
  )
}
export default IfcViewer;

