import {useState, useRef, memo, useEffect} from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import {DescriptionBoxInterface, NodeData, descriptionBoxPart, typeOfInteraction, Icons, LogMessageType, ActionLogReferences} from '../../../functions_interfaces/interfaces';
import {extractFileName} from '../../../functions_interfaces/functions';
import {
  deleteRoute,
  addPathRoute,
  getSingleNodeDataRoute,
  updateRoute,
  getHistoryLogsOfNodeRoute
} from '../../../utils/routes';
import fetchData from '../../../functions_interfaces/functions';
import { Tooltip } from 'react-tooltip';
import { HistoryLogItem } from './_Overview';




function DescriptionBox ({DescriptionBoxReferences, tableName, ActionLogReferences}: DescriptionBoxInterface)  {
/* function is used to handle item data:
    1.) creating new item => insert Form,
    2.) updating exsisting item => edit Form && quickUpdates (bluePen)
    3.) deleting item  
    
    PARAMETERS:
      - data => main data input 
      - insertForm => is react useState hook constant, it gets passed from another component
                      and is used as a switch between regular descriptionBox and new item form
      - setInsertForm => is react useState hook function to set the state of insertForm
      - tableName => string name of a psql table */
      /* let getCurrentDate = () => {

        let date = new Date();
        let splicedDate =  date.getFullYear() + '-' + date.getDate() + '-' + (date.getMonth() + 1);
  
        return splicedDate;
    } */
//-------------------------------------data states-------------------------------------------
    const data = useRef(new NodeData());

    const [visibleName, setVisibleName] = useState(data.current.name);
    const [visibleFileNameSubmitted, setVisibleFileNameSubmitted] = useState(data.current.file_name_submitted);
    const [visibleStatus, setVisibleStatus] = useState(typeof(data.current.status) == 'number'? data.current.status : null);
    const [visibleTypeOfItem, setVisibleTypeOfItem] = useState(data.current.type_of_item);
    const [visibleSection, setVisibleSection] = useState(data.current.section);
    const [visibleDescription, setVisibleDescription] = useState(data.current.description);
    const [visiblePriorityNumber, setVisiblePriorityNumber] = useState(data.current.priority_number);
    const [visibleDateCreated, setVisibleDateCreated] = useState(data.current.date_created);
    const [visibleDateFinished, setVisibleDateFinished] = useState(data.current.date_finished);
    const [visibleDateSubmitted, setVisibleDateSubmitted] = useState(data.current.date_submitted);
    const [visibleDeadline, setVisibleDeadline] = useState(data.current.deadline);
    const [visiblePathInfo, setVisiblePathInfo] = useState(data.current.path_info);
    const [visibleDivider, setVisibleDivider] = useState(data.current.divider);
    const [visibleItemSubitems, setVisibleItemSubItems] = useState(data.current.sub_items);
    const [visibleNodeType, setVisibleNodeType] = useState(data.current.node_type);
    const [visibleStaff, setVisibleStaff] = useState<any>(data.current.staff);
    const [visibleScale, setVisibleScale] = useState<any>(data.current.scale);
    const [visibleClassId, setVisibleClassId] = useState<any>(data.current.class_id);
    const [visibleTags, setVisibleTags] = useState<any>(data.current.tags);


    let [visibleStatusChangeDescription, setVisibleStatusChangeDescription] = useState('');
    let [toggleStatus, setToggleStatus] = useState(data.current.status===null);

    const [Data, setData] = useState(new NodeData());
    const [toggleChat, setToggleChat] = useState<boolean>(false);

    //const [filteredStaff, setFilteredStaff] = useState<any>(descriptionBoxReferences.usersData?.filter(el => !data.current.staff?.includes(el)))
    let filteredStaff = useRef<any>([]);
//--------------------------------------------------------------------------------
    const [isDescriptionBoxShown, setIsDescriptionBoxShown] = useState(false);
    const [isInsertFormActive, setIsInsertFormActive] = useState(false); // sets descriptionBox component to insert new item (or update existing item) form
    const [typeOfInsertForm, setTypeOfInsertForm] = useState(typeOfInteraction.updateExistingItem); // false is used when insert form is used to UPDATE EXISTING ITEM
                                                                                                    // true is used when insert form is used to INSERT NEW ITEM
      DescriptionBoxReferences.dBoxData = data;
      DescriptionBoxReferences.setVisibleData = setVisibleData;
      DescriptionBoxReferences.setShowDescriptionBox = setIsDescriptionBoxShown

      DescriptionBoxReferences.setDboxToInsertForm = setIsInsertFormActive;
      DescriptionBoxReferences.DboxInsertFormState = isInsertFormActive;
      DescriptionBoxReferences.setTypeOfInsertForm = setTypeOfInsertForm;
    
//-------------------------------------inherited data needed to make new item-------------------------------------------
    
    const [TableName] = useState(tableName);

//-------------------------------------Misc-------------------------------------------

    const [myStatusColor, setMyStatusColor] = useState('white'); //status bar color depending on status value

//--------------------------------------------------------------------------------

const [selectedNodeHistoryLog, setSelectedNodeHistoryLog] = useState<any>([]);

    const handleChatClick = async() => {
      setToggleChat(!toggleChat);
    }
    async function fetchHistoryLogsOfSelectedNode(node_id, nodeName) {
      
      if (node_id === 'temporary_node') {
        return;
      }
      try {
        let response = await axios.post(getHistoryLogsOfNodeRoute, {node_id})
  
        if (response && response.data.resolved) {
  
          let sortedListByDate = response.data.data.sort((a, b) => new Date(b.change_time).getTime() - new Date(a.change_time).getTime());
          setSelectedNodeHistoryLog(sortedListByDate);
          let newData = await fetchData(getSingleNodeDataRoute, node_id, 'ProjectTable0');
          console.log(newData);
          ActionLogReferences.updateLogString(
            'Data transfer success',
            LogMessageType.Success,
            response.data.message
          );
        } else {
          ActionLogReferences.updateLogString(
            'Error fetching data!',
            LogMessageType.Error,
            response.data.message
          );
        };
      }
      catch (e) {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          e
        );
      }
    }
    function setVisibleData () {
    /* function is used to initialize visible data of item based on Master data structure*/
      setVisibleName(DescriptionBoxReferences.dBoxData.current.name);
      setVisibleFileNameSubmitted(data.current.file_name_submitted);

      filteredStaff.current = [...DescriptionBoxReferences.usersData];

      if (data.current.staff) {
        for (let person = 0; person < data.current.staff.length; person ++) {
          for (let Person = 0; Person < DescriptionBoxReferences.usersData.length; Person ++) {
            if (DescriptionBoxReferences.usersData[Person].useruuid === data.current.staff[person].useruuid ){
              filteredStaff.current.splice(filteredStaff.current.indexOf(DescriptionBoxReferences.usersData[Person]),1);
            }
          }
        }
      }
    
      console.log(filteredStaff.current);
        
      setData(data.current)

      setVisibleItemSubItems(data.current.sub_items);
      setVisibleStatus(typeof(data.current.status) == 'number'? data.current.status : null);
      setStatusColor(Number(data.current.status));
      setVisibleTypeOfItem(data.current.type_of_item);
      setVisibleDivider(data.current.divider);
      setVisibleSection(data.current.section);
      setVisibleDescription(data.current.description);
      setVisiblePriorityNumber(data.current.priority_number);
      setVisibleDateCreated(data.current.date_created);
      setVisibleDateFinished(data.current.date_finished);
      setVisibleDateSubmitted(data.current.date_submitted);
      setVisibleDeadline(data.current.deadline);
      setVisiblePathInfo(data.current.path_info);
      setVisibleNodeType(data.current.node_type);
      setVisibleStaff(data.current.staff);
      setToggleStatus(data.current.status === null);
      setVisibleScale(data.current.scale);
      setVisibleClassId(data.current.class_id);
      setVisibleTags(data.current.tags);/* 
      setToggleChat(false); */
      fetchHistoryLogsOfSelectedNode(data.current.node_id, data.current.name);
      
      if (data.current.node_id === 'temporary_node'){
        setTypeOfInsertForm(typeOfInteraction.insertNewItem);
        console.log('its insert form');
      }
      else {
        setTypeOfInsertForm(typeOfInteraction.updateExistingItem);
        console.log('its update form');
      };
    };
//--------------------------------------------------------------------------------
    function setDividerToBoolean(booleanAsString) {
    /* select only offers string input that needs to be converted
    to bool using this function */

      if (booleanAsString === 'item') {
        updateVisibleDataAccordingToInput (descriptionBoxPart.divider, false);
      };

      if (booleanAsString === 'divider') {
        updateVisibleDataAccordingToInput (descriptionBoxPart.divider, true);
      };
    };

//--------------------------------------------------------------------------------

    function setStatusToInteger (statusAsString) {
    /* select only offers string input that needs to be converted
    to number using this function */

    let StatusAsNumber
      /* if (statusAsString !== 'null') {
        StatusAsNumber = Number(statusAsString);
      } */
     if (statusAsString === 'Status') {

        if (!data.current.status) {
          StatusAsNumber = 1;
        }
        else {
          console.log(data.current.status);
          StatusAsNumber = Number(data.current.status);
        }
      }
      if (statusAsString === 'null') {
        StatusAsNumber = 0;
      }

      setStatusColor(StatusAsNumber);
      updateVisibleDataAccordingToInput (descriptionBoxPart.status, StatusAsNumber);
    };

//--------------------------------------------------------------------------------

    function setStatusColor (Status) {
    /* function sets status bar color depending on status value */ 

      if (Status){
          if (Status <= 50 && Status >= 0) {
          setMyStatusColor('red');
        }

        else if (Status > 50 && Status <= 90){
          setMyStatusColor('yellow');
        }

        else if (Status > 90 && Status <= 100){
          setMyStatusColor('lightgreen');
        }
      }
      else {
        setMyStatusColor('white');
      };
    };
    const blockInvalidChar = e => ((['+', '-'].includes(e.key)) || (e.keyCode === 69 || e.keyCode === 190)) && e.preventDefault();
//--------------------------------------------------------------------------------

    async function insertRoute(e, typeOfDatabaseInteraction){ 
    
    //typeOfAction == updateItem(false) updates already existing object in database

    //typeOfAction == insertNewItem(true)(creates new (object of data => $newItem) inserted by user when making new
    //item then sends it to backend using axios

      e.preventDefault();
      
      const newItemId = uuid();
      
      // newItem => initialization of node data based on visibleData => data prepared to send to DB 
      let newItem = { name: visibleName && visibleName !== ''? visibleName : Icons.warningLight.repeat(5) + ' NO NAME ' + Icons.warningLight.repeat(5),
                      node_id: typeOfDatabaseInteraction? newItemId : data.current.node_id,
                      parent_node_id: data.current.parent_node_id,
                      ref_node_id: null,
                      path_info: visiblePathInfo && visiblePathInfo !== ''? visiblePathInfo : null,
                      description: visibleDescription && visibleDescription !== ''? visibleDescription : null,
                      date_created: visibleDateCreated && visibleDateCreated !== ''? visibleDateCreated : null,
                      date_finished: visibleDateFinished && visibleDateFinished !== ''? visibleDateFinished : null,
                      date_submitted: visibleDateSubmitted && visibleDateSubmitted !== ''? visibleDateSubmitted : null,
                      deadline: visibleDeadline && visibleDeadline !== ''? visibleDeadline : null,
                      tasks: data.current.tasks && data.current.tasks.length > 0 ? data.current.tasks : null,
                      status: visibleStatus === undefined? null :  visibleStatus,
                      type_of_item: visibleTypeOfItem && visibleTypeOfItem !== ''? visibleTypeOfItem : null,
                      node_type: visibleNodeType? visibleNodeType : 10,
                      section: visibleSection && visibleSection !== ''? visibleSection : null,
                      divider: visibleDivider? visibleDivider : false,
                      tableName: TableName,
                      priority_number: visiblePriorityNumber? visiblePriorityNumber : 0,
                      file_name_submitted: visibleFileNameSubmitted? visibleFileNameSubmitted : null,
                      sub_items: visibleItemSubitems && visibleItemSubitems !== ''? visibleItemSubitems : null,
                      change_description: visibleStatusChangeDescription? visibleStatusChangeDescription : null,
                      class_id: visibleClassId?visibleClassId : null,
                      scale: visibleScale? visibleScale : null,
                      staff: extractUserUuid(),
                      tags: visibleTags? visibleTags: null

                    };

        console.log(data.current.sub_items);

        if (typeOfDatabaseInteraction === typeOfInteraction.updateExistingItem) {
          let msg = 'Item updating'
          console.log(msg);
          ActionLogReferences.updateLogString(msg, LogMessageType.Pending, JSON.stringify(newItem, null,"\t"));

          try {
            let response = await axios.post(updateRoute, newItem);

           if (response && response.data) {
              if (response.data.resolved) {

                // if update node transaction is successful updated Node data is called from database to get actual picture of node data 
                let newData = await fetchData(getSingleNodeDataRoute, data.current.node_id, 'ProjectTable0');
                // Master data of node is then changed to new recieved data 
                data.current.name = newData.data.name;
                data.current.node_id = newData.data.node_id;
                data.current.parent_node_id = newData.data.parent_node_id;
                data.current.ref_node_id = newData.data.ref_node_id;
                data.current.path_info = newData.data.path_info;
                data.current.description = newData.data.description;
                data.current.date_created = newData.data.date_created;
                data.current.date_finished = newData.data.date_finished;
                data.current.date_submitted = newData.data.date_submitted;
                data.current.deadline = newData.data.deadline;
                data.current.tasks = newData.data.tasks;
                data.current.status = newData.data.status;
                data.current.type_of_item = newData.data.type_of_item;
                data.current.node_type = newData.data.node_type;
                data.current.section = newData.data.section;
                data.current.divider = newData.data.divider;
                data.current.priority_number = newData.data.priority_number;
                data.current.file_name_submitted = newData.data.file_name_submitted;
                data.current.sub_items = newData.data.sub_items;
                data.current.staff = newData.data.staff;
                data.current.change_description = newData.data.change_description;
                data.current.scale = newData.data.scale;
                data.current.class_id = newData.data.class_id;
                data.current.tags = newData.data.tags;

                ActionLogReferences.updateLogString('Item updated', LogMessageType.Success, `New data: ${JSON.stringify(newData, null,"\t")} \n`);
                console.log(newData, '\n');

                setVisibleData();
                DescriptionBoxReferences.recursiveSetVisibleData();
                setIsInsertFormActive(false);
              }
              else {
                alert(response.data.message);
                ActionLogReferences.updateLogString('Error updating item', LogMessageType.Error,`\n ${response.data.message}`);
                console.log(response.data);
              };
            }
            
          }
          catch (error) {
            if (error) {
              const msg = 'Error updating item';
              console.log(msg + ' :\n', error);
              ActionLogReferences.updateLogString(msg , LogMessageType.Error,`\n ${error}`);
              alert(error);
            }
          };
        }

        else if (typeOfDatabaseInteraction === typeOfInteraction.insertNewItem){
          let msg = 'Adding item'
          console.log(msg);
          ActionLogReferences.updateLogString(msg, LogMessageType.Pending,`\n ${newItem}`);

          try {

            let response = await axios.post(addPathRoute, [newItem]);

            if (response  && response.data ) {
              if (response.data.resolved) {
                console.log(response);

                data.current.node_id = newItemId;

                //let newData = await fetchData(routes.getTableRoute, data.current.node_id, 'ProjectTable0');

                DescriptionBoxReferences.fetchNewParentData(); 
                //descriptionBoxReferences.updateFlattenedData(); 

                ActionLogReferences.updateLogString('Item updated', LogMessageType.Success, /* `New data: ${JSON.stringify(newData, null,"\t")} \n` */);
                //console.log(newData,'\n');
                setIsInsertFormActive(false);
                setIsDescriptionBoxShown(false);
              }
              else {
                alert(response.data.message);
                ActionLogReferences.updateLogString('Error updating item', LogMessageType.Error,`\n ${response.data.message}`);
                console.log(response.data);
              }
            }
            
          }
          catch (error) {
            if (error) {
              ActionLogReferences.updateLogString('Error updating item', LogMessageType.Error,`\n ${error}`);
              console.log('error: ', error);
              alert(error);
            }
          };
        }
    };

//--------------------------------------------------------------------------------

//--------------------------------------------------------------------------------

    function copyToClip(selectedString) {
    /* function takes parameter @selectedString and copies it to clipboard */

        ActionLogReferences.updateLogString('Copied to Clip', LogMessageType.Success, 'Clipboard data: \n' + selectedString);
        return navigator.clipboard.writeText(selectedString);
    };
//--------------------------------------------------------------------------------

    async function deleteItem(Id, name){
    /* function deletes item from database based on item UUID (@Id),
        function alerts user before deleting item */

      let answer = window.confirm("dokončno izbriši <" + name + "> ?");

      if  (answer)
          {
            try {
              ActionLogReferences.updateLogString('Deleting Item...', LogMessageType.Pending);

              let response = await axios.delete(deleteRoute, {data: {Id: Id}, headers:{"Authorization": "+++"}});

              if (response &&  response.data){
                if (response.data.resolved) {
                  ActionLogReferences.updateLogString('Item deleted', LogMessageType.Success, `Item ${response.data.data.Id} deleted`);
                  let msg = 'Pot <' + name + '> je izbrisana';
                  DescriptionBoxReferences.fetchNewParentData();
                  alert (msg);
                  setIsDescriptionBoxShown(false);
                }
                else {
                  let msg = `Error deleting Item <${name}>`
                  ActionLogReferences.updateLogString('Error deleting Item', LogMessageType.Error, response.data.message);
                  alert (msg);
                };
              }
            }
            catch (e) {
              alert(e);
              ActionLogReferences.updateLogString('Error Occured', LogMessageType.Error, e);
            }

          } 
    else  {
            let message = 'Delete item Canceled'
            alert (message);
            ActionLogReferences.updateLogString(message, LogMessageType.Regular, message);
            return;
          };
    };

//--------------------------------------------------------------------------------
    function closeInsertForm (nameOfItem) {
    /* This is a preventive function. It's called when DescriptionBox is in edit mode. Only Submit button causes permanent data changes
      and updates the database. 
      Purpose of this function is to alert the user that form in edit mode has not been submitted and data will not be updated if user
        1.) closes the form with "x button"
        2.) clicks on another node in recursive causing descriptionBox data to update to another node.
       
      If "cancel" action is confirmed:  a.) form is set back to "readOnly" mode (not insert form)
                                        b.) DescriptionBox is closed (hidden)
                                        c.) any changed data is initialized back to master data value
                                        d.) Log is sent to ActionLog component
      */

      let answer = window.confirm('Form will be Cancelled.          Continue? \n\n (Submit must be pressed to cause changes)');

      if (answer){
        setIsInsertFormActive(false);
        setIsDescriptionBoxShown(false);
        DescriptionBoxReferences.updatePrevItem();
        
        let message = `Form submission was canceled \n =>  Item  > ${nameOfItem} <  was not updated.`;
        ActionLogReferences.updateLogString(`Item NOT updated`, LogMessageType.Error, message);
      };
    };
//--------------------------------------------------------------------------------
    function expandDescriptionBox () {
      /* Function is called when pressing open DescriptionBox button on item. 
        The state of visible descriptionBoxes is saved in openDescriptionState array passed from outside the functional component
        to remember the state of opened descriptionBoxes.*/
     
      setIsDescriptionBoxShown(!isDescriptionBoxShown); // if array to remember states is not provided => just toggle
      DescriptionBoxReferences.recSetCurrentlySelected(false);
    };

    function setInsertFormToEdit () {
      setIsInsertFormActive(!isInsertFormActive);

      if (data.current.node_id === 'temporary_node'){
        setTypeOfInsertForm(typeOfInteraction.insertNewItem);
        console.log('form is insert');
      }
      else {
        setTypeOfInsertForm(typeOfInteraction.updateExistingItem);
        console.log('form is update');
      }
    };

//--------------------------------------------------------------------------------
   function updateVisibleDataAccordingToInput (section, value) {
    /* Function is called when updating data in descriptionBox. It updates the state of section that's currently being updated.
      while updating state of visible data in descriptionBox component, the state of visible data in recursive component is synced using
      descriptionBoxReferences.updateRecursiveNode(section, value). */

      if (section === descriptionBoxPart.fileName) {
        
        setVisibleName(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.fileName, value);
      }
      else if (section === descriptionBoxPart.status) {
        
        setVisibleStatus(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.status, value);
      }
      //-----------------------------------------------------------------------------------------------------
      else if (section === descriptionBoxPart.class_id) {
        
        setVisibleClassId(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.class_id, value);
      }
      else if (section === descriptionBoxPart.scale) {
        
        setVisibleScale(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.scale, value);
      }

      //-----------------------------------------------------------------------------------------------------
      else if (section === descriptionBoxPart.typeOfItem) {
        
        setVisibleTypeOfItem(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.typeOfItem, value);
      }
      else if (section === descriptionBoxPart.tags) {
        
        setVisibleTags(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.tags, value);
      }
      else if (section === descriptionBoxPart.section) {
        
        setVisibleSection(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.section, value);
      }
      else if (section === descriptionBoxPart.comment) {
        
        setVisibleDescription(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.comment, value);
      }
      else if (section === descriptionBoxPart.priorityNumber) {
        setVisiblePriorityNumber(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.priorityNumber, value);
      }
      else if (section === descriptionBoxPart.dateCreated) {
        
        setVisibleDateCreated(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.dateCreated, value);
      }
      else if (section === descriptionBoxPart.dateFinished) {
        
        setVisibleDateFinished(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.dateFinished, value);
      }
      else if(section === descriptionBoxPart.dateSubmitted) {
        
        setVisibleDateSubmitted(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.dateSubmitted, value);
      }
      else if(section === descriptionBoxPart.pathInfo) {
        if  (/"/.test(value)) { // if string contains '"' delete it
          value = value.replace(/"/g, '');
        };
        setVisiblePathInfo(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.pathInfo, value);
      }
      else if (section === descriptionBoxPart.deadline) {
        setVisibleDeadline(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.deadline, value);
      }
      else if (section === descriptionBoxPart.fileNameSubmitted) {
        setVisibleFileNameSubmitted(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.fileNameSubmitted, value);
      }
      else if (section === descriptionBoxPart.subItems) {
        setVisibleItemSubItems(value);
        console.log(visibleItemSubitems)
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.subItems, value);
      }
      else if (section === descriptionBoxPart.divider) {
        setVisibleDivider(value);
        DescriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.divider, value);
      }
      else if (section === descriptionBoxPart.change_description) {
        setVisibleStatusChangeDescription(value);/* 
        descriptionBoxReferences.updateRecursiveNode(descriptionBoxPart.change_description, value); */
      }
      else {
        let message = 'ERROR in &updateFormSection function (descriptionBox.tsx) - unhandled case'
        alert(message);
        ActionLogReferences.updateLogString('ERROR unhandled case', LogMessageType.Error, message);
      }
   };
//--------------------------------------------------------------------------------
   function prepareFormSubmission (e) {
    /* There are 2 types of form submission process:
      1.) insert form type => When new node is created in recursive component, the value of it's node_id is set to "temporary_node"
          if node_id === "temporary_node" node has not yet been submitted to backend and form submission type is set to insert new item
      2.) if node_id === uuid, node has already been submitted to backend and exists in database. This states that node can only be updated
          setting the type of insert form to update.
      
      This is important since backend has 2 different routes and functions for updating existing node and inserting new node    */

    if (data.current.node_id === 'temporary_node'){
      setTypeOfInsertForm(typeOfInteraction.insertNewItem);
    }
    else {
      setTypeOfInsertForm(typeOfInteraction.updateExistingItem);
    }
    insertRoute(e, typeOfInsertForm);
   }
//--------------------------------------------------------------------------------
  function addUser (item, index) {
    
    if (visibleStaff) {
      setVisibleStaff(current => [...current, item]);
    }
    else {
      setVisibleStaff([item]);
    }
    filteredStaff.current.splice(index, 1);
   };
//--------------------------------------------------------------------------------
  function deleteUser (item, index) {
    /* Function is used to remove user from array of already added staff on item(people working on item)  */

    filteredStaff.current.push(item);
    let permArray = visibleStaff;
    permArray.splice(index, 1);
    setVisibleStaff([...permArray]);
 };
//--------------------------------------------------------------------------------
  function extractUserUuid() {
    /* when appending user to item, his uuid is stored in database table thus this function extracts user's uuid from user info */

    if (visibleStaff && visibleStaff.length > 0) {
      let filteredOutUserUUid = visibleStaff.map((item) => {
      return item.useruuid
    })
    /* 
    filteredOutUserUUid = JSON.stringify(filteredOutUserUUid).replace('[', '{').replace(']','}'); */
    return filteredOutUserUUid;
    }
    else {
      return null
    }
  };
//--------------------------------------------------------------------------------
  function eraseStatus() {
    setToggleStatus(!toggleStatus);
    if (toggleStatus === false) {
      setVisibleStatus(null)
    }
    else {
      setVisibleStatus(typeof(data.current.status) == 'number'? data.current.status : null);
    }
  };
  function TagComponent ({ tags }) {
    // Predpostavljamo, da so tagi podani kot niz, ločen z ";"
    let tagList;
    if (tags && tags.includes(';')) {
      tagList = tags.split(';').filter(tag => tag.trim() !== '');
    }
    else {
      return <></>;
    }
  
    return (
      <div className='sectionTitle'>{/* 
        <span className='tagsLabel'>Tags:</span> */}
        <div className='tagsContainer'>
          {tagList.map((tag, index) => (
            <div key={index} className='tagItem'>{tag}</div>
          ))}
        </div>
      </div>
    );
  };
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------

return (
    <div className ='descrBox-wrapper' style = {{display: isDescriptionBoxShown?'':'none'}}> 
            {/* <button  onClick={() => expandDescriptionBox()}
                    className = 'globals--chat-button'>
                      {Icons.message}
          </button> */}

          {toggleChat && <Chat  selectedNodeHistoryLog={selectedNodeHistoryLog} 
                                nodeData={Data}
                                setToggleChat = {setToggleChat}/>}
    {!isInsertFormActive?
/* -----------------------------------[regular descriptionBox]--------------------------------------------------------- */
      <div>
        <button  onClick={handleChatClick}
                    className = 'globals--chat-button'>
                      {Icons.message}
          </button>
          <button  onClick={() => expandDescriptionBox()}
                    className = 'globals--close-button'>
          </button>
          {/* <button  onClick={() => expandDescriptionBox()}
                    className = 'globals--chat-button'>
                      chat
          </button> */}
          <div className ='DBhead'>
              <div className = 'title'>
{visibleName} 
              </div>
        
              
          </div>
          <TagComponent tags={visibleTags}/>
{/* -------------------------------------------------------------------------------- */}
          <div className='sectionTitle flex'>
            <div>
Priority number:
            </div>
            <div className = 'contentColor alignRight'>
              {visiblePriorityNumber}
            </div>
          </div >
{/* -------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Section: 
              
              <div className='contentColor'>
                {visibleSection}
              </div>
          </div>
          <div className='sectionTitle flex'>
Class ID 

            <div>
                        {visibleClassId}
            </div>
          </div>

          <div className='sectionTitle flex'>
Scale 

            <div>
                        {visibleScale}
            </div>
          </div>
{/* -------------------------------------------------------------------------------- */}
          <div  className = 'status'>
              <div></div>

              <div  className = 'statusBar notForm' >
                  <div style = {{backgroundColor:typeof(visibleStatus) === 'number'? myStatusColor:'transparent', width: visibleStatus + '%', borderRadius:'10px'}}>
                        <div className = 'statusNameMask'>
{typeof(visibleStatus) == 'number'?
                      <>
                        Status: {visibleStatus}% done 
                      </>
                      :
                      <>
                        No status defined
                      </>
                      }
                      </div>
                  </div>
              </div>
          </div>
{/* -------------------------------------------------------------------------------- */}
  
          <div className='sectionTitle flex'>
file Name: 
          </div>

          {visiblePathInfo !== null?
            <div style = {{maxWidth: '100%', wordWrap:'break-word'}}>
                {extractFileName(visiblePathInfo)}
            </div>
          :
            <>
            </>
          }
{/* -------------------------------------------------------------------------------- */}

          <div className = 'sectionTitle flex'>
Type:
            <div className = 'contentColor'>
              {visibleTypeOfItem}
            </div>
          </div>
{/* -------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Path:   
          {visiblePathInfo && 
            <button className="copyToClipButton" onClick={() => {copyToClip(visiblePathInfo)}}>
             (Copy) {Icons.copyToClip}
            </button>}
          </div>
          <div  className = 'textInput'>
                      {visiblePathInfo}
                      
          </div>


{/* ------------------------------------Update edit when tasks program is done---------------------------------------------- */}

          <div className='sectionTitle flex'>
Comment:

          </div>
          {visibleDescription?
            <>
              <textarea   className = 'textInput'
                          readOnly = {true}
                          value = {visibleDescription}>
              </textarea>
            </>
          :
            <>
            </>
          }


          <div className='sectionTitle flex'>
Working on item:
          </div>
          <div>
            <ul>
              {visibleStaff && visibleStaff?.map((item) => {
                return (<li key = {item.useruuid}>

                            {`${item.firstname} ${item.lastname}`}
                        </li>)
              })}
            </ul>

          </div>
          
{/* -------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Date created: 

            <div className = 'contentColor'>
              {visibleDateCreated}
            </div>
          </div>
{/* -------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Date Finished: 
              <div className = 'contentColor'>
                {visibleDateFinished}
              </div>
          </div>
{/* -------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Date Submitted:
              <div className = 'contentColor'>
                {visibleDateSubmitted}
              </div> 
          </div>
{/* -------------------------------------------------------------------------------- */}
          <div className='sectionTitle flex'>
            <span>
DEADLINE:
            </span>
            <div>
              {visibleDeadline}
            </div>
          </div>
{/* -------------------------------------------------------------------------------- */}
          <div className='sectionTitle flex'>
Submitted File Name:
          </div>
          <div>
            {visibleFileNameSubmitted}
          </div>
 
{/* --------------------------------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------- */}

          <div>
            <div className='sectionTitle noBorder'>
Made By: 
            </div>

            <div className='sectionTitle noBorder'>
              ItemOwner
            </div>
            <div className='sectionTitle noBorder' style = {{justifyContent:'space-between'}}>
              <button onClick = {() => {setInsertFormToEdit()}}
                      className = 'globals--regular-button'>
Edit
              </button>
            
            {data.current.node_id !== 'temporary_node'?
            <div>
              <button className='globals--red-button '
                    onClick = {() => deleteItem(data.current.node_id, data.current.name)}>
                {Icons.trashCan}
              </button>
            </div>
            :
            <div>
              temporary node
            </div>}
          </div>
        </div>
{/* -------------------------------------------------------------------------------- */}

      </div>
/* ---------------------------------[regular descriptionBox]--------------------------------------------------------- */
    :
/* -------------------------------------[insert Form]--------------------------------------------------------- */

    <form onSubmit={(e) => prepareFormSubmission(e)}>
              <button  onClick={() => setToggleChat(!toggleChat)}
                        className = 'globals--chat-button'>
                          {Icons.message}
              </button>
              <button onClick={() => closeInsertForm(visibleName)}
                      className = 'globals--close-button'>
              </button>  
              <div className ='DBhead'>

                  <div className = 'title'>
{visibleName} 
                  </div>
            
 
              </div>
              
              <div className='sectionTitle flex'>
Tags:
              </div>

              <textarea   className ='textInput'
                          placeholder = {'Insert Name'}
                          value = {visibleTags}
                          onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.tags, e.target.value)}}>                 
              </textarea>
              <div className='sectionTitle flex noBorder'>
Type of item: 
                <select defaultValue = {visibleDivider?'divider':'item'}
                        key = 'new Name'
                        onChange={(e) => {setDividerToBoolean(e.target.value)}}>

                  <option value = 'divider'>Divider</option>
                  <option value = 'item'>Item</option>
                </select>
              </div>
              
              <div className='sectionTitle flex'>
Name:
              </div>

              <textarea   className ='textInput'
                          placeholder = {'Insert Name'}
                          value = {visibleName}
                          onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.fileName, e.target.value)}}>                 
              </textarea>
              <div className='sectionTitle flex'>
            <div>
Priority number:
            </div>
              <textarea value = {visiblePriorityNumber}
                        onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.priorityNumber, e.target.value)}}>
              </textarea>
          </div >
              <div className='sectionTitle flex'>
Type of Target:
                <select value = {visibleTypeOfItem}
                        key = 'new type of file'
                        onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.typeOfItem, e.target.value)}}>
                  <option value = 'undefined'>undefined</option>
                  <option value = 'drawing'>Drawing</option>
                  <option value = 'document'>Document</option>
                  <option value = '2D_model'>2D Model </option>
                  <option value = '3D_model'>3D Model</option>
                  <option value = 'Folder'>Folder</option>
                  <option value = 'webPage'>WebPage</option>
                </select>

              </div>
          
          <div className='sectionTitle flex'>
Section:
            <input  type = 'text'
                    value = {visibleSection}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.section, e.target.value)}}
                    className = 'textInput sectionInput'
                    placeholder = '[1234]'>
            </input>
          </div>

          <div className='sectionTitle flex'>
Class ID 

            <input  type = 'text'
                    value = {visibleClassId}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.class_id, e.target.value)}}
                    className = 'textInput sectionInput'
                    placeholder = 'G.123'>
            </input>
          </div>

          <div className='sectionTitle flex'>
Scale 

            <input  type = 'text'
                    value = {visibleScale}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.scale, e.target.value)}}
                    className = 'textInput sectionInput'
                    placeholder = '1:50'>
            </input>
          </div>

          <div className='status'>
            <div className = 'sectionTitle flex noBorder'>
              
              
              <input type = 'checkbox'
                      style = {{transform:'scale(1.5)'}}
                      onChange = {() => eraseStatus()}
                      checked = {typeof(visibleStatus) == 'number' && !toggleStatus}>
              </input>
              <div style = {{textDecoration:toggleStatus?'line-through':''}}
                    className = 'sectionTitle flex noBorder'>
Status
              </div>

            </div>

            <div style = {{textAlign:'center',display:toggleStatus?'none':''}}
                  className = 'helpers--section-container-orientation-flex-row'>
              <input type = 'number'
                      value = {String(visibleStatus)}
                      onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.status, e.target.value)}}
                      placeholder='Enter % Done'>
              </input>
            </div>
          </div>
{/* 
          <div className = 'sectionTitle noBorder'>sDescription:</div>
          <textarea placeholder = {'Describe What Changed'}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.change_description, e.target.value)}}
                    className = 'textInput'>

          </textarea> */}

{/* --------------------------------------------------------------------------------------------------------------- */}
         
          <div> 
            <div className='sectionTitle flex'>
File Name:
            </div>
            {visiblePathInfo === '' ?
              <>
              </>
            :
              <div style = {{maxWidth: '100%', wordWrap:'break-word'}}>
                {extractFileName(visiblePathInfo)}
              </div>
            }
            
          </div>

          <div className = 'sectionTitle flex'>
Path:
          </div>
          
          <textarea value = {visiblePathInfo}
                    readOnly = {false}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.pathInfo, e.target.value)}}
                    className = 'textInput'
                    placeholder='Insert Path Of Item'>
          </textarea>


  
{/* --------------------------------------------------------------------------------------------------------------- */}

{/*           <div className='sectionTitle flex'>
Comment:

          </div>

          <div>
            <textarea value = {visibleDescription}
                      onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.comment, e.target.value)}}
                      className = 'textInput'
                      placeholder='Insert Comment'>
            </textarea>
          </div> */}
          
          <div className = 'sectionTitle noBorder'>sComment:</div>
          <textarea onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.change_description, e.target.value)}}
                    className = 'textInput'>

          </textarea>

          <div className='sectionTitle flex'>
Working on item:
          </div>
            <ul data-tooltip-id="remove-people" data-tooltip-content="Click to remove" style = {{listStyleType:'decimal'}}>
              {visibleStaff?.map((item, index) => {
                return (<li key = {item.useruuid}
                            onClick = {() => deleteUser(item, index)}>
                            {`${item.firstname} ${item.lastname}`}
                        </li>)
              })}
            </ul>

          <div className='sectionTitle flex'>
Add People:
          </div>
            
              <ul data-tooltip-id="add-people" data-tooltip-content="Click to add" style = {{listStyleType:'decimal'}}>
                {filteredStaff.current.map((item, index) => {
                  return (
                    <li key = {item.useruuid} 
                        onClick= {() => addUser(item, index)}>
                      {`${item.firstname} ${item.lastname}`}
                    </li>
                  )
                })}
              </ul>
            
            <Tooltip id="add-people"/>
            <Tooltip id="remove-people"/>
          

{/* --------------------------------------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Date Created:
            <input  type = 'date'
                    value = {visibleDateCreated || ''}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.dateCreated, e.target.value)}}
                    className = 'contentColor'
                    >      
            </input>
          </div>
    
{/* --------------------------------------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Date Finished:
            <input  type = 'date'
                    value = {visibleDateFinished || ''}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.dateFinished, e.target.value)}}
                    className = 'contentColor'>
            </input> 
          </div>
    
{/* --------------------------------------------------------------------------------------------------------------- */}

          <div className='sectionTitle flex'>
Date Submitted:
            <input type = 'date'
                  value = {visibleDateSubmitted || ''}
                  onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.dateSubmitted, e.target.value)}}
                  className='contentColor'>
            </input>
          </div>
    
{/* --------------------------------------------------------------------------------------------------------------- */}
          <div className='sectionTitle flex'>
DEADLINE:
            <input type = 'date'
                  value = {visibleDeadline || ''}
                  onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.deadline, e.target.value)}}
                  className='contentColor'>
            </input>
          </div>

          <div className = 'sectionTitle flex'>
Submitted File Name:
          </div>
          
          <textarea value = {visibleFileNameSubmitted}
                    readOnly = {false}
                    onChange = {(e) => {updateVisibleDataAccordingToInput(descriptionBoxPart.fileNameSubmitted, e.target.value)}}
                    className = 'textInput'
                    placeholder='Submitted file must hold this name'>
          </textarea>  
{/* --------------------------------------------------------------------------------------------------------------- */}

          <div className='footer'>
            <button type = 'submit' 
                    autoFocus={true}
                    className = 'globals--regular-button'/* 
                    onClick={() => insertRoute(typeOfInsertForm)} */>
                      
Submit
            </button>
          </div>

{/* --------------------------------------------------------------------------------------------------------------- */}

    </form>
/* -------------------------------------[insert Form]--------------------------------------------------------- */
    }

    </div>

    ) //return
  }// end of function


 interface ChatComponentProps {
  selectedNodeHistoryLog,
  nodeData: any
  setToggleChat
 }
  const Chat: React.FC<ChatComponentProps> = ({selectedNodeHistoryLog, nodeData, setToggleChat}) => {
    
    return (
      <div className='resizable-popup'>
        

        <div style = {{textAlign:'justify', padding: '2em'}}>
          <div style = {{display:'flex', flexDirection:'row',position:'sticky', top: 0, backgroundColor:'rgb(255,255,255,0.8)',justifyContent:'space-around', textAlign:'center', alignItems:'center', alignContent:'center'}}>
            <h1>Comments: </h1>
            <h2 className = 'title'>
              {nodeData.name}
            </h2>
            <button className = 'globals--close-button' 
                    onClick={() => setToggleChat(false)}/>
          </div>
          
          <div className = 'changesList'>
            
            <div className = 'listComponent'>
              <ul style = {{listStyleType:'none'}}>
                {selectedNodeHistoryLog.map((item) => {
                  return (
                    <li key = {item.changeid}>
                      <HistoryLogItem
                        changeOwner={item.change_owner}
                        changeDescription={item.change_description}
                        date = {item.change_time}
                        newStatus={item.status}
                        statusChange={item.status_change}/>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  export default memo(DescriptionBox)