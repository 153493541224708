import axios from "axios";

export function extractFileName(path) {
  /* function takes in whole path and returns only last item (name of file/folder)
    it currently seperates by checking for '\\' in a path */
  if (!path) {
    return '';
  }

  return path.substring(path.lastIndexOf('\\') + 1);
};
//-----------------------------------------------------------------------------------------
export function extractFileExtension(path) {
  if (path === null) {
    return '';
  }

  const lastSixCharacters = path.slice(-6);

  if (/\./.test(lastSixCharacters)) {
    const extension = lastSixCharacters.split(".")[1].replace(/\//g, '');
    return `[.${extension}]`;
  }

  return '';
};
//-----------------------------------------------------------------------------------------
export default async function fetchData(route: string, recursiveParent: any, selectTable: string): Promise<any> {
  try {
    const response = await axios.post(route, { parentId: recursiveParent, tableName: selectTable });
    
    if (response.data && response.data.resolved === true) {
      const obj = JSON.parse(response.data.tableJsonStr);
      const res = { resolved: true, data: obj, message: 'New data is set' };
      console.log('Data transfer successful in "fetchData()"\n', res);
      return res;
    } else {
      const message = response.data && response.data.message ? response.data.message : 'No response.data.message!';
      alert(message);
      return { resolved: false, data: null, message };
    }
  } catch (error) {
    console.log(error);
    const message = 'Error occurred while fetching data!';
    alert(message);
    return { resolved: false, data: null, message };
  }
};
//-----------------------------------------------------------------------------------------
export async function fetchUsersTable(route: string): Promise<any> {
  try {
    const response = await axios.post(route);
    const responseData = response.data;

    if (responseData && responseData.resolved === true) {
      const obj = JSON.parse(responseData.data);
      const res = {
        resolved: true,
        data: obj,
        message: 'New data is set'
      };
      console.log('Data transfer successful in "fetchUsersTable()"\n', res);
      return res;
    } else {
      const errorMessage = responseData && responseData.message ? responseData.message : 'No response data message!';
      alert(errorMessage);
      return {
        resolved: false,
        data: null,
        message: errorMessage
      };
    }
  } catch (error: any) {
    const errorMessage = 'An error occurred while fetching data: ' + error.message;
    alert(errorMessage);
    return {
      resolved: false,
      data: null,
      message: errorMessage
    };
  }
};

//-----------------------------------------------------------------------------------------
export function getCurrentTime() {
  /* Function creates string of current time
    => Format: hh:mm:ss */
  let date = new Date();

  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let time;
  
  if (minutes < 10) {
    time = hours + ':' + '0' + minutes;
  } else {
    time = hours + ':' + minutes;
  }

  if (seconds < 10) {
    time = time + ':' + '0' + seconds;
  } else {
    time = time + ':' + seconds;
  }

  return time;
};

