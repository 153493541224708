import {useState, useEffect} from "react";
import axios from "axios";
import { LogMessageType, Icons } from "../../../functions_interfaces/interfaces";
import { unifyDuplicatedTagsRoute, deleteTagRoute } from "../../../utils/routes";

export interface tag {
    id: '',
    name:''
}
function TagSettingsComponent ({ActionLogReferences, toggleTagComponent, tagsArray, fetchTags}) {
    const [tags, setTags] = useState<tag[]>(tagsArray.tags);
    const [unusedTags, setUnusedTags] = useState<tag[]>(tagsArray.unusedTags);
    const [activeColumn, setActiveColumn] = useState('tags');
    const [groupedDuplicatedTags, setGroupedDuplicatedTags] = useState({});
    const [selectedGroup, setSelectedGroup] = useState<tag[]>();
    const [selectedGroupKey, setSelectedGroupKey] = useState<string>('');
    const [showDuplicatedTagList, setShowDuplicatedTagList] = useState<boolean>(false);
    const [showDeleteTag, setShowDeleteTag] = useState(false);

    const [tagToDelete, setTagToDelete] = useState<tag>()
    
    useEffect(() => {
        groupDuplicatedTags(tagsArray.duplicatedTags);
    }, []);
    
    const closeComponent = () => {
        toggleTagComponent(false);
    };



    async function unifyDuplicatedTags (selectedUnifiedTag) {
        
        try {
            let response = await axios.put(unifyDuplicatedTagsRoute, {tagName: selectedUnifiedTag});

            if (response && response.data.resolved) {
                await fetchTags();
                setShowDuplicatedTagList(false);
                setGroupedDuplicatedTags({});
            }
            else {
                ActionLogReferences.updateLogString(
                    'Error fetching data!',
                    LogMessageType.Error,
                    JSON.stringify(response.data.message, null, "\t")
                );
            }
        }
        catch (e) {
            ActionLogReferences.updateLogString(
                'Error fetching data!',
                LogMessageType.Error,
                JSON.stringify(e, null, "\t")
            );
        };
    };

    function groupDuplicatedTags(duplicatedTags) {
        const groupedTags = {};
    
        duplicatedTags.forEach(tag => {
            const uppercaseTag = tag.name.toUpperCase();
    
            if (!groupedTags[uppercaseTag]) {
                groupedTags[uppercaseTag] = [];
            }
    
            groupedTags[uppercaseTag].push(tag);
        });
        setGroupedDuplicatedTags(groupedTags);
    }

    const renderRowsBasedOnActiveColumn = () => {
        switch (activeColumn) {
            case 'tags':
                return tags.map((tag, index) => <tr key={index}><td>{tag.name}</td></tr>);
            case 'unusedTags':
                return unusedTags.map((tag, index) => <tr onClick = {() => handleDeleteTagClick(tag)} key={index}><td>{tag.name}</td></tr>);
            case 'duplicatedTags':
                return Object.keys(groupedDuplicatedTags).map((groupName, index) => (
                    <tr key={index} onClick={() => handleTagClick(groupName)}>
                        <td>{groupName}</td>
                        <td>
                            {groupedDuplicatedTags[groupName].map((tag, idx) => (
                                    <div key={idx}>{tag.name}</div>
                                ))
                            }
                        </td>
                    </tr>
                ));
            default:
                return null;
        }
    };
    
    function handleTagClick(tagName) {
        setShowDuplicatedTagList(true);
        setSelectedGroupKey(tagName); 
        setSelectedGroup(groupedDuplicatedTags[tagName]);
    };
    function handleDeleteTagClick (tag) {
        setShowDeleteTag(true);
        setTagToDelete(tag);
    };

    const FixTagsComponent = ({ groupName, duplicatedTags }) => {
        const [selectedUnifiedTag, setSelectedUnifiedTag] = useState('');
        if (!groupName || !duplicatedTags) {
            return null;
        }
    
        return (
            <div className='fix-tags-container'>
                <div style = {{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>   
                    <h2 className='fix-tags-title'>{`Unify tags: ${groupName}`}</h2>
                        <button onClick={() => setShowDuplicatedTagList(false)} className="close-fix-tags-btn">
                            {Icons.closeIcon}
                        </button>
                </div>
            
            <ul className='tag-list'>
                <div style = {{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h3>Select or write unified tag name: </h3>
                    <h3>
                        <input  type = 'text' 
                                value = {selectedUnifiedTag}
                                onChange={(e) => setSelectedUnifiedTag(e.target.value)}/>
                    </h3>
                    <button onClick = {() => unifyDuplicatedTags(selectedUnifiedTag)}>
                        Unify
                    </button>
                </div>
                {duplicatedTags.map((tag, index) => (
                    <li key={index} 
                        className='tag-item'
                        onClick={() => setSelectedUnifiedTag(tag.name)}
                        style = {{backgroundColor: tag.name === selectedUnifiedTag? 'lightblue' : '', 
                                cursor: 'pointer',
                                color: tag.name === selectedUnifiedTag? '' : 'grey',
                                fontWeight:tag.name === selectedUnifiedTag? 'bold' : '',}}>
                        {tag.name}
                    </li>
                ))}
            </ul>
        </div>
        );
    };

    async function deleteTag(tag) {
        let check = window.confirm(`Ali si resnično želite izbrisati tag ${tag.name}?`);

        if (check) {
        try {
            let response = await axios.put(deleteTagRoute, {tag});

            if (response && response.data.resolved) {
                await fetchTags();
                setShowDeleteTag(false);
                setTagToDelete({id: '', name: ''});
            }
            else {
                ActionLogReferences.updateLogString(
                    'Error fetching data!',
                    LogMessageType.Error,
                    JSON.stringify(response.data.message, null, "\t")
                );
            }
        }
        catch (e) {
            ActionLogReferences.updateLogString(
                'Error fetching data!',
                LogMessageType.Error,
                JSON.stringify(e, null, "\t")
            );
        }}
        else {
            return;
        }
    }
    const DeleteTagsComponent = () => {
        if (!tagToDelete) {
            return null;
        }
    
        return (
            <div className='fix-tags-container'>
                <div style = {{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>   
                    <h2 className='fix-tags-title'>{`Unify tags: ${tagToDelete?.name}`}</h2>
                    <button onClick={() => setShowDeleteTag(false)} className="close-fix-tags-btn">
                        {Icons.closeIcon}
                    </button>
                </div>
            <button className = 'globals--regular-button' onClick={() => deleteTag(tagToDelete)}>
                Delete
            </button>
        </div>
        );
    };
    
    
    return (
        <div className='resizable-popup'>
            <div className='global-list'>
                <div className='title'>Tag Settings</div>
                <select onChange={(e) => setActiveColumn(e.target.value)}>
                    <option value="tags">Tags</option>
                    <option value="unusedTags">Unused Tags</option>
                    <option value="duplicatedTags">Duplicated Tags</option>
                </select>
                {activeColumn === 'duplicatedTags' && showDuplicatedTagList && 
                    <FixTagsComponent 
                        groupName={selectedGroupKey} 
                        duplicatedTags={selectedGroup}
                    />
                }
                {activeColumn === 'unusedTags' && showDeleteTag && 
                    <DeleteTagsComponent/>
                }
                <table>
                    <thead>
                        <tr>
                            <th>{activeColumn.charAt(0).toUpperCase() + activeColumn.slice(1)}</th>
                            {activeColumn === 'duplicatedTags' && 
                                <th>Variations</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {renderRowsBasedOnActiveColumn()}
                    </tbody>
                </table>
            </div>
            <button  className="globals--close-button"
                    onClick={closeComponent}></button>
        </div>
    );
}
export default TagSettingsComponent;