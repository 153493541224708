import React, {useState, useEffect, useRef} from 'react';
import { Icons, PageNumber, Sensor, SetNewVirtualTourNodeProps, VirtualToursInterface } from '../../functions_interfaces/interfaces';
import axios from 'axios';
import { 
  addFolderRoute, 
  getVirtualTourNodeTreeRoute,
  virtualTourRoute
} from '../../utils/routes';
import { useTranslation } from 'react-i18next';
import { getModuleNodetreeMenu } from '../monitoring/_monitoring';

function VirtualTour({VirtualTourReferences}: VirtualToursInterface) {
  const { t } = useTranslation();
  const [nodeTreeData, setNodeTreeData] = useState<Sensor[]>([]);
  const [viewedVirtualTour, setViewedVirtualTour] = useState('')

  async function getVirtualTourNodeTree () {
    try {
      let response = await axios.get(getVirtualTourNodeTreeRoute,{
        params: {
          pageNumber: PageNumber.Virtual_tour
        }
      })

      if (response && response.data.resolved) {
        console.log(response.data.data);
        setNodeTreeData(response.data.data);
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  VirtualTourReferences.setViewedVirtualTour = setViewedVirtualTour;
  VirtualTourReferences.getVirtualTourNodeTree = getVirtualTourNodeTree

  useEffect(() => {
    getVirtualTourNodeTree();
  }, []);

  useEffect(() => {
    
    getMonitoringNodeTreeInsideComponent();
  }, []);

  async function getMonitoringNodeTreeInsideComponent() {
    const monitoringNodeTreeData = await getModuleNodetreeMenu(PageNumber.Virtual_tour, getVirtualTourNodeTreeRoute) as Sensor[]
    setNodeTreeData(monitoringNodeTreeData)
  }

  return (
    <>
      <header className='globals--module-navbar'>
        <div className='globals--module-navbar-title'>
          {t(`index.head.dropdown.virtualtour`)}
          <div className = 'globals--module-navbar-title-arrow'> {Icons.arrowRight} </div>

          <div className= 'globals--module-navbar-dropdown-tree-menu'>
            <RenderFirstLevel 
              firstLevelItems={nodeTreeData}
              VirtualTourReferences={VirtualTourReferences}
            />
          </div>


        </div>
        <div className = 'helpers--section-container-orientation-flex-row'>
          <button className = 'globals--regular-button' 
                  onClick={() => navigator.clipboard.writeText(`${virtualTourRoute}${viewedVirtualTour}/index.html`)}>
            Copy Path
          </button>
        </div>
      </header>
      <main className='virtualTourContainer'>

        
        <div className = 'virtual-tour'>
          <iframe 
            src={`${virtualTourRoute}${viewedVirtualTour}`}
            title="Virtual Tour"/>
        </div>
      </main>
    </>
  );
};

export default VirtualTour;

function RenderThirdLevel({firstLevelItems, secondLevelItems, VirtualTourReferences}) {

  const handleNewSensorClick = () => {
    setNewTreeItemBasedOnType({
      isProject: false,
      isSensor: true,
      parentUUID: secondLevelItems.uuid,
      name: '',
      projectData: firstLevelItems,
      workplaceData: secondLevelItems,
      VirtualTourReferences: VirtualTourReferences
    });
  };

  return (
    <>
      <div 
        onClick={handleNewSensorClick} 
        className='add-new-item sensor'
      >
        New Virtual Tour
      </div>

      {secondLevelItems.children?.map(thirdLevelItem => (
        <ThirdLevelItem 
          key={thirdLevelItem.uuid}
          
          firstLevelItem={firstLevelItems}
          secondLevelItem={secondLevelItems}
          thirdLevelItem={thirdLevelItem}
          VirtualTourReferences = {VirtualTourReferences}
        >
          {/* Optionally render sensor details here */}
        </ThirdLevelItem>
      ))}
    </>
  );
}
//------------------------------------------------------------------------------------------------------
function RenderSecondLevel({firstLevelItems, VirtualTourReferences}) {

  const handleNewWorkplaceClick = () => {
    setNewTreeItemBasedOnType({
      isProject: false,
      isSensor: false,
      parentUUID: firstLevelItems.uuid,
      name: '',
      projectData: firstLevelItems,
      VirtualTourReferences: VirtualTourReferences
    });
  };

  return (
    <>
      <div 
        onClick={handleNewWorkplaceClick} 
        className='add-new-item workplace'
      >
        New Workplace
      </div>

      {firstLevelItems.children?.map(SecondLevelItem => (
        <TreeItem key={SecondLevelItem.uuid} label={SecondLevelItem.name}>
          <RenderThirdLevel 
            firstLevelItems={firstLevelItems} 
            secondLevelItems={SecondLevelItem}
            VirtualTourReferences = {VirtualTourReferences}
          />
        </TreeItem>
      ))}
    </>
  );
}
//------------------------------------------------------------------------------------------------------
function RenderFirstLevel({firstLevelItems, VirtualTourReferences}) {

  const handleNewProjectClick = () => {
    setNewTreeItemBasedOnType({
      isProject: true,
      isSensor: false,
      parentUUID: '',
      name: '',
      VirtualTourReferences: VirtualTourReferences
    });
  };

  return (
    <>
      <div 
        onClick={handleNewProjectClick} 
        className='add-new-item project'
      >
        New Project
      </div>

      {firstLevelItems?.map(item => (
        <TreeItem key={item.uuid} label={item.name}>
          <RenderSecondLevel 
            firstLevelItems={item}
            VirtualTourReferences  = {VirtualTourReferences}
          />
        </TreeItem>
      ))}
    </>
  );
};
//------------------------------------------------------------------------------------------------------
const TreeItem = React.memo(({ label, children }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
      setIsExpanded(!isExpanded);
  };
  return (
      <div className="TreeItem">
          <div onClick={toggleExpand} className="TreeItem-label">
              {isExpanded ? Icons.arrowDown : Icons.arrowRight}
              {label}
          </div>
          {isExpanded && <div className="TreeItem-children">{children}</div>}
      </div>
  );
});
//------------------------------------------------------------------------------------------------------
const ThirdLevelItem = React.memo(({ firstLevelItem, secondLevelItem, thirdLevelItem, VirtualTourReferences }: any) => {

  const handleVirtualTourClick = () => {
    VirtualTourReferences.setViewedVirtualTour(thirdLevelItem.file_name);
    console.log(thirdLevelItem.file_name)
  }
  return (
      <div className="TreeItem" onClick={handleVirtualTourClick}>
        
          <div  className="TreeItem-label" >
                {thirdLevelItem.name}
          </div>
          
      </div>
  );
});

export function InsertVirtualTourNode ({VirtualTourReferences}) {

  const [newItemState, setNewItemState]= useState<any>()
  const [showComponent, setShowComponent] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selected, setSelected] = useState(false);
  const fileRef = useRef<any>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUploadProgressBar, setShowUploadProgressBar] = useState(false);


  if (VirtualTourReferences) {
    VirtualTourReferences.setShowInsertForm = setShowComponent;
  }

  const handleNewNodeItemClose = () => {
      setShowComponent(false);
      setSelectedFile(null);
      setNewItemState(VirtualTourReferences.data);
      setSelected(false);     // This line resets the selected state to false
      if (fileRef.current) {
        fileRef.current.value = null;  // This line resets the value of the file input field
      }
  };

  async function submitFile() {
  
    const formData = new FormData();
    formData.append('zipFile', selectedFile);
    formData.append('nodeTreeItemData', JSON.stringify(newItemState));
  
    try {
      setShowUploadProgressBar(true);
      const response = await axios.post(addFolderRoute, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

      if (response && response.data.resolved) {
        setShowComponent(false);
        VirtualTourReferences.getVirtualTourNodeTree()
        handleNewNodeItemClose();
        setShowUploadProgressBar(false);
      } else {
        window.alert(response.data.message);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileSubmit = () => {
    submitFile();
  }


  const isButtonDisabled = () => {
    if (newItemState?.isSensor) {
      if (newItemState?.name.length > 0) {

        return !selected;
      } else {
        return true;
      }
    } else {
      console.log(newItemState?.name.length <= 0)
      return newItemState?.name.length <= 0;
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setSelected(true);
    } else {
      setSelected(false);
    }
  };


  useEffect(() => {
      setNewItemState(VirtualTourReferences?.data);
  },[VirtualTourReferences?.data]);


  return(
      <div className = 'insertNodeFormContainer' style = {{ display: showComponent? '':'none'}}>
          <div style = {{opacity: '1', backgroundColor: newItemState?.isProject? '#007BFF': newItemState?.isSensor?'#FFA500': '#008248'}} className='insertNodeForm'>
            <div className="gridContainer">
              {newItemState?.isProject && 
                <div>
                  <div>
                    <span>Projekt:</span>
                    <span>{newItemState?.name}</span>
                  </div>
                </div>
              }
                          
              {newItemState?.isSensor && 
                <div>
                  <div>
                    <span>Project:</span>
                    <span>{newItemState?.projectData?.name}</span>
                  </div>
                  <div>
                    <span>Workplace:</span>
                    <span>{newItemState?.workplaceData?.name}</span>
                  </div>
                  <div>
                      <span>Virtual_Tour:</span>
                      <span>{newItemState?.name}</span>
                  </div>
                  <div>
                  <input type="file" ref={fileRef} accept=".zip" onChange={handleFileChange} />


                  </div>
                </div> 
              }

              {!newItemState?.isProject && !newItemState?.isSensor && 
                <div>
                  <div>
                    <span>Project:</span>
                    <span>{newItemState?.projectData?.name}</span>
                  </div>
                  <div>
                    <span>Workplace:</span>
                    <span>{newItemState?.name}</span>
                  </div>
                </div>
              }
            </div>
            <textarea placeholder='Insert Name' 
                      onChange = {(e) => setNewItemState(prev => ({...prev, name: e.target.value}))}
                      value = {newItemState?.name}
                      style = {{gridColumn: 'span 2'}}/>
            <div style = {{gridColumn: 'span 2', textAlign:'center', display: showUploadProgressBar?'': 'none', backgroundColor:'darkred' }}>
              Uploaded {uploadProgress}%
            </div>
            
            <button onClick = {handleFileSubmit}
                    disabled = {isButtonDisabled()}>
              Confirm
            </button>
            <button onClick = {handleNewNodeItemClose}>
              Cancel
            </button>
          </div>
      </div>
  )
}


function setNewTreeItemBasedOnType({
  isProject,
  isSensor,
  parentUUID,
  name = '',
  projectData,
  workplaceData,
  virtualTourData,
  VirtualTourReferences
}: SetNewVirtualTourNodeProps) {

  const monitoringData = {
    name,
    isProject,
    isSensor,
    parentUUID,
    projectData,
    workplaceData,
    virtualTourData,
  };

  VirtualTourReferences.data = monitoringData;
  VirtualTourReferences.setShowInsertForm(true);
}







