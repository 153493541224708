
import { useEffect, useState } from 'react';
import { OverviewInterface, LogMessageType, HistoryLogItemProps } from '../../../functions_interfaces/interfaces';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { 
  getOverviewNodeListRoute, 
  getHistoryLogsOfNodeRoute, 
  getSingleNodeDataRoute } from '../../../utils/routes';
import { Line, Doughnut } from 'react-chartjs-2';
import fetchData from '../../../functions_interfaces/functions'

export default function Overview({OverViewReferences, DescriptionBoxReferences, ActionLogReferences}: OverviewInterface) {

  const [nodeEntryPoint, setNodeEntryPoint] = useState<any>();
  const [currentlyViewedNode, setCurrentlyViewedNode] = useState<string>('')
  const [nodeListData, setNodeListData] = useState<any>([]);
  const [statusStatistics, setStatusStatistics] = useState<any>({});
  const [averageStatus, setAverageStatus] = useState(0);

  const [selectedNodeHistoryLog, setSelectedNodeHistoryLog] = useState<any>([]);

//-----------------------------------------------------------------------
  function setDescriptionbox(dataOfItem) {
    /* function gets called when name on list is clicked. it opens the descriptionbox and
        sets the data  */
    
        DescriptionBoxReferences.dBoxData.current = dataOfItem
        DescriptionBoxReferences.setVisibleData(dataOfItem);
        DescriptionBoxReferences.setShowDescriptionBox(true);
  };

//-----------------------------------------------------------------------
  useEffect(() => {
    fetchNodeList(OverViewReferences.nodeEntryPoint)
  }, []);

  async function fetchNodeList (nodeid) {
    try {
      let response = await axios.post(getOverviewNodeListRoute, {node_id: nodeid , tableName: 'ProjectTable0'})

      if (response && response.data.resolved) {
        let sortedList = response.data.data.nodelist.sort((a, b) => a.name.localeCompare(b.name));
        const matchingNode = sortedList.find((node) => node.node_id === nodeid);

        setNodeEntryPoint(matchingNode.name);
        setNodeListData(sortedList);
        setStatusStatistics(response.data.data.statistics);
        setAverageStatus(Math.floor(response.data.data.averageStatus));
        

        ActionLogReferences.updateLogString(
          'Data transfer success',
          LogMessageType.Success,
          response.data.message
        );
      } else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          response.data.message
        );
      };
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        e
      );
    }
  };
  async function fetchHistoryLogsOfSelectedNode(node_id, nodeName) {
    try {
      let response = await axios.post(getHistoryLogsOfNodeRoute, {node_id})

      if (response && response.data.resolved) {

        let sortedListByDate = response.data.data.sort((a, b) => new Date(b.change_time).getTime() - new Date(a.change_time).getTime());
        setSelectedNodeHistoryLog(sortedListByDate);
        setCurrentlyViewedNode(nodeName);
        let newData = await fetchData(getSingleNodeDataRoute, node_id, 'ProjectTable0');
        console.log(newData);
        setDescriptionbox(newData.data);

        ActionLogReferences.updateLogString(
          'Data transfer success',
          LogMessageType.Success,
          response.data.message
        );
      } else {
        ActionLogReferences.updateLogString(
          'Error fetching data!',
          LogMessageType.Error,
          response.data.message
        );
      };
    }
    catch (e) {
      ActionLogReferences.updateLogString(
        'Error fetching data!',
        LogMessageType.Error,
        e
      );
    }
  }

  return (
    <div className="OverviewWrapper">
      <div className = 'ItemList'>
        <div className = 'title'>
          {nodeEntryPoint} 
          <div className = 'nodeCount'>
            {nodeListData.length} items
          </div>
        </div>
        <div className = 'listComponent'>
          <ul>
            {nodeListData.map((item) => {
              return (
                <li key = {item.node_id}
                    onClick={() => fetchHistoryLogsOfSelectedNode(item.node_id, item.name)}>
                  {item.name}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className = 'changesList'>
        <div className = 'title'>
          {currentlyViewedNode}
        </div>
        <div className = 'listComponent'>
          <ul>
            {selectedNodeHistoryLog.map((item) => {
              return (
                <li key = {item.changeid}>
                  <HistoryLogItem
                    changeOwner={item.change_owner}
                    changeDescription={item.change_description}
                    date = {item.change_time}
                    newStatus={item.status}
                    statusChange={item.status_change}/>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className = 'graphSection'>
        <div className = 'title'>
          {nodeEntryPoint}
        </div>
        <div className = 'pieChart'>
          <DonutChart data = {statusStatistics}/>
          <div className = 'progressBar'>
              <div className = 'progress' style = {{height: `${averageStatus}%`}}>
                {averageStatus}%
              </div>
          </div>
        </div>
        <div className = 'barChart'>
          <LineChartWithMarkers data = {selectedNodeHistoryLog}/>
        </div>
      </div>
    </div>
  );
}


export const HistoryLogItem: React.FC<HistoryLogItemProps> = ({
  changeOwner,
  changeDescription,
  date,
  newStatus,
  statusChange,
}) => {

  const [datePart, timePart] = new Date(date).toISOString().split('T');
  const timeFormatted = timePart.slice(0, 5);
  let [statusColor, setStatusColor] = useState('green');
  let [formatStatusChange, setFormatStatusChange] = useState('+0')

  useEffect(() => {
    statusFormat(statusChange);
  },[]);

  const statusFormat = (number: string) => {

    if (Number(number) > 0) {
      setStatusColor('green');
      setFormatStatusChange(`+${number}`);
    }
    else {
      setStatusColor('red');
      setFormatStatusChange(number)
    }
  }

  return (
    <div className="history-log-item">
      <div className="log-header">
        <div className="change-owner">{changeOwner}</div>
        <div className="date">
          <div className="date-date">{datePart}</div>
          <div className="date-time">{timeFormatted}</div>
        </div>
      </div>
      <div className="change-description">
          <ReactMarkdown>{changeDescription}</ReactMarkdown>
      </div>

      {formatStatusChange || newStatus && <div className="status-change">
        {newStatus && <div className="new-status">{newStatus}%</div>}
        {formatStatusChange &&
          <div  className="status-change-indicator" 
              style = {{color: statusColor}}>
          {formatStatusChange}%
        </div>}
      </div>}
    </div>
  );
};

interface DonutChartProps {
  data: {  zeroDone: number;
          starting: number;
          half_done: number;
          finishing: number;
          finished: number;
          no_status: number;};
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {

  const getColorForLevel = (level: keyof DonutChartProps) => {
    const colorMap: { [key: string]: string } = {
      no_status: 'transparent',
      zeroDone: 'red',
      half_done: 'yellow',
      starting: 'orange',
      finishing: 'lightgreen',
      finished: 'darkgreen'
    };

    return colorMap[level];
  };
  const getLabelWithPercentage = (level: keyof DonutChartProps) => {
    const percentageRanges: { [key: string]: string } = {
      zeroDone: '0%',
      starting: '1-25%',
      half_done: '26-75%',
      finishing: '76-99%',
      finished: '100%',
      no_status: 'No Status',
    };

    return `${level} [${percentageRanges[level]}] [${data[level]}]`;
  };

  const customLabels = Object.keys(data).map((level) => getLabelWithPercentage(level as keyof DonutChartProps));

  const chartData = {
    labels: customLabels,
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: Object.keys(data).map((level) =>
          getColorForLevel(level as keyof DonutChartProps)
        ),
      },
    ],
  };
  const chartOptions: any = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
    cutout: '65%',
    aspectRatio: 5/3,
  };

  return (
      <Doughnut data={chartData} options={chartOptions} />
  );
};



interface LineChartProps {
  data: {change_description: string;
        change_owner: string;
        change_time: string;
        changeid: string;
        itemid: string;
        status: number;
        status_change: number;}[];
}

const LineChartWithMarkers = ({ data }: LineChartProps) => {
  const chartData = {
    labels: data.map((d) => d.change_time),
    datasets: [
      {
        label: 'Status',
        data: data.map((d) => d.status),
        borderColor: 'darkgreen',
        borderWidth: 1.5,
        pointRadius: 5,
        pointHoverRadius: 8,
        fill: false,
      },
    ],
  };

  const chartOptions: any = {
    responsive: true,
    
    aspectRatio: 7/3,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Status [%]', 
          color: '#666', 
          font: {
            size: 16, // Larger font size
            weight: 'bold',
          }
        }
      },
    },
    animation: {
      duration: 0,
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataIndex = context.dataIndex;
            const dataPoint = data[dataIndex];

            return [
              `Change Owner: ${dataPoint.change_owner}`,
              `Status: ${dataPoint.status}`,
              `Status Change: ${dataPoint.status_change}`,
              `Change Description: ${dataPoint.change_description}`
            ];
          },
        },
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};
