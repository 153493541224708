import {useState, useEffect} from 'react';
import axios from 'axios';
import {
  updateSectionRoute,
  getPartialDataRoute,
} from '../../../utils/routes';
import {RecursiveInterface, descriptionBoxPart,Icons, LogMessageType} from '../../../functions_interfaces/interfaces';
import fetchData, {extractFileExtension, extractFileName} from '../../../functions_interfaces/functions';
import { Tooltip } from 'react-tooltip';


function Recursive({
  data,
  openFolderState,
  openQuickInfoState,
  showAllQuickInfo,
  selectedTable,
  unfoldTree,
  refreshRecursiveParent,
  refreshRecursiveParentState,
  
  itemIndex,
  selectedTags,
  moveItemsToggle,
  setNewData,
  fetchNewParentData,

  OverViewReferences,
  ActionLogReferences,
  DescriptionBoxReferences,
}: RecursiveInterface)  {

//-----------------------------[state of opened folders and descriptions and color depending on that]----------------------------------------
    
    const [isVisible, setIsVisible] = useState <boolean> (data.node_id === '13d7a15c-a3ac-11ed-a8fc-0242ac120002'? true : openFolderState.current.includes(data.node_id)? true : false /* || unfoldTree?unfoldTree:false *//* openFolderState.current? openFolderState.current.includes(data.node_id? data.node_id : 123) :  */);

    const [statusColor, setStatusColor] = useState<any>(() => setStatus(data.status)); //sets color of status square in quick info

    const [visibleName, setVisibleName] = useState(() => data.name);
    const [visibleNodeType, setVisibleNodeType] = useState(()=> data.node_type)
    const [visiblePathInfo, setVisiblePathInfo] = useState(() => data.path_info);
    const [, setVisibleStatus] = useState(() => data.status);
    const [visibleTypeOfItem, setVisibleTypeOfItem] = useState(() => data.type_of_item);
    const [visibleSection, setVisibleSection] = useState(() => data.section);
    const [visibleDescription, setVisibleDescription] = useState(() => data.description);
    const [visiblePriorityNumber, setVisiblePriorityNumber] = useState(() => data.priority_number);
    const [visibleDateCreated, setVisibleDateCreated] = useState(() => data.date_created);
    const [visibleDateFinished, setVisibleDateFinished] = useState(() => data.date_finished);
    const [visibleDateSubmitted, setVisibleDateSubmitted] = useState(() => data.date_submitted);
    const [visibleDeadline, setVisibleDeadline] = useState(() => data.deadline);
    const [visibleDivider, setVisibleDivider] = useState/* <boolean> */(() => data.divider);
    const [visibleParentId, setVisibleParentId] = useState(()=> data.parent_node_id);
    const [visibleStatusChangeDescription, setVisibleStatusChangeDescription] = useState(() => data.change_description);
    const [visibleScale, setVisibleScale] = useState(()=> data.scale);
    const [visibleClassId, setVisibleClassId] = useState<any>(data.class_id);
    const [visibleTags, setVisibleTags] = useState<any>(data.tags);


    const [toggleDescriptionIcon, setToggleDescriptionIcon] = useState(false);
    const [toggleFileNameToPath, setToggleFileNameToPath] = useState(false);

//-------------------------[Context menu open position and toggle]-------------------------------------------
    
    const [contexMenuPosition, setContexMenuPosition] = useState({x: 0, y: 0})
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [updateRecursive, setUpdateRecursive] = useState(false);

//-------------------------------------[Misc]-----------------------------------

    let [currentSubNodes, setCurrentSubNodes] = useState<any[]>(() => data.subNodes); //sets the state of current subnodes => useful when updating subnodes in Ram
    const [currentSubNodesNo, setCurrentSubNodesNo] = useState<any>(data.nodeCount? data.nodeCount : 0);
    const [, setSelected] = useState(false);

//---------------------------------------------------------------------------------------

DescriptionBoxReferences.currentData = data;
DescriptionBoxReferences.recSetCurrentlySelected = setSelected;
DescriptionBoxReferences.recursiveSetVisibleData = setVisibleData;
DescriptionBoxReferences.fetchNewParentData = fetchNewParentData;

//---------------------------------------------------------------------------------------

  function setStatus (Status) {
  /* Function sets the color of quick info status square based on data status value */
    if (Status) {

        if  (Status < 51 && Status > 0) { 
          return (Icons.redSquare);// red sqare status icon
        }
        else if (Status > 50 && Status <= 90 ) {
            return (Icons.yellowSquare);// yellow square status icon
        }
        else if (Status > 90 && Status <= 100) { 
            return (Icons.greenSquare);//green square status icon
        }
        else if (Status === null) {
          return (Icons.whiteSquare);// white square status icon (no status icon)
        };

      } else {
        return (Icons.whiteSquare);
    };
};
//---------------------------------------------------------------------------------------
  function setVisibleData() {
    /* Function is used to update all visible data based on master data structure */

    setSelected(false);
    setVisibleScale(data.scale);
    setVisibleClassId(data.class_id);
    setVisibleName(data.name);
    setVisibleNodeType(data.node_type);
    setVisiblePathInfo(data.path_info)
    setVisibleStatus(data.status);
    setVisibleTypeOfItem(data.type_of_item);
    setVisibleSection(data.section);
    setVisibleDescription(data.description);
    setVisiblePriorityNumber(data.priority_number);
    setVisibleDateCreated(data.date_created);
    setVisibleDateFinished(data.date_finished);
    setVisibleDateSubmitted(data.date_submitted);
    setVisibleDeadline(data.deadline);
    setVisibleDivider(data.divider);
    setCurrentSubNodes(data.subNodes);
    setIsVisible (openFolderState.current? openFolderState.current.includes(data.node_id) : false)
    setCurrentSubNodesNo(data.nodeCount? data.nodeCount : 0);
    setVisibleStatusChangeDescription(data.change_description);
    setVisibleTags(data.tags);

    setStatusColor(()=> setStatus(data.status));
  };

//---------------------------------------------------------------------------------------
  function updateDescriptionBoxData_AccordingToRecursive() {
  /* Function is used to update the state of DescriptionBox function component.
    setInsertForm() and descriptionBox() are passed in from outside (higher level functional components)
    to effect the state globaly */
    
    if  (DescriptionBoxReferences)  {
        if (DescriptionBoxReferences.updatePrevItem) {
            if(DescriptionBoxReferences.DboxInsertFormState) {

              let answer = window.confirm('Form submission will be Cancelled.          Continue? \n\n (Submit must be pressed to cause changes)');

              if(answer){
                DescriptionBoxReferences.updatePrevItem();

                let message = 'Form submission was canceled \n =>  item was not updated';
                ActionLogReferences.updateLogString('Item NOT updated', LogMessageType.Error, message);
              }
              else {
                return
              };
            }
            else {
              DescriptionBoxReferences.updatePrevItem();
            };
        };

        DescriptionBoxReferences.updateRecursiveNode = updateVisibleData_AccordingToDescriptionBox;
        DescriptionBoxReferences.setDboxToInsertForm(false);
        DescriptionBoxReferences.setShowDescriptionBox(true);

        DescriptionBoxReferences.dBoxData.current = data;
        DescriptionBoxReferences.setVisibleData();
      
        DescriptionBoxReferences.updatePrevItem = setVisibleData;
    };
  };
//---------------------------------------------------------------------------------------


  function updateVisibleData_AccordingToDescriptionBox (section, value) {
    /* Function serves as connection between descriptionBox and recursive component. It is passed to descriptionBox component which
      is used to make any changes to item's data (only priority number and parentId are changable in recursive component, everything else
      is changable only in descriptionBox).
      
      Function is used to change visible data in recursive component. It is called to sync descriptionBox changes with recursive
      
      Function takes in 2 parameters to only affect 1 (currently changed) state. 
      1.) section =>  which section of visible data should be updated? 
      2.) value => new value of changed data */


    if (section === descriptionBoxPart.fileName) {
      setVisibleName(value);
    }
    else if(section === descriptionBoxPart.divider) {
      setVisibleDivider(value);
    }
    else if(section === descriptionBoxPart.scale) {
      setVisibleScale(value);
    }
    else if(section === descriptionBoxPart.class_id) {
      setVisibleClassId(value);
    }
    else if (section === descriptionBoxPart.status) {
      setVisibleStatus(value);
      setStatusColor(setStatus(Number(value)));
    }
    else if (section === descriptionBoxPart.typeOfItem) {
      setVisibleTypeOfItem(value);
    }
    else if (section === descriptionBoxPart.section) {
      setVisibleSection(value);
    }
    else if (section === descriptionBoxPart.comment) {
      setVisibleDescription(value);
    }
    else if (section === descriptionBoxPart.tags) {
      setVisibleTags(value);
    }
    else if (section === descriptionBoxPart.priorityNumber) {
      
      setVisiblePriorityNumber(value);
      refreshRecursiveParent(refreshRecursiveParentState);
    }
    else if (section === descriptionBoxPart.dateCreated) {
      setVisibleDateCreated(value);
    }
    else if (section === descriptionBoxPart.dateFinished) {
      setVisibleDateFinished(value);
    }
    else if(section === descriptionBoxPart.dateSubmitted) {
      setVisibleDateSubmitted(value);
    }
    else if (section === descriptionBoxPart.pathInfo) {
      setVisiblePathInfo(value);
    }
    else if (section === descriptionBoxPart.deadline) {
      setVisibleDeadline(value);
    }
    else if (section === descriptionBoxPart.change_description){
      setVisibleStatusChangeDescription(value);
    }
  };
//---------------------------------------------------------------------------------------

  function expand(e) {
    e.stopPropagation();
  /* Function is called on recursive unwrapping and toggles next level between visible and unvisible (setIsVisible).
     It also changes color depending on visible state. 
     openFolderState is an array of Id's.
     Id of currently opened item is saved in this array for the programm to remember which folders are opened
     openFolderState is passed into function from outside*/

    if  ((data.divider === true) && openFolderState.current) // if array of opened folders is passed into functon Component
        {
          if  (openFolderState.current.includes(data.node_id))
              {
                openFolderState.current.splice(openFolderState.current.indexOf(data.node_id),1);
                setIsVisible(false);
              }
          else
              {
                openFolderState.current.push(data.node_id);
                setIsVisible(true);
              };
          }
    else if ((data.divider === true) && !openFolderState.current) // if there is opened folders array just toggle visible state
            {
              setIsVisible(!isVisible);
            };
  };
//---------------------------------------------------------------------------------------
  function setNewInsertForm () {
  /* function is called when "Add new item" is selected in ContexMenu. New item is created in
    DescriptionBox functional component. This function passes necessary data that needs to be
    inherited from parent node to new child.
    Function also toggles DescriptionBox (from regular to new item insert form)*/

    currentSubNodes = currentSubNodes?.filter(function(item) {
      console.log('123')
      return (item.node_id !== 'temporary_node');
      
    })
    data.subNodes = data.subNodes?.filter(function(item) {
      console.log('456')
      return(item.node_id !== 'temporary_node');
    })

    console.log(currentSubNodes);
    let initItem = {parent_node_id: data.node_id,
                    parent_node_name:  data.name,
                    tableName: selectedTable,
                    subNodes: data.subNodes,
                    node_id: 'temporary_node',
                    name: Icons.warningLight.repeat(5)  + 'NEW ITEM' + Icons.warningLight.repeat(5)}

    data.subNodes.push(initItem);
    setCurrentSubNodes([...data.subNodes]);
  };
//---------------------------------------------------------------------------------------

  function setContextMenu (e) {
    /* function is called to set the context menu position and prevent default browser context menu */
  
      e.preventDefault();

      if (visibleDivider === true) {
        setContexMenuPosition({x: e.pageX, y: e.pageY-80});
        setShowContextMenu(true);
        console.log('desni klik');
      }
      else {
        console.log('Not divider')
      }
    }
   
//---------------------------------------------------------------------------------------

function setOverviewData (nodeID): void {
  OverViewReferences.nodeEntryPoint = data.node_id
  OverViewReferences.disableButton(OverViewReferences.nodeEntryPoint.lenght === 0)
}

  function ContextMenu () {
  /* functional component of context menu*/

    if (showContextMenu)  {
      return (
        <ul style = {{top: contexMenuPosition.y-20  , left: contexMenuPosition.x -1}}
            className = 'context'
            onMouseLeave = {() => {setShowContextMenu(false)}}>

          <li onMouseDown = {() => setNewInsertForm()}>Add New Item</li>
          <li onMouseDown = {() => setOverviewData(data.node_id)}>Set Status graph here</li>
        </ul>
      )
    }
    else {
      return(<></>);
    };
  };
//---------------------------------------------------------------------------------------
    function Sorted () {
      /* Function is part of recursive procedure. When function is called, it render's divider's subchildren. Function is called based on
        "isVisible" state. That state toggles (true/false) when divider's "open" element is clicked.*/
        const [mostCurrentSubNodes, setCurrentSubNodes]= useState<any>(data.subNodes);
        const [loading, setLoading] = useState(true);
        let selectedTable = 'ProjectTable0'

        useEffect(() => {
          if (!data.subNodes) {
            fetchAndSortData();
          } else {
            let sortedSubNodes = sortAndSetSubNodes(data.subNodes);
            setCurrentSubNodes(sortedSubNodes);
            setLoading(false);
          }
        }, [data.node_id, selectedTable, data.subNodes]);

        const sortAndSetSubNodes = (subNodes) => {
          const sortedSubNodes = [...subNodes].sort(
            (a, b) => Number(b.priority_number) - Number(a.priority_number)
          );
          return sortedSubNodes;
        };

        async function fetchAndSortData() {
          try {
            setLoading(true);
            const response = await fetchData(getPartialDataRoute, data.node_id, selectedTable);
            if (response && response.data) {
              console.log(response.data)
              const newNodes = sortAndSetSubNodes(response.data);
              setCurrentSubNodes(newNodes);
              data.subNodes = newNodes;
            }
            else {
              ActionLogReferences.updateLogString(
                'Error fetching data!',
                LogMessageType.Error,
                JSON.stringify(response.data.message, null, "\t")
              );
            }
          } catch (error) {
            ActionLogReferences.updateLogString(
              'Error fetching data!',
              LogMessageType.Error,
              JSON.stringify(error, null, "\t")
            );
          } finally {
            setLoading(false);
          }
        }
        if (loading) {
          return <></>
        }
        return(
          <>
              {mostCurrentSubNodes?.map((subNodes, index) =>
                  {subNodes.listIndex = index + 1;
                    return (
                          
                          <div className='openingMovement'
                                key = {subNodes.node_id}>
                                <Recursive  data = {subNodes}

                                            refreshRecursiveParent = {setUpdateRecursive}
                                            refreshRecursiveParentState = {updateRecursive}
                                            itemIndex = {index}
                                            selectedTags={selectedTags}
                                            setNewData = {setNewData}
                                            fetchNewParentData = {fetchNewParentDataFromDatabase}
                                            moveItemsToggle = {moveItemsToggle}
                                            DescriptionBoxReferences = {DescriptionBoxReferences}
                                            ActionLogReferences = {ActionLogReferences}
                                            openFolderState = {openFolderState}
                                            openQuickInfoState = {openQuickInfoState}
                                            forceUpdate = {setIsVisible}
                                            showAllQuickInfo = {showAllQuickInfo}
                                            selectedTable = {selectedTable}
                                            unfoldTree = {unfoldTree}
                                            OverViewReferences = {OverViewReferences}/>
                          </div>
                        );
              }
                        )
            }
        </>
      )
    };

  //---------------------------------------------------------------------------------------

   async function fetchNewParentDataFromDatabase() {
      /* Function is passed to divider's children to make connection between child node and parent. Function is then passed to descriptionBox
        and called when new parent's child is created or a child is being deleted. Main purpose of function is to fetch actual database state
        after creating or deleting item */

      let newData = await fetchData(getPartialDataRoute, data.node_id, selectedTable as string);

      if (newData && newData.resolved){
        console.log(newData.data);
        data.subNodes = newData.data.subNodes;
        setVisibleData()
      }
    }

  
//---------------------------------------------------------------------------------------

function ItemInfo() {
  // Improved readability with consistent indentation and spacing
  return (
    <>
      {/* Conditional rendering with clear separation of concerns */}
      {visibleDivider && visibleNodeType === 20 ?
        <div onClick={expand} onContextMenu={(e) => setContextMenu(e)}>
          <span style={{ verticalAlign: 'center' }}>
            {Icons.appended} {isVisible || unfoldTree ? Icons.arrowDown2 : Icons.arrowRight2} {/* open folder icon */}
          </span>
          <span className='subitemsNumber'>
            [{currentSubNodesNo}]
          </span>
        </div>
        :
        <div />}

      <div >

          
      </div>

      {visiblePathInfo ? (
          <div className='link' onClick={() => setToggleFileNameToPath(!toggleFileNameToPath)}>
            {/* [Link] */}{extractFileName(visiblePathInfo)}
                  
          </div>
      ) : <div />}

      {toggleFileNameToPath ? (
        <div style={{ color: 'blue', fontSize: '14px' }} onClick={() => CopyDataToClip(visiblePathInfo)}>
          <textarea className='description' value={visiblePathInfo} readOnly />
        </div>
      ) : <div />}

      {visibleSection ? (
        <div className='itemSection'>
          {visibleSection}
        </div>
      ) : <div />}

      {visiblePathInfo && (
        <div className='fileExtension'>
          {extractFileExtension(visiblePathInfo)}
        </div>
      )}

      {visibleTypeOfItem && (
        <div className='typeOfItem'>
          [{visibleTypeOfItem}]
        </div>
      )}

      {visibleScale && <span className='scale'>[{visibleScale}]</span>}
      {visibleClassId && <span className='typeOfItem'>[{visibleClassId}]</span>}

      {visibleDescription && (
        <>
          {!toggleDescriptionIcon ? (
            <div className='descriptionIcon' onClick={() => setToggleDescriptionIcon(!toggleDescriptionIcon)}>
              {Icons.message}
            </div>
          ) : (
            <div style={{ marginRight: '5px' }}>
              <div className='descriptionIcon' onClick={() => setToggleDescriptionIcon(!toggleDescriptionIcon)}>
                {Icons.message}
                <span className='name'>Komentar:</span>
              </div>
              <div className='DELETEME'>
                <textarea className='description' value={visibleDescription} readOnly />
              </div>
            </div>
          )}
        </>
      )}

      {visibleDateCreated && (
        <div className='dateCreated'>
          <span className='dateCreatedName'>Created:</span>
          [{visibleDateCreated}]
        </div>
      )}

      {visibleDateFinished && (
        <div className='dateCreated'>
          <span className='dateCreatedName'>Finished:</span>
          [{visibleDateFinished}]
        </div>
      )}

      {visibleDateSubmitted && (
        <div className='dateCreated'>
          <span className='dateCreatedName'>Submitted:</span>
          [{visibleDateSubmitted}]
        </div>
      )}

      {visibleDeadline && (
        <div className='dateCreated'>
          <span className='deadline'>Deadline:</span>
          [{visibleDeadline}]
        </div>
      )}
    </>
  );
}


  function MoveItemsComponent() {
    async function moveNodeWithPriorityNumber () {
      /* Function is used when reordering divider's children. Item's order is sorted based on it's priority number. Order of actions when priority number
      is updated:
        1.) new priority number is sent to database
        2.) database sends a.) response of resolved state (resolved(ok), !resolved (error))
                            b.) if resolved => new priority number written in database
        DATABASE RESOLVED: 
          3.) when resolved master data structure priority number value is updated.
          4.) Parent node is refreshed to force new priority number sorting of items
          5.) Log is sent to ActionLog component
      
        DATABASE ERROR:
          3.) if error from database, alert is called displaying database response message
          4.) Log is sent to ActionLog component
          5.) visiblePriorityNumber value is initialized back to masterData structure */
      
        try {
          let updatePriorityNumber: any = await axios.put(updateSectionRoute, { Id: data.node_id,
                                                                                      sectionName:'node_id',
                                                                                      dataContent: visiblePriorityNumber,
                                                                                      section: 'priority_number',
                                                                                      type: 'number',
                                                                                      tableName:selectedTable
                                                                                    });
            
            if (updatePriorityNumber && updatePriorityNumber.data) {
              
              if (updatePriorityNumber.data.resolved) {
      
                data.priority_number = visiblePriorityNumber;
                refreshRecursiveParent(!refreshRecursiveParentState);
                ActionLogReferences.updateLogString('Successfully updated', LogMessageType.Success,`\n ${updatePriorityNumber.data.message}`);
              }
              else {
                alert (updatePriorityNumber.message);
                ActionLogReferences.updateLogString('Failed to update', LogMessageType.Error,`\n ${updatePriorityNumber.data.message}`);
                setVisiblePriorityNumber(data.priority_number);
              };
            };                                   
        }
        catch (e) {
          alert (e);
          ActionLogReferences.updateLogString('Failed to update', LogMessageType.Error,`\n ${e}`);
        };
      };
    return (
      <div className="moveItemsContainer">
      <div className="flexRow">

        <div className="copyToClipButton" onClick={() => CopyDataToClip(data.node_id)}>
          <span className="icon">{Icons.copyToClip}</span>
          <span className="text">{data.node_id}</span>
        </div>

        <div className = 'parentIdContainer'>
          Parent_Id:
          <input 
            type='text'
            placeholder={visibleParentId}
            onChange={(e) => setVisibleParentId(e.target.value)}/>
          <button 
            type='button'
            onClick={moveNodeToAnotherParent}
          >
            move
          </button>
        </div>


        <div className = 'priorityNumberContainer'>
          Priority Number: 
          <input 
            type='number'
            placeholder={visiblePriorityNumber}
            onChange={(e) => setVisiblePriorityNumber(e.target.value)}
          />
          <button 
            type='button'
            onClick={moveNodeWithPriorityNumber}
          >
            move
          </button>
        </div>
      </div>
    </div>
    )
  }

  function QuickInfoRight () {
  /* function component for quick info on the right side of item */

    return (
      <div className = {`quickInfoRight ${moveItemsToggle?'move-items':''}`}
           onClick = {() => updateDescriptionBoxData_AccordingToRecursive()}>
            {moveItemsToggle?
              <MoveItemsComponent/>
            :
              <ItemInfo/>
            }
      </div>
    );
  };

//---------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------

function CopyDataToClip (data) {
  /* function is called to copy passed data to clipboard and send Log of copied data to ActionLog component */

  ActionLogReferences.updateLogString('Copied to clip', LogMessageType.Success,`Clipboard Data: \n\n ${data}`);
  navigator.clipboard.writeText(data);
};

//---------------------------------------------------------------------------------------

async function moveNodeToAnotherParent () {
/* Function is called when moving Node to another parent. 
  Order of actions:
  1.) new parent_node_id of item is sent to database
  2.) database returns response including: a.) state of resolved (resolved/error)
                                            b.) if resolved => new parent_node_id value written in database
  DATABASE RESOLVED:
    3.) when request is resolved, initialization of all data is called. setNewData() => fetch all data from database
    4.) Log is sent to ActionLog component
    
  DATABASE ERROR:
    3.) alert is triggered containing database response message
    4.) Log is sent to ActionLog component
    5.) VisibleParentId is initialized back to current master data*/

  
    /* try { */
/*       const response = await axios.post(routes.checkIfNodeExistsRoute, {
        tableName: selectedTable,
        idToCheck: visibleParentId
      });
  
      console.log(`Was node with node_id = ${visibleParentId} found?: ${response.data.resolved}`);
  
      if (response.data && response.data.resolved) { */
        try {
          const updateResponse = await axios.put(updateSectionRoute, {
            Id: data.node_id,
            sectionName: 'node_id',
            dataContent: visibleParentId,
            section: 'parent_node_id',
            type: 'string',
            tableName: selectedTable
          });
  
          if (updateResponse && updateResponse.data && updateResponse.data.resolved) {
            setNewData();
            ActionLogReferences.updateLogString('Successfully updated', LogMessageType.Success, `\n ${updateResponse.data.message}`);
          } else {
            const errorMessage = updateResponse?.data?.message || 'Failed to update';
            alert(errorMessage);
            ActionLogReferences.updateLogString(errorMessage, LogMessageType.Error, `\n ${errorMessage}`);
            setVisibleParentId(data.parent_node_id);
          }
        } catch (e) {
          alert(e);
          ActionLogReferences.updateLogString('Failed to update', LogMessageType.Error, `\n ${e}`);
          setVisibleParentId(data.parent_node_id);
        }
      } /* else {
        const errorMessage = `Node with ID: "${visibleParentId}" was not found`;
        ActionLogReferences.updateLogString('Incorrect Parent id', LogMessageType.Error, `\n ${errorMessage}`);
        alert(`Node with id: ${visibleParentId} does not exist`);
        setVisibleParentId(data.parent_node_id);
        setShowNodeParentIdInput(false);
      } */
/*     } catch (e) {
      alert(e);
    }
  }; */

//---------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------

  return (
  
    <div  className = 'recursiveWrapper' >
      <Tooltip id = "path"/>
      <div onClick = {() => updateDescriptionBoxData_AccordingToRecursive()}>
        <div className={`recursiveWrapper ${
                            visibleDivider && visibleNodeType !== 20 
                            ? 'specialNode' 
                            : visibleNodeType !== 20 
                            ? 'regularNode' 
                            : 'childNode'
                        }`
        }>

        <ContextMenu/>

{/* ------------------------------Moving with priority Number-------------------------------------- */}
      
        {visibleDivider?
          <>
            {
              visibleNodeType !== 20 &&
                <div className='closedDivider' onClick={expand} onContextMenu={setContextMenu}>
                  <span style={{ color: isVisible || unfoldTree? 'red':''}}>
                    {isVisible || unfoldTree ? Icons.arrowDown : Icons.arrowRight }
                  </span>
                  <span className='subitemsNumber'>
                    [{currentSubNodesNo}]
                  </span>
                </div>
            }
          </>
        :
          <> 
            {
              currentSubNodesNo !== 0 &&
              <>
                [{currentSubNodesNo}]   {/* preventive check if item is not divider but it contains subitems  */} 
              </>
            }
          </>
        }

{/* --------------------------------Show name of item------------------------------------------- */}

        <span>
          <span className = 'itemName'>
            <div  style = {{  color: visibleDivider && (isVisible || unfoldTree) ? 'red' : '',
                              textDecoration:visibleDivider && visibleNodeType != 20?'underline':'none',
                              }}
                  onContextMenu = {(e) => setContextMenu(e)}
                  className = 'rec-title'>
                      
              {visibleName}
              <div style = {{fontSize:'12px', background:'lightgrey', padding:'0 5px', borderRadius:'5px'}}
                    data-tooltip-id="path" data-tooltip-content={data.path?data.path:''}>
                {data.path?data.path.split('/').slice(1,3).join('/'):''}
              </div>
            </div>
            <div className='sectionTitle'>
              <div className='tagsContainer' >
                {visibleTags?.split(';').map((tag, index) => (
                  <div  key={index} 
                        className='tagItem' 
                        style={{backgroundColor: selectedTags?.some(selectedTag => selectedTag.name === tag) ?  '#1a73e8' : '', 
                                color: selectedTags?.some(selectedTag => selectedTag.name === tag) ?  'white' : '',
                                fontSize: '8px', 
                                borderTopLeftRadius: '10px',
                                borderBottomLeftRadius: '10px',
                                borderRadius:'3px'}}>
                        {tag}
                  </div>
                ))}
              </div>
            </div>
          </span>
        </span>
{/* --------------------------------right side of all quick info---------------------------------- */}

          <QuickInfoRight/>        
                                  {/* open next recursive */}
        </div>
      </div>

      <div  className = 'recursiveWrapper listOnGrid'>
        {(isVisible || unfoldTree) &&
          <Sorted/>
        }
      </div>

    </div>
      
  );   /* return */
}       /* main */

export default (Recursive);